import { convertToRupiah } from '../../../helper/Rupiah'

const RefundContractorItem = (props) => {
  const data = props.data
  const teamAssign = JSON.parse(data.team_assign)
  const teamOnsite = JSON.parse(data.team_onsite)
  const isMinus = data.sisa_deposit < 0 ? true : false

  return (
    <tr>
      <th>{props.no}</th>
      <td>{data.project_id}</td>
      <td>Project Contractor</td>
      <td>{data.client_name}</td>
      <td>{data.tgl_deposit}</td>
      <td>Rp. {convertToRupiah(data.deposit)}</td>
      <td>{teamAssign.T + '/' + teamAssign.K}</td>
      <td>{teamOnsite.T + '/' + teamOnsite.K}</td>
      <td>Rp. {convertToRupiah(data.upah)}</td>
      <td>Rp.{convertToRupiah(data.customer_fee)}</td>
      <td>Rp.{convertToRupiah(data.refund)}</td>
      <td>Rp.{convertToRupiah(data.fee_tukang)}</td>
      <td className={isMinus ? 'text-danger' : 'text-success'}>
        Rp.{convertToRupiah(data.sisa_deposit)}
      </td>
    </tr>
  )
}

export default RefundContractorItem
