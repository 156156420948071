import { useState, useEffect, useRef } from 'react'
import FileSaver from 'file-saver'
import Main from '../../layouts/Main'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import Card from '../../components/Card'
import Button from '../../components/Button'
import InputGroup from '../../components/InputGroup'
import InputGroupDate from '../../components/InputGroupDate'
import Pagination from '../../components/Pagination'
import KasbonApprovalService from '../../services/KasbonApproval'
import { Toast } from '../../config/Toast'
import 'react-loading-skeleton/dist/skeleton.css'
import Item from './Item'
import Confirm from './Confirm'
import Reject from './Reject'
import Detail from './Detail'
import Download from './Download'
import Upload from './Upload'
import ImageEvidance from './ImageEvidance'

const List = () => {
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [modalReject, setModalReject] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalDownload, setModalDownload] = useState(false)
  const [modalUpload, setModalUpload] = useState(false)
  const [modalImage, setModalImage] = useState(false)
  const [image, setImage] = useState()
  const [totalPending, setTotalPending] = useState()
  const [data, setData] = useState(null)
  const [dataConfirm, setDataConfirm] = useState()
  const [dataReject, setDataReject] = useState()
  const [dataDetail, setDataDetail] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [search, setSearch] = useState(null)
  const [filterLoading, setFilterLoading] = useState(false)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const toastId = useRef(null)
  const limit = 5

  useEffect(() => {
    setLoading(true)

    KasbonApprovalService.list(pageNumber, limit, search, startDate, endDate)
      .then((res) => {
        setData(res.data.data.list)
        setTotalPending(res.data.data.total_pending)
        setTotalPage(res.data.total_page)
        setIsUpdated(false)
      })
      .catch((err) => {
        console.log(err)
        setIsUpdated(false)
      })

    setTimeout(() => {
      setLoading(false)
    }, 1200)
  }, [isUpdated])

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setIsUpdated(true)
  }

  const filterHandler = () => {
    if (startDate && endDate) {
      setFilterLoading(true)
      setLoading(true)

      KasbonApprovalService.list(pageNumber, limit, search, startDate, endDate)
        .then((res) => {
          setData(res.data.data.list)
          setTotalPending(res.data.data.total_pending)
          setIsUpdated(false)
          setTotalPage(res.data.total_page)
        })
        .catch((err) => {
          console.log(err)
          setFilterLoading(false)
          setLoading(false)
        })

      setTimeout(() => {
        setFilterLoading(false)
        setLoading(false)
      }, 1200)
    } else {
      notifyFailed('Periode rentang waktu tidak boleh kosong')
    }
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setIsUpdated(true)
  }

  const confirmHandler = () => {
    KasbonApprovalService.confirm(dataConfirm.id)
      .then(() => {
        setModalDetail(false)
        notifySuccess('Berhasil terima permintaan kasbon')
        setIsUpdated(true)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Terjadi kesalahan')
      })
  }

  const rejectHandler = (value) => {
    KasbonApprovalService.reject(dataReject.id, { reject_reason: value })
      .then(() => {
        setModalDetail(false)
        notifySuccess('Berhasil tolak permintaan kasbon')
        setIsUpdated(true)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Terjadi kesalahan')
      })
  }

  const downloadHandler = () => {
    setModalDetail(false)
    toastId.current = toast('Sedang mengunduh tanda terima', {
      type: 'info',
      autoClose: false,
      position: 'top-center',
      theme: 'colored',
    })

    KasbonApprovalService.download(dataDetail.id)
      .then((res) => {
        const url = `${process.env.REACT_APP_API_DOMAIN}/${res.data.path}`
        FileSaver.saveAs(url, res.data.path)
        toast.update(toastId.current, {
          type: 'success',
          autoClose: 3000,
          render: 'Berhasil download tanda terima',
        })
        setModalDetail(false)
      })
      .catch((err) => {
        console.log(err)
        toast.update(toastId.current, {
          type: 'error',
          autoClose: 3000,
          render: 'Gagal download tanda terima',
        })
      })
  }

  const uploadHandler = async (value) => {
    let bodyFormData = new FormData()
    bodyFormData.append('image_evidance', value[0].file)
    bodyFormData.append('upload_by', 'finance')
    setModalUpload(false)
    setModalDetail(false)

    toastId.current = toast('Sedang memproses upload bukti transfer', {
      type: 'info',
      autoClose: false,
      position: 'top-center',
      theme: 'colored',
    })

    KasbonApprovalService.upload(dataDetail.id, bodyFormData)
      .then((res) => {
        toast.update(toastId.current, {
          type: 'success',
          autoClose: 3000,
          render: 'Barhasil upload bukti transfer',
        })
      })
      .catch((err) => {
        console.log(err)
        toast.update(toastId.current, {
          type: 'error',
          autoClose: 3000,
          render: 'Gagal upload bukti transfer',
        })
      })
  }

  const imageHandler = (imageUrl) => {
    setImage(imageUrl)
    setModalImage(true)
  }

  const closeModalHandler = () => {
    if (
      modalDetail &&
      (modalConfirm == true ||
        modalReject == true ||
        modalDownload == true ||
        modalUpload == true)
    ) {
      setModalConfirm(false)
      setModalReject(false)
      setModalDownload(false)
      setModalUpload(false)
    } else if (modalImage) {
      setModalImage(false)
    } else {
      setModalDetail(false)
      setModalConfirm(false)
      setModalReject(false)
      setModalDownload(false)
      setModalUpload(false)
      setModalImage(false)
    }
  }

  const tHead = [
    'No',
    'Date',
    'PIC',
    'Worker Name',
    'Worker ID',
    'Status',
    'Note',
    'Total Kasbon',
    'Kasbon Type',
    'Duration',
    'Payment Type',
    'Action',
  ]

  return (
    <Main title={`Permintaan Kasbon`}>
      {modalDetail && (
        <Detail
          data={dataDetail}
          activeModal={modalDetail}
          setUpdate={setIsUpdated}
          closeModal={closeModalHandler}
          setDataConfirm={setDataConfirm}
          setDataReject={setDataReject}
          setDataDetail={setDataDetail}
          setModalConfirm={setModalConfirm}
          setModalReject={setModalReject}
          setModalDetail={setModalDetail}
          setModalDownload={setModalDownload}
          setModalUpload={setModalUpload}
          imageHandler={imageHandler}
        />
      )}

      {modalConfirm && (
        <Confirm
          data={dataConfirm}
          activeModal={modalConfirm}
          setUpdate={setIsUpdated}
          closeModal={closeModalHandler}
          confirmHandler={confirmHandler}
        />
      )}

      {modalReject && (
        <Reject
          data={dataReject}
          activeModal={modalReject}
          setUpdate={setIsUpdated}
          closeModal={closeModalHandler}
          rejectHandler={rejectHandler}
        />
      )}

      {modalDownload && (
        <Download
          data={dataDetail}
          activeModal={modalDownload}
          setUpdate={setIsUpdated}
          closeModal={closeModalHandler}
          downloadHandler={downloadHandler}
        />
      )}

      {modalUpload && (
        <Upload
          activeModal={modalUpload}
          setUpdate={setIsUpdated}
          closeModal={closeModalHandler}
          uploadHandler={uploadHandler}
        />
      )}

      {modalImage && (
        <ImageEvidance
          activeModal={modalImage}
          closeModal={closeModalHandler}
          data={image}
        />
      )}

      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row">
          <div className="col-3">
            <InputGroupDate
              type="startDate"
              label="Start Date"
              setStartDate={setStartDate}
            />
          </div>
          <div className="col-3">
            <InputGroupDate
              type="endDate"
              label="End Date"
              setEndDate={setEndDate}
            />
          </div>
          <div className="col">
            <div className="row mt-5">
              <div className="col mt-4">
                <Button
                  button="btn-primary"
                  title="Filter"
                  icon="fa fa-filter me-1"
                  loading={filterLoading}
                  onClick={filterHandler}
                />
              </div>
              <div className="col-8 mt-4">
                <InputGroup
                  button="btn-primary"
                  icon="fa fa-search"
                  searchHandler={searchHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered text-nowrap text-md-nowrap text-center mb-0">
                <thead>
                  <tr>
                    {tHead.map((d) => (
                      <th>{d}</th>
                    ))}
                  </tr>
                </thead>
                {loading &&
                  tHead.map(() => (
                    <td>
                      <Skeleton
                        count={5}
                        variant="rectangular"
                        height={33}
                        style={{
                          marginBottom: '0.5rem',
                        }}
                      />
                    </td>
                  ))}
                {!loading && data && (
                  <tbody>
                    {data.map((d, index) => (
                      <Item
                        key={d.id}
                        no={
                          index +
                          (pageNumber == 1 ? 1 : limit * pageNumber - limit + 1)
                        }
                        data={d}
                        setDataConfirm={setDataConfirm}
                        setDataReject={setDataReject}
                        setDataDetail={setDataDetail}
                        setModalConfirm={setModalConfirm}
                        setModalReject={setModalReject}
                        setModalDetail={setModalDetail}
                        setModalDownload={setModalDownload}
                      />
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            {totalPage > 0 && (
              <Pagination
                pageCount={totalPage}
                changePage={changePageHandler}
              />
            )}
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default List
