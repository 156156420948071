import { useState, Fragment } from 'react'
import { toast } from 'react-toastify'
import Button from '../../../components/Button'
import Badge from '../../../components/Badge'
import { Toast } from '../../../config/Toast'
import KasbonService from '../../../services/Kasbon'
import { convertToRupiah } from '../../../helper/Rupiah'

const RekapItem = (props) => {
  const data = props.data
  const isPosting = data.kasbon_check == 1 ? true : false
  const kasbonUpaid =
    data.total_kasbon_unpaid > 0 ? `${data.total_kasbon_unpaid} Transaksi` : '-'
  const [bookmark, setBookmark] = useState(data.kasbon_bookmark)
  const [deduction, setDeduction] = useState(data.deduction_amount)
  const [deductionNote, setDeductionNote] = useState(
    data?.deduction_note || '-'
  )
  const [initialAmount, setInitialAmount] = useState(data.initial_amount)
  const [finalAmount, setFinalAmount] = useState(data.net_amount)
  const notifyFailed = (message) => toast.error(message, Toast)

  const bookmarkHandler = () => {
    KasbonService.bookmark(data.id)
      .then(() => {
        bookmark == 0 ? setBookmark(1) : setBookmark(0)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const detailHandler = () => {
    KasbonService.detail(data.id)
      .then((res) => {
        props.detailHandler(data.id, data.worker_name, res.data.data)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Something went wrong!')
      })
  }

  return (
    <tr className={isPosting ? 'text-primary' : ''}>
      <td>{props.no}</td>
      <td>
        {data.worker_name}
        {data.is_jatuh_tempo && <i className="fa fa-circle text-danger ms-2"></i>}
      </td>
      <td>{data.worker_id}</td>
      <td>Rp. {convertToRupiah(parseInt(initialAmount))}</td>
      <td>
        {!isPosting && (
          <Fragment>
            <Button
              button={`${
                bookmark === 0 ? 'btn-outline-success' : 'btn-success'
              } btn-sm me-1`}
              icon={`fa fa-bookmark ${
                bookmark === 0 ? 'text-success' : 'text-light'
              }`}
              onClick={bookmarkHandler}
            />
            <Button
              button="btn-outline-warning btn-sm"
              icon="fa fa-wrench text-warning"
              onClick={detailHandler}
            />
          </Fragment>
        )}

        {isPosting && (
          <Badge badge="bg-primary pt-1" name="posted" type="rounded-pill" />
        )}
      </td>
      <td>{kasbonUpaid}</td>
      <td className="text-danger">-Rp. {convertToRupiah(deduction)}</td>
      <td>{deductionNote}</td>
      <td className="text-success">Rp. {convertToRupiah(finalAmount)}</td>
      <td>
        {data.status_kasbon && (
          <Badge
            badge={`${
              data.status_kasbon == 'Sedang Berlangsung'
                ? 'bg-info'
                : 'bg-success px-6'
            } pt-1`}
            name={data.status_kasbon}
            type="rounded-pill"
          />
        )}

        {!data.status_kasbon && '-'}
      </td>
    </tr>
  )
}

export default RekapItem
