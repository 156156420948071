import axios from 'axios'
import { headersConfig } from '../config/Header'

class DashboardService {
  balance() {
    const url = process.env.REACT_APP_API_URL_MITRA10 + '/rms/balance'
    return axios.get(url, headersConfig)
  }

  totalProjects() {
    const url =
      process.env.REACT_APP_API_URL_MITRA10 + '/rms/current-transaction'
    return axios.get(url, headersConfig)
  }

  totalProjectGeneral() {
    const url =
      process.env.REACT_APP_API_URL_V1 + '/parameter/total-transaction'
    return axios.get(url, headersConfig)
  }
}

export default new DashboardService()
