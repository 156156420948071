const Alert = (props) => {
  return (
    <div className={`alert alert-${props.type} text-center`} role="alert">
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-hidden="true"
      >
        ×
      </button>
      {props.title}
    </div>
  )
}

export default Alert
