import { Fragment } from 'react'

const InputForm = (props) => {
  const option = []

  const changeHandler = (e) => {
    props.changeHandler(e.target.value)
  }

  if (props.type == 'select') {
    props.option.forEach((el) => {
      option.push(
        <option selected={el == props.value} value={el}>{`${el
          .charAt(0)
          .toUpperCase()}${el.slice(1)}`}</option>
      )
    })
  }

  return (
    <div className={`row ${props.margin}`}>
      {props.type != 'select' && (
        <Fragment>
          <label htmlFor={props.id} className="col-md-3 form-label">
            {props.label}
          </label>
          <div className="col-md-9">
            <input
              type={props.type}
              className="form-control"
              id={props.id}
              value={props.value}
              placeholder={props.placeholder}
              onChange={changeHandler}
              disabled={props.disabled ? props.disabled : false}
            />
          </div>
        </Fragment>
      )}

      {props.type == 'select' && (
        <Fragment>
          <label htmlFor={props.id} className="col-md-3 form-label">
            {props.label}
          </label>
          <div className="col-md-9">
            <select
              className="form-select"
              aria-label="Default select example"
              disabled={props.disabled}
              onChange={changeHandler}
              id={props.id}
            >
              {props.defaultOption && (
                <option
                  value={props.defaultOption}
                  selected={props.value == null ? 'true' : 'false'}
                >
                  {props.placeholder}
                </option>
              )}
              {option}
            </select>
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default InputForm
