import ConsumerTable from './ConsumerTable'
import VendorTable from './VendorTable'
import ContractorTable from './ContractorTable'
import PelaksanaTable from './PelaksanaTable'
import VoucherTable from './VoucherTable'
import Main from '../../../layouts/Main'
import Card from '../../../components/Card'
import Tab from '../../../components/Tab'

const DepositConsumerReport = () => {
  const data = [
    {
      name: 'Consumer',
      id: '#tab1',
      content: <ConsumerTable />,
    },
    {
      name: 'Vendor',
      id: '#tab2',
      content: <VendorTable />,
    },
    {
      name: 'Kontraktor',
      id: '#tab3',
      content: <ContractorTable />,
    },
    {
      name: 'Pelaksana',
      id: '#tab4',
      content: <PelaksanaTable />,
    },
    {
      name: 'Voucher',
      id: '#tab5',
      content: <VoucherTable />,
    },
  ]

  return (
    <Main title="Report Deposit">
      <Card col={'col-xl-12 col-lg-12'}>
        <Tab
          data={data}
          defaultId={data[0].id}
          defaultContent={data[0].content}
        />
      </Card>
    </Main>
  )
}

export default DepositConsumerReport
