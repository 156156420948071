import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class UserService {
  getAll() {
    const url = process.env.REACT_APP_API_URL_V1 + '/user'
    return axios.get(url, headersTokenConfig)
  }

  setVerified(id, toogle) {
    const url = process.env.REACT_APP_API_URL_V1 + '/user/toogle'
    return axios.post(url, { id, toogle }, headersTokenConfig)
  }

  delete(id) {
    const url = process.env.REACT_APP_API_URL_V1 + `/user/${id}/delete`
    return axios.delete(url, headersTokenConfig)
  }
}

export default new UserService()
