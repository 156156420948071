import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [maintenanceDropdown, setMaintenanceDropdown] = useState(false);
  const [upahDropdown, setUpahDropdown] = useState(false);
  const [calculationDropdown, setCalculationDropdown] = useState(false);
  const [reportDropdown, setReportDropdown] = useState(false);
  const [customerRefundDropdown, setCustomerRefundDropdown] = useState(false);

  const maintenanceDropdownHandler = () => {
    setMaintenanceDropdown(!maintenanceDropdown);
  };

  const calculationDropdownHandler = () => {
    setCalculationDropdown(!calculationDropdown);
  };

  const reportDropdownHandler = () => {
    setReportDropdown(!reportDropdown);
  };

  const upahDropdownHandler = () => {
    setUpahDropdown(!upahDropdown);
  };

  const customerRefundDropdownHandler = () => {
    setCustomerRefundDropdown(!customerRefundDropdown);
  };

  return (
    <div className="sticky">
      <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
      <div className="app-sidebar">
        <div className="app-sidebar overflow-y-auto">
          <div className="side-header">
            <a className="header-brand1" href="/#">
              <img
                src={`${window.location.origin}/assets/images/logo/kanggo.png`}
                className="header-brand-img desktop-logo"
                alt="logo"
              />
              <img
                src={`${window.location.origin}/assets/images/logo/kanggo.png`}
                className="header-brand-img toggle-logo"
                alt="logo"
              />
              <img
                src={`${window.location.origin}/assets/images/logo/logo-mini.png`}
                className="header-brand-img light-logo"
                alt="logo"
              />
              <img
                src={`${window.location.origin}/assets/images/logo/logo-new3.svg`}
                className="header-brand-img light-logo1"
                alt="logo"
              />
            </a>
          </div>

          <div className="main-sidemenu">
            <div className="slide-left disabled" id="slide-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#7b8191"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
              </svg>
            </div>

            <ul className="side-menu">
              {/* Dashboard */}
              <li className="slide">
                <NavLink
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  to="/"
                >
                  <i className="side-menu__icon fe fe-home" />
                  <span className="side-menu__label">Dashboard</span>
                </NavLink>
              </li>
              {/* List Tukang */}
              <li className="slide">
                <NavLink
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  to="/list-tukang"
                >
                  <i className="side-menu__icon fe fe-users" />
                  <span className="side-menu__label">List Tukang</span>
                </NavLink>
              </li>
              {/* Recalculate */}
              <li className="slide">
                <NavLink
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  to="/recalculate"
                >
                  <i className="side-menu__icon fe fe-refresh-cw" />
                  <span className="side-menu__label">Recalculate</span>
                </NavLink>
              </li>
              {/* Maintenance */}
              <li
                role="button"
                className={`slide ${maintenanceDropdown ? "is-expanded" : ""}`}
              >
                <a
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  onClick={maintenanceDropdownHandler}
                >
                  <i className="side-menu__icon fe fe-settings" />
                  <span className="side-menu__label">Maintenance</span>
                  <i className="angle fe fe-chevron-right" />
                </a>

                <ul
                  className={`slide-menu ${
                    maintenanceDropdownHandler ? "open" : ""
                  }`}
                >
                  <li className="side-menu-label1">
                    <a href="javascript:void(0)">Maintenance</a>
                  </li>
                  <li>
                    <NavLink
                      to="/maintenance/user-lists"
                      className="slide-item"
                    >
                      User Lists
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/maintenance/harga-keahlian"
                      className="slide-item"
                    >
                      Harga Keahlian
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/maintenance/parameter" className="slide-item">
                      Parameter
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/maintenance/bpjs" className="slide-item">
                      Master BPJS
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* Perhitungan Upah Tukang */}
              <li className="slide">
                <NavLink
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  to="/perhitungan-upah"
                >
                  <i className="side-menu__icon fe fe-dollar-sign" />
                  <span className="side-menu__label">Perhitungan Upah</span>
                </NavLink>
              </li>
              {/* Pemotongan Upah */}
              <li
                role="button"
                className={`slide ${upahDropdown ? "is-expanded" : ""}`}
              >
                <a
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  onClick={upahDropdownHandler}
                >
                  <i className="side-menu__icon ti-wallet" />
                  <span className="side-menu__label">Pemotongan Upah</span>
                  <i className="angle fe fe-chevron-right" />
                </a>

                <ul
                  className={`slide-menu ${upahDropdownHandler ? "open" : ""}`}
                >
                  <li className="side-menu-label1">
                    <a href="javascript:void(0)">Pemotongan Upah</a>
                  </li>
                  <li>
                    <NavLink
                      to="/upah/permintaan-kasbon"
                      className="slide-item"
                    >
                      Permintaan Kasbon
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/upah/pemotongan-upah" className="slide-item">
                      Rekap, BPJS & Kasbon
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/upah/upah-keahlian" className="slide-item">
                      Tipe Keahlian
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* Point & Bonus */}
              <li className="slide">
                <NavLink
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  to="/point-bonus"
                >
                  <i className="side-menu__icon fe fe-award" />
                  <span className="side-menu__label">Point & Bonus</span>
                </NavLink>
              </li>
              {/* Report */}
              <li
                role="button"
                className={`slide ${reportDropdown ? "is-expanded" : ""}`}
              >
                <a
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  onClick={reportDropdownHandler}
                >
                  <i className="side-menu__icon fe fe-book" />
                  <span className="side-menu__label">Reports</span>
                  <i className="angle fe fe-chevron-right" />
                </a>

                <ul className={`slide-menu ${reportDropdown ? "open" : ""}`}>
                  <li className="side-menu-label1">
                    <a href="javascript:void(0)">Reports</a>
                  </li>
                  <li>
                    <NavLink to="/reports/deposit" className="slide-item">
                      Deposit
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports/vendor-commission"
                      className="slide-item"
                    >
                      Komisi Vendor
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/reports/upah-tukang" className="slide-item">
                      Upah Tukang
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports/disbursment-worker"
                      className="slide-item"
                    >
                      Disbursment Worker
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports/refund-kontraktor"
                      className="slide-item"
                    >
                      Refund Kontraktor
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/reports/bonus_point" className="slide-item">
                      Bonus Point
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* Worker Refund */}
              <li className="slide">
                <NavLink
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  to="/penangguhan"
                >
                  <i className="side-menu__icon fe fe-user" />
                  <span className="side-menu__label">Tukang Penangguhan</span>
                </NavLink>
              </li>
              {/* Refund Customer */}
              <li
                role="button"
                className={`slide ${
                  customerRefundDropdown ||
                  currentPath === "/refund-customer" ||
                  currentPath === "/refund-material"
                    ? "is-expanded"
                    : ""
                }`}
              >
                <div
                  className="side-menu__item"
                  data-bs-toggle="slide"
                  onClick={customerRefundDropdownHandler}
                >
                  <i className="side-menu__icon ti-flag" />
                  <span className="side-menu__label">Refund Customer</span>
                  <i className="angle fe fe-chevron-right" />
                </div>

                <ul
                  className={`slide-menu ${
                    customerRefundDropdownHandler ? "open" : ""
                  }`}
                >
                  <li>
                    <NavLink to="/refund-customer" className="slide-item">
                      Refund Project
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/refund-material" className="slide-item">
                      Refund Material
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>

            <div className="slide-right" id="slide-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#7b8191"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
