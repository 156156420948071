import { convertToRupiah } from "../../helper/RupiahDashboard";
import Button from "../../components/Button";

const ItemSkill = (props) => {
  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>{props.item.skill_name}</td>
      <td>
        {props.item.type === 1 ? "B2B" : props.item.type === 2 ? "B2C" : "-"}
      </td>
      <td>Rp{convertToRupiah(props.item.salary_cut)}</td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            button="btn-outline-primary btn-sm"
            icon="fa fa-pencil"
            onClick={() => props.handleEdit(props.item)}
          />
          <Button
            button="btn-outline-danger btn-sm ms-1"
            icon="fa fa-trash"
            onClick={() => props.handleDelete(props.item)}
          />
        </div>
      </td>
    </tr>
  );
};

export default ItemSkill;
