import { useState } from 'react'
import Modal from '../../components/Modal'
import InputForm from '../../components/InputForm'

const EditForm = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const [fullPrice, setFullPrice] = useState(props.data.standard_price)
  const [morningPrice, setMorningPrice] = useState(props.data.session1_price)
  const [eveningPrice, setEveningPrice] = useState(props.data.session2_price)

  const submitHandler = () => {
    const dataEntered = {
      fullPrice: +fullPrice,
      morningPrice: +morningPrice,
      eveningPrice: +eveningPrice,
    }

    props.onUpdate(props.data.id, dataEntered)
  }

  const forms = [
    {
      margin: 'mb-4',
      label: 'Harga Seharian',
      type: 'number',
      id: 'standard_price',
      value: fullPrice,
      changeHandler: setFullPrice,
    },
    {
      margin: 'mb-4',
      label: 'Harga Pagi',
      type: 'number',
      id: 'session1_price',
      value: morningPrice,
      changeHandler: setMorningPrice,
    },
    {
      margin: 'mb-4',
      label: 'Harga Sore',
      type: 'number',
      id: 'session2_price',
      value: eveningPrice,
      changeHandler: setEveningPrice,
    },
  ]

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title={props.data.name}
      closeClick={props.closeClick}
      submitHandler={submitHandler}
      type="Update"
    >
      {forms.map((form) => (
        <InputForm key={form.id} {...form} />
      ))}
    </Modal>
  )
}

export default EditForm
