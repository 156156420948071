import { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Toast } from "../../config/Toast";

// Components
import Main from "../../layouts/Main";
import ItemSkill from "./Item";
import Button from "../../components/Button";
import Card from "../../components/Card";
import InputGroup from "../../components/InputGroup";
import ModalAddEditSkill from "./ModalAddEditSkill";
import SkillSalaryCutService from "../../services/SkillSalaryCut";

const ListUpahKeahlian = () => {
  const [loading, setLoading] = useState(false)
  const [initData, setInitData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [typeModal, setTypeModal] = useState(""); // add | edit
  const [showModal, setShowModal] = useState(false)

  const rowTable = ["No", "Keahlian", "Tipe", "Potongan", "Action"];
  const notifyFailed = (message) => toast.error(message, Toast);

  const convertType = (type) => {
    if (type === 1) {
      return "B2B";
    } else if (type === 2) {
      return "B2C";
    } else {
      return "-";
    }
  };

  const closeModal = () => {
    setShowModal(false)
  }

  const handleSearch = (value) => {
    const findSkill = initData.filter(
      (item) =>
        item.skill_name.toLowerCase().includes(value.toLowerCase()) ||
        convertType(item.type).toLowerCase().includes(value.toLowerCase())
    );
    setData(findSkill);
  };

  const handleAddSkill = () => {
    setShowModal(true)
    setTypeModal("add");
    setSelectedSkill({
      id: null,
      skill_id: 0,
      skill_name: '',
      type: 0,
      salary_cut: "",
    });
  };

  const handleEditSkill = (data) => {
    setShowModal(true)
    setTypeModal("edit");
    setSelectedSkill({...data, salary_cut: data.salary_cut.toString()});
  };

  const handleDeleteSkill = (data) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Kamu tidak akan dapat mengembalikan data ini jika sudah dihapus",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        SkillSalaryCutService.deleteSalaryCut(data.id)
          .then(() => {
            Swal.fire("Berhasil", "Data berhasil dihapus", "success");
            getDataList();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Gagal", "Data gagal dihapus, silahkan coba kembali", "error");
          });
      }
    });
  };

  const getDataList = () => {
    setLoading(true)
    SkillSalaryCutService.getListSalaryCut().then(response => {
      const res = response.data.data

      setData(res);
      setInitData(res);
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      console.log(error);
      notifyFailed(error.response.data.message || "Terjadi kesalahan, silahkan coba lagi");
    })
  };

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <Main title={`Pemotongan Upah Keahlian`}>
      <div>
        <Card col={"col-xl-12 col-lg-12"}>
          <div className="row mt-1">
            <div className="row justify-content-between align-items-center">
              <div className="col-4">
                <div
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#modaldemo8"
                  className="d-inline-block"
                >
                  <Button
                    title="Tambah Keahlian"
                    button="btn-success btn-sm ms-1"
                    onClick={handleAddSkill}
                  />
                </div>
              </div>
              <div className="col-4">
                <InputGroup
                  button="btn-primary"
                  icon="fa fa-search"
                  searchHandler={handleSearch}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-bordered table-hover text-nowrap text-md-nowrap mb-0 text-center">
                  <thead>
                    <tr>
                      {rowTable.map((item, index) => (
                        <Fragment key={index}>
                          <th>{item}</th>
                        </Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? <div>Loading</div> : data.map((item, index) => (
                      <Fragment key={index}>
                        <ItemSkill
                          item={item}
                          index={index}
                          handleEdit={handleEditSkill}
                          handleDelete={handleDeleteSkill}
                        />
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>
      </div>
      
      {showModal && (
        <ModalAddEditSkill
          activeModal={showModal}
          typeModal={typeModal}
          selectedItemSkill={selectedSkill}
          closeModal={closeModal}
          refreshData={getDataList}
        />
      )}
    </Main>
  );
};

export default ListUpahKeahlian;
