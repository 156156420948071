import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { Toast } from '../../../config/Toast'
import InputForm from '../../../components/InputForm'
import SalaryService from '../../../services/SalaryCalculationMitra10'
import 'react-toastify/dist/ReactToastify.css'

const AddModal = (props) => {
  const data = props.dataModal
  const [workers, setWorkers] = useState(props.workers)
  const [workerSelected, setWorkerSelected] = useState(null)
  const [dateProject, setDateProject] = useState(data.date_project)
  const [sesi, setSesi] = useState(data.sesi)
  const [sesiStart, setSesiStart] = useState(data.sesi_start)
  const [sesiEnd, setSesiEnd] = useState(data.sesi_end)
  const [dailySalary, setDailySalary] = useState(data.upah_harian)
  const [sesiSalary, setSesiSalary] = useState(data.upah_per_sesi)
  const [insentifSalary, setInsentifSalary] = useState(data.upah_insentif)
  const [transportSalary, setTransportSalary] = useState(data.upah_transport)
  const [serviceSalary, setServiceSalary] = useState(data.upah_tambahan_layanan)
  const [buildingSalary, setBuildingSalary] = useState(data.upah_fee_building)
  const [total, setTotal] = useState(data.total)
  const [note, setNote] = useState(data.note)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  useEffect(() => {
    const newSesiSalary = (+dailySalary / 8) * 3
    setSesiSalary(newSesiSalary)
  }, [dailySalary])

  useEffect(() => {
    const newTotal =
      +sesiSalary +
      +insentifSalary +
      +transportSalary +
      +serviceSalary +
      +buildingSalary

    setTotal(newTotal)
  }, [
    dailySalary,
    sesiSalary,
    insentifSalary,
    transportSalary,
    serviceSalary,
    buildingSalary,
  ])

  const workerSelectHandler = (options) => {
    setWorkerSelected(options)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const id = data.id
    const workerId = workerSelected.value
    const workerName = workerSelected.label
    const payload = {
      project_id: data.project_id,
      date_project: dateProject,
      worker_id: workerId,
      worker_name: workerName,
      upah_harian: dailySalary,
      upah_per_sesi: sesiSalary,
      upah_insentif: insentifSalary ? insentifSalary : 0,
      upah_transport: transportSalary ? transportSalary : 0,
      upah_tambahan_layanan: serviceSalary ? serviceSalary : 0,
      upah_fee_building: buildingSalary ? buildingSalary : 0,
      total: total,
      note: note,
      sesi: sesi,
      sesi_start: sesiStart,
      sesi_end: sesiEnd,
    }

    SalaryService.addWorker(id, payload)
      .then((res) => {
        console.log(res)
        notifySuccess(`Tukang berhasil ditambahkan`)
        props.setUpdate(true)
        props.closeModal()
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
      })
  }

  const forms = [
    {
      margin: 'mb-4',
      label: 'Pengerjaan Project',
      type: 'date',
      id: 'date_project',
    },
    {
      margin: 'mb-4',
      label: 'Nama Sesi',
      type: 'text',
      id: 'sesi_name',
    },
    {
      margin: 'mb-4',
      label: ' Sesi Start',
      type: 'text',
      id: 'sesi_start',
    },
    {
      margin: 'mb-4',
      label: 'Sesi End',
      type: 'text',
      id: 'sesi_end',
    },
    {
      margin: 'mb-4',
      label: 'Upah Harian',
      type: 'number',
      id: 'dailySalary',
      value: dailySalary,
      changeHandler: setDailySalary,
    },
    {
      margin: 'mb-4',
      label: 'Upah Per Sesi',
      type: 'number',
      id: 'sesiSalary',
      value: sesiSalary,
      changeHandler: setSesiSalary,
      disabled: true,
    },
    {
      margin: 'mb-4',
      label: 'Insentif',
      type: 'number',
      id: 'insentifSalary',
      value: insentifSalary,
      changeHandler: setInsentifSalary,
    },
    {
      margin: 'mb-4',
      label: 'Transport',
      type: 'number',
      id: 'transportSalary',
      value: transportSalary,
      changeHandler: setTransportSalary,
    },
    {
      margin: 'mb-4',
      label: 'Bangunan',
      type: 'number',
      id: 'buildingSalary',
      value: buildingSalary,
      changeHandler: setBuildingSalary,
    },
    {
      margin: 'mb-4',
      label: 'Tambahan Layanan',
      type: 'number',
      id: 'serviceSalary',
      value: serviceSalary,
      changeHandler: setServiceSalary,
    },
    {
      margin: 'mb-4',
      label: 'Total',
      type: 'number',
      id: 'total',
      value: total,
      changeHandler: setTotal,
      disabled: true,
    },
    {
      margin: 'mb-4',
      label: 'Keterangan',
      type: 'text',
      id: 'note',
      value: note,
      changeHandler: setNote,
    },
  ]

  return (
    <div
      className={`modal fade ${activeModal}`}
      id="modalAddMitra10"
      style={activeStyle}
      role="dialog"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered text-center "
        role="document"
      >
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h5 className="modal-title fw-bold">
              ID Project: #{data.project_id}
            </h5>
            <button
              aria-label="Close"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={props.closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap text-center table-bordered">
                <thead className="table-info">
                  <tr>
                    <th>Tanggal</th>
                    <th>Client Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.date_project}</td>
                    <td>{data.client_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <form className="form-horizontal" onSubmit={submitHandler}>
              <div className="modal-body">
                <div className="row mb-2">
                  <label htmlFor="worker" className="col-md-3 form-label">
                    Nama Tukang
                  </label>
                  <div className="col-md-9">
                    <Select
                      options={workers}
                      value={workerSelected}
                      onChange={workerSelectHandler}
                    />
                  </div>
                </div>
                {forms.map((form) => (
                  <InputForm key={form.id} {...form} />
                ))}
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary">Tambah Tukang</button>
                <button
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={props.closeModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddModal
