import { Fragment } from 'react'
// import Button from '../../components/Button'
import { convertToRupiah } from '../../helper/Rupiah'

const PriceItem = (props) => {
  const data = props.data

  // const editHandler = () => {
  //   props.setModal(true)
  //   props.setDataEdit(props)
  // }

  return (
    <Fragment>
      <tr>
        <td className="align-middle text-center">{props.no}</td>
        <td className="align-middle text-center">
          <img
            alt="image"
            className="avatar avatar-md br-7"
            src={data.image_uri}
          />
        </td>
        <td className="text-center align-middle">{data.name}</td>
        <td>Rp. {convertToRupiah(data.standard_price)}</td>
        <td>Rp. {convertToRupiah(data.session1_price)}</td>
        <td>Rp. {convertToRupiah(data.session2_price)}</td>
        <td className="text-center align-middle">
          <button className="btn btn-sm btn-warning" disabled>
            Temporarily Disabled
          </button>
          {/* <Button
            button="btn-warning btn-sm"
            icon="fa fa-pencil me-1"
            title="Edit"
            onClick={editHandler}
          /> */}
        </td>
      </tr>
    </Fragment>
  )
}

export default PriceItem
