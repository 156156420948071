import { toast } from 'react-toastify'
import { Toast } from '../../../config/Toast'
import Modal from '../../../components/Modal'
import Recalculate from '../../../services/Recalculate'
import 'react-toastify/dist/ReactToastify.css'

const RefundModal = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    props.setLoadingRefund(true)
    props.closeModal()

    const payload = {
      start: props.startDate,
      end: props.endDate,
      module: 'refund-kontraktor',
    }

    Recalculate.recalculate(payload)
      .then(() => {
        notifySuccess('Success generate refund kontraktor')
        props.setLoadingRefund(false)
      })
      .catch((err) => {
        console.log(err)
        props.setLoadingRefund(false)
        notifyFailed('Failed generate refund kontraktor')
      })
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Generate Refund Kontraktor"
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Generate"
    >
      <h5>
        Pastikan Upah Tukang sudah benar, data akan mengenerate <br />
        report dari tanggal
        <div className="fw-bold mt-3">
          {props.startDate} s.d {props.endDate}
        </div>
      </h5>
    </Modal>
  )
}

export default RefundModal
