import moment from 'moment'
import { convertToRupiah } from '../../../helper/Rupiah'

const CompleteTableItem = (props) => {
  const master = props.master
  const data = props.data
  const paydate = moment(data.next_paydate).format('DD/MM/YYYY')
  let amount
  let duration = data.duration

  if (master.is_installment == 0) {
    amount = master.amount
    duration = `${master.days} Hari`
  } else {
    amount = master.amount_installment
  }

  return (
    <tr>
      <td>{props.no + 1}</td>
      <td>{paydate}</td>
      <td>{moment(data.paydate).format('DD/MM/YYYY')}</td>
      <td>Rp. {convertToRupiah(amount)}</td>
      <td>{duration}</td>
      <td className="fw-bold text-success">Lunas</td>
      <td>{data.note}</td>
    </tr>
  )
}

export default CompleteTableItem
