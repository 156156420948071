import Badge from '../../../components/Badge'
import Button from '../../../components/Button'
import OngoingTableItem from './OngoingTableItem'

const OngoingItem = (props) => {
  const data = props.data
  const imageExists = data.image_evidance ? true : false
  let badgeClass, badgeName

  if (data.status == 3 || data.status == 4) {
    badgeClass = 'bg-info px-5'
    badgeName = 'Sedang Berlangsung'
  } else if (data.status == 5) {
    badgeClass = 'bg-success px-6'
    badgeName = 'Lunas'
  }

  const tHead = [
    'No',
    'Due Date',
    'Payment Date',
    'Amount',
    'Duration',
    'Payment Status',
    'Note',
  ]

  const imageHandler = () => {
    props.imageHandler(data.image_evidance)
  }

  return (
    <div className="card p-4 border">
      <div className="card bg-light p-3">
        <div className="row">
          <div className="col text-start">
            <span>PIC</span>
            <p className="fw-bold">{data.pic_name}</p>
          </div>
          <div className="col text-start">
            <span>Note</span>
            <p className="fw-bold">{data.note}</p>
          </div>
          <div className="col text-end">
            {imageExists && (
              <Button
                button="btn-outline-dark"
                title="Lihat Tanda Bukti"
                onClick={imageHandler}
              />
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col text-start">
          <h5 className="ms-1">Status Pembayaran</h5>
        </div>
        <div className="col text-end">
          <Badge badge={badgeClass} name={badgeName} type="rounded-pill py-2" />
        </div>
      </div>

      <table className="table table-bordered  mt-2">
        {tHead.map((d) => (
          <th className="fw-bold">{d}</th>
        ))}
        <tbody>
          {data.list.map((d, index) => (
            <OngoingTableItem
              key={index}
              no={index}
              data={d}
              master={data}
              editHandler={props.editHandler}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default OngoingItem
