import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class Parameter {
  syncWorkers() {
    const url =
      process.env.REACT_APP_API_URL_V1 + `/parameter/tarik-data-worker`
    return axios.get(url, headersTokenConfig)
  }

  getSetup() {
    const url = process.env.REACT_APP_API_URL_V1 + `/parameter/setup`
    return axios.get(url, headersTokenConfig)
  }

  updateSetup(data) {
    const url = process.env.REACT_APP_API_URL_V1 + `/parameter/update`
    return axios.post(url, data, headersTokenConfig)
  }
}

export default new Parameter()
