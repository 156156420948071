import { useState } from 'react'
import Modal from '../../components/Modal'
import InputForm from '../../components/InputForm'

const Topup = (props) => {
  const data = props.data.data
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const [amount, setAmount] = useState(0)

  const submitHandler = () => {
    props.onConfirm({ workerId: data.user_id, amount })
  }

  const forms = [
    {
      margin: 'mb-4',
      label: 'Nominal',
      type: 'number',
      id: 'price',
      placeholder:
        data?.amount ||
        'Nominal deposit kelipatan Rp18.000 (cth: Rp.18.000, Rp36.000, Rp54.000, dst.)',
      changeHandler: setAmount,
    },
  ]

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Biaya Bulanan BPJS"
      closeClick={props.closeClick}
      submitHandler={submitHandler}
      type="Masukkan"
      modalType="modal-lg"
    >
      {forms.map((form) => (
        <InputForm key={form.id} {...form} />
      ))}
    </Modal>
  )
}

export default Topup
