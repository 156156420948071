import React from 'react'
import { Fragment } from 'react'

const ButtonDropdown = (props) => {
  const loading = props.loading ? props.loading : false

  return (
    <Fragment>
      {loading && (
        <button className={`btn ${props.button}`} disabled>
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      )}

      {!loading && (
        <div className="d-inline-flex ms-1">
          <div className="dropdown">
            <button
              type="button"
              className={`btn ${props.button} dropdown-toggle`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {props.title}
            </button>
            <div className="dropdown-menu">
              {props.dropdown.map((d) => (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  onClick={d.clickFunction}
                >
                  {d.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ButtonDropdown
