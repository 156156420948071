import { toast } from "react-toastify";

import Modal from "../../../components/Modal";
import { Toast } from "../../../config/Toast";
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';

import RefundMaterialService from "../../../services/RefundMaterial";

function ConfirmRefundMaterial(props) {
  const { activeModal = false, closeClick, dataConfirm, getDataRefund } = props;
  const { currentUser } = useContext(AuthContext)

  const notifySucces = (message) => toast.success(message, Toast);
  const notifyFailed = (message) => toast.error(message, Toast);

  const submitHandler = () => {
    const payload = {
      refund_by: currentUser.id,
      total_refund: dataConfirm.total_refund,
      admin_fee: dataConfirm.admin_fee,
      refund_fee: dataConfirm.refund_fee,
    };

    RefundMaterialService.confirmRefundMaterial(
      dataConfirm.material_refund_id,
      payload
    )
      .then(() => {
        getDataRefund();
        notifySucces("Berhasil refund material");
        closeClick();
      })
      .catch((err) => {
        console.log(err);
        notifyFailed(
          err.response.data.message ||
            "Gagal refund material, silahkan coba kembali"
        );
        closeClick();
      });
  };

  return (
    <Modal
      activeModal={activeModal}
      closeClick={closeClick}
      submitHandler={submitHandler}
      title="Refund Material"
      type="Simpan"
    >
      <div>
        <p>Apakah Anda yakin telah membayar refund material?</p>
        <p className="fw-bold">
          Setelah proses ini, data sudah tidak bisa lagi dikembalikan semula.
        </p>
      </div>
    </Modal>
  );
}

export default ConfirmRefundMaterial;
