import OngoingItem from './OngoingItem'
import { Fragment } from 'react'

const Ongoing = (props) => {
  const data = props.data

  return (
    <Fragment>
      {data.map((d, index) => (
        <OngoingItem
          key={index}
          data={d}
          editHandler={props.editHandler}
          imageHandler={props.imageHandler}
        />
      ))}
    </Fragment>
  )
}

export default Ongoing
