const TabItem = (props) => {
  const activeClass = props.defaultId === props.id ? 'active' : ''

  return (
    <li>
      <a
        href={props.id}
        id={props.id}
        data-bs-toggle="tab"
        onClick={props.tabHandler}
        className={`${activeClass} px-4`}
      >
        {props.name}
      </a>
    </li>
  )
}

export default TabItem
