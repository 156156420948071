import { toast } from 'react-toastify'
import { Toast } from '../../../config/Toast'
import Modal from '../../../components/Modal'
import SalaryCalculationService from '../../../services/SalaryCalculation'
import 'react-toastify/dist/ReactToastify.css'

const PostingModal = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    props.setLoadingPosting(true)
    props.closeModal()

    SalaryCalculationService.posting(props.startDate, props.endDate)
      .then(() => {
        notifySuccess('Success posting data')
        props.setLoadingPosting(false)
        props.setUpdate(true)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
        props.setLoadingPosting(false)
      })
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Posting Perhitungan Upah"
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Posting"
    >
      <h5>
        Proses ini akan membentuk laporan disbursement ke worker <br />
        dan akan mengupgrade status is_disburse pada worker apps. <br />
        <br />
        <div className="fw-bold">
          Setelah proses ini, <br /> upah tukang sudah tidak bisa lagi di
          koreksi.
        </div>
      </h5>
    </Modal>
  )
}

export default PostingModal
