import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Toast } from '../../../config/Toast'
import Modal from '../../../components/Modal'
import InputForm from '../../../components/InputForm'
import SalaryService from '../../../services/SalaryCalculation'
import 'react-toastify/dist/ReactToastify.css'

const EditSalaryModal = (props) => {
  const data = props.dataModal.data
  const [dailySalary, setDailySalary] = useState(
    data.upah_harian ? data.upah_harian : 0
  )
  const [insentifSalary, setInsentifSalary] = useState(
    data.upah_add ? data.upah_add : 0
  )
  const [transportSalary, setTransportSalary] = useState(
    data.upah_transport ? data.upah_transport : 0
  )
  const [keramikSalary, setKeramikSalary] = useState(
    data.upah_add_keramik ? data.upah_add_keramik : 0
  )
  const [lasSalary, setLasSalary] = useState(
    data.upah_add_las ? data.upah_add_las : 0
  )
  const [buildingSalary, setBuildingSalary] = useState(
    data.upah_fee_building ? data.upah_fee_building : 0
  )
  // const [complaintAmount, setComplaintAmount] = useState(
  //   data.potongan_keluhan ? data.potongan_keluhan : 0
  // )
  const [total, setTotal] = useState(data.total_upah ? data.total_upah : 0)
  const [note, setNote] = useState(data?.keterangan || null)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  useEffect(() => {
    const newTotal =
      +dailySalary +
      +insentifSalary +
      +transportSalary +
      +keramikSalary +
      +lasSalary +
      +buildingSalary
    // +complaintAmount

    setTotal(+newTotal)
  }, [
    dailySalary,
    insentifSalary,
    transportSalary,
    keramikSalary,
    lasSalary,
    buildingSalary,
    // complaintAmount,
  ])

  const submitHandler = (e) => {
    const id = data.id
    const payload = {
      upah_harian: dailySalary,
      upah_add: insentifSalary,
      upah_transport: transportSalary,
      upah_add_keramik: keramikSalary,
      upah_add_las: lasSalary,
      upah_fee_building: buildingSalary,
      // potongan_keluhan: complaintAmount,
      total_upah: total,
      keterangan: note,
    }

    SalaryService.edit(id, payload)
      .then(() => {
        notifySuccess(`Upah berhasil diperbarui`)
        props.setUpdate(true)
        props.closeModal()
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(`Upah gagal diperbarui`)
      })
  }

  const forms = [
    {
      margin: 'mb-2',
      label: ' Upah Harian',
      type: 'number',
      id: 'dailySalary',
      value: dailySalary,
      changeHandler: setDailySalary,
    },
    {
      margin: 'mb-2',
      label: 'Insentif',
      type: 'number',
      id: 'insentifSalary',
      value: insentifSalary,
      changeHandler: setInsentifSalary,
    },
    {
      margin: 'mb-2',
      label: 'Transport',
      type: 'number',
      id: 'transportSalary',
      value: transportSalary,
      changeHandler: setTransportSalary,
    },
    {
      margin: 'mb-2',
      label: 'Keramik',
      type: 'number',
      id: 'keramikSalary',
      value: keramikSalary,
      changeHandler: setKeramikSalary,
    },
    {
      margin: 'mb-2',
      label: 'Las',
      type: 'number',
      id: 'lasSalary',
      value: lasSalary,
      changeHandler: setLasSalary,
    },
    {
      margin: 'mb-2',
      label: 'Bangunan',
      type: 'number',
      id: 'buildingSalary',
      value: buildingSalary,
      changeHandler: setBuildingSalary,
    },
    // {
    //   margin: 'mb-2',
    //   label: 'Potongan Keluhan',
    //   type: 'number',
    //   id: 'complaintAmount',
    //   value: complaintAmount,
    //   changeHandler: setComplaintAmount,
    // },
    {
      margin: 'mb-2',
      label: 'Total',
      type: 'number',
      id: 'total',
      value: total,
      changeHandler: setTotal,
      disabled: true,
    },
    {
      margin: 'mb-2',
      label: 'Keterangan',
      type: 'text',
      id: 'note',
      value: note,
      changeHandler: setNote,
    },
  ]

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title={`ID Project: #${data.project_id}`}
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Update"
      modalType="modal-lg"
    >
      {forms.map((form) => (
        <InputForm key={form.id} {...form} />
      ))}
    </Modal>
  )
}

export default EditSalaryModal
