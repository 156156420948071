import { convertToRupiah } from '../../../helper/Rupiah'

const VoucherTableItem = (props) => {
  const data = props.data
  let voucherDiscount

  if (data.voucher_type === 'persentase') {
    voucherDiscount = `${data.voucherDiscount}%`
  } else {
    voucherDiscount = `Rp. ${convertToRupiah(data.voucher_discount)}`
  }

  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.project_id}</td>
      <td>Consumer</td>
      <td>{data.vendor_name}</td>
      <td>{data.created_at}</td>
      <td>{data.voucher_code}</td>
      <td>{voucherDiscount}</td>
      <td>Rp. {convertToRupiah(data.original_amount)}</td>
      <td>Rp. {convertToRupiah(data.discount_amount)}</td>
      <td>Rp. {convertToRupiah(data.paid_amount)}</td>
    </tr>
  )
}

export default VoucherTableItem
