const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-md-12 col-sm-12 text-center">
            Copyright © <span id="year" />
            {new Date().getFullYear()}
            <a href="javascript:void(0)"> RMS</a>. Designed with
            <span className="fa fa-heart text-danger" /> by
            <a href="javascript:void(0)"> Kanggo </a> All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
