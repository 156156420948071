import { useState, useEffect } from 'react'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import InputGroup from '../../components/InputGroup'
import Pagination from '../../components/Pagination'
import BonusPointItem from './BonusPointItem'
import BonusPointService from '../../services/BonusPoint'
import Loader from '../../components/Loader'
import Detail from './Detail'

const BonusPoint = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [modal, setModal] = useState(false)
  const [dataDetail, setDataDetail] = useState(null)
  const [search, setSearch] = useState(null)

  const limit = 10

  // Fetch Data
  useEffect(() => {
    setLoading(true)

    BonusPointService.workers(pageNumber, limit, search)
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
      })
      .catch((err) => {
        console.log(err)
      })

    setIsUpdated(false)
    setLoading(false)
  }, [isUpdated])

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setIsUpdated(true)
  }

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setIsUpdated(true)
  }

  const closeEditHandler = () => {
    setModal(false)
  }

  if (loading === true) {
    return <Loader />
  }

  return (
    <Main title="Point & Bonus">
      {modal && (
        <Detail
          data={dataDetail}
          activeModal={modal}
          closeClick={closeEditHandler}
        />
      )}

      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row justify-content-end">
          <div className="col">
            <div className="card-title">Mutasi Point</div>
          </div>
          <div className="col-5">
            <InputGroup
              button="btn btn-dark"
              icon="fa fa-search"
              searchHandler={searchHandler}
            />
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap mb-0">
                <thead>
                  <tr>
                    <th className="text-center">No</th>
                    <th className="text-center">Worker ID</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, index) => (
                    <BonusPointItem
                      key={d.user_id}
                      no={
                        index +
                        (pageNumber === 1 ? 1 : limit * pageNumber - limit + 1)
                      }
                      worker_id={d.user_id}
                      name={d.name}
                      setModal={setModal}
                      setDataDetail={setDataDetail}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Pagination pageCount={totalPage} changePage={changePageHandler} />
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default BonusPoint
