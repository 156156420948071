import { Fragment, useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import ButtonGroup from '../../../components/ButtonGroup'
import InputGroup from '../../../components/InputGroup'
import InputGroupDate from '../../../components/InputGroupDate'
import Pagination from '../../../components/Pagination'
import KasbonService from '../../../services/Kasbon'
import { Toast } from '../../../config/Toast'
import KasbonItem from './KasbonItem'
import Posting from './Posting'
import Detail from './Detail'
import Edit from './Edit'
import ImageEvidance from './ImageEvidance'
import 'react-loading-skeleton/dist/skeleton.css'

const KasbonList = () => {
  const [data, setData] = useState([])
  const [dataId, setDataId] = useState(null)
  const [dataDetail, setDataDetail] = useState(null)
  const [historyId, setHistoryId] = useState()
  const [image, setImage] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loadingFilter, setLoadingFilter] = useState(false)
  const [loadingPost, setLoadingPost] = useState(false)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalPosting, setModalPosting] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalImage, setModalImage] = useState(false)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)
  const limit = 5

  useEffect(() => {
    if (startDate && endDate) {
      setLoading(true)

      KasbonService.list(pageNumber, limit, search, startDate, endDate)
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setUpdate(false)
        })
        .catch((err) => {
          console.log(err)
          setUpdate(false)
        })

      setTimeout(() => {
        setLoading(false)
      }, 1200)
    }
  }, [update])

  const filterHandler = () => {
    if (startDate && endDate) {
      setLoadingFilter(true)
      setLoading(true)

      KasbonService.list(pageNumber, limit, search, startDate, endDate)
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setLoadingFilter(false)
        })
        .catch((err) => {
          console.log(err)
          setLoadingFilter(false)
        })

      setTimeout(() => {
        setLoading(false)
      }, 1200)
    } else {
      notifyFailed('Periode rentang waktu tidak boleh kosong')
    }
  }

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setUpdate(true)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setUpdate(true)
  }

  const postHandler = () => {
    if (!startDate || !endDate) {
      notifyFailed('Periode rentang waktu tidak boleh kosong')
      return
    }

    setModalPosting(true)
  }

  const postingHandler = () => {
    setLoadingPost(true)

    const payload = {
      start: startDate,
      end: endDate,
    }

    KasbonService.post(payload)
      .then(() => {
        notifySuccess('Barhasil posting data kasbon tukang')
        setUpdate(true)
        setLoadingPost(false)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Gagal posting data kasbon tukang')
        setLoadingPost(false)
      })
  }

  const detailHandler = (id, workerName, value) => {
    value.id = id
    value.worker_name = workerName
    setDataId(id)
    setDataDetail(value)
    setModalDetail(true)
  }

  const editHandler = (id) => {
    setHistoryId(id)
    setModalEdit(true)
  }

  const updateHandler = (note) => {
    setUpdate(true)
    setModalEdit(false)
    setModalDetail(false)

    KasbonService.update(dataId, historyId, { note })
      .then(() => {
        notifySuccess('Kasbon tukang berhasil terbayar')
        setUpdate(false)
      })
      .catch((err) => {
        console.log(err)
        setUpdate(false)
        notifyFailed('Kasbon tukang gagal terbayar')
      })
  }

  const imageHandler = (imageUrl) => {
    setImage(imageUrl)
    setModalImage(true)
  }

  const closeModalHandler = () => {
    if (modalEdit) {
      setModalEdit(false)
    } else if (modalImage) {
      setModalImage(false)
    } else {
      setModalPosting(false)
      setModalDetail(false)
      setModalEdit(false)
      setModalImage(false)
    }
  }

  const tHead = [
    'No',
    'Worker Name',
    'Worker Id',
    'Initial Amount',
    'Action',
    'Kasbon Unpaid',
    'Deduction',
    'Deduction Note',
    'Final Amount',
    'Status',
  ]

  return (
    <Fragment>
      {modalPosting && (
        <Posting
          activeModal={modalPosting}
          closeModal={closeModalHandler}
          onConfirm={postingHandler}
        />
      )}

      {modalDetail && (
        <Detail
          activeModal={modalDetail}
          closeModal={closeModalHandler}
          data={dataDetail}
          editHandler={editHandler}
          imageHandler={imageHandler}
        />
      )}

      {modalEdit && (
        <Edit
          activeModal={modalEdit}
          closeModal={closeModalHandler}
          updateHandler={updateHandler}
        />
      )}

      {modalImage && (
        <ImageEvidance
          activeModal={modalImage}
          closeModal={closeModalHandler}
          data={image}
        />
      )}

      <div className="row">
        <div className="col-3">
          <InputGroupDate
            type="startDate"
            label="Start Date"
            setStartDate={setStartDate}
          />
        </div>
        <div className="col-3">
          <InputGroupDate
            type="endDate"
            label="End Date"
            setEndDate={setEndDate}
          />
        </div>
        <div className="col">
          <div className="row mt-5">
            <div className="col mt-4">
              <ButtonGroup
                buttonOne="btn-primary"
                titleOne="Filter"
                iconOne="fa fa-filter me-1"
                onClickOne={filterHandler}
                loadingOne={loadingFilter}
                buttonTwo="btn-orange"
                titleTwo="Posting"
                iconTwo="ti-angle-double-right me-1"
                onClickTwo={postHandler}
                loadingTwo={loadingPost}
              />
            </div>
            <div className="col mt-4">
              <InputGroup
                button="btn-primary"
                icon="fa fa-search"
                searchHandler={searchHandler}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover text-nowrap text-md-nowrap mb-0 text-center">
              <thead>
                <tr>
                  {tHead.map((d) => (
                    <th>{d}</th>
                  ))}
                </tr>
              </thead>
              {loading &&
                tHead.map(() => (
                  <td>
                    <Skeleton
                      count={5}
                      variant="rectangular"
                      height={33}
                      style={{
                        marginBottom: '0.5rem',
                      }}
                    />
                  </td>
                ))}
              {!loading && (
                <tbody>
                  {data.map((d, index) => (
                    <KasbonItem
                      key={d.id}
                      no={
                        index +
                        (pageNumber == 1 ? 1 : limit * pageNumber - limit + 1)
                      }
                      data={d}
                      setUpdate={setUpdate}
                      detailHandler={detailHandler}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          {totalPage > 0 && (
            <Pagination pageCount={totalPage} changePage={changePageHandler} />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default KasbonList
