import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import Button from '../../components/Button'
import Pagination from '../../components/Pagination'
import WorkerRefundService from '../../services/WorkerRefundService'
import WorkerRefundItem from './WorkerRefundItem'
import Confirm from './Confirm'
import { Toast } from '../../config/Toast'

const UserLists = () => {
  const [workers, setWorkers] = useState([])
  const [isUpdated, setIsUpdated] = useState(false)
  const [modal, setModal] = useState(false)
  const [data, setData] = useState(null)
  const [btnLoading, setBtnLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const notifySucces = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)
  const limit = 5

  useEffect(() => {
    WorkerRefundService.listWorkers(pageNumber, limit)
      .then((res) => {
        setWorkers(res.data.data)
        setTotalPage(res.data.total_page)
        setIsUpdated(false)
      })
      .catch((err) => {
        console.log(err)
        setIsUpdated(false)
      })
  }, [isUpdated])

  const syncWorkerHandler = () => {
    setBtnLoading(true)

    WorkerRefundService.syncWorker()
      .then((res) => {
        notifySucces(res.data.message)
        setIsUpdated(true)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err)
      })

    setBtnLoading(false)
  }

  const confirmHandler = (data) => {
    setData(data)
    setModal(true)
  }

  const closeEditHandler = () => {
    setModal(false)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setIsUpdated(true)
  }

  return (
    <Main title="User Lists">
      {modal && (
        <Confirm
          data={data}
          activeModal={modal}
          setIsUpdated={setIsUpdated}
          closeModal={closeEditHandler}
        />
      )}

      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row">
          <div className="col">
            <Button
              button="btn-primary"
              title="Refresh"
              icon="fa fa-rotate-right me-1"
              onClick={syncWorkerHandler}
              loading={btnLoading}
            />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap text-center mb-0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Project ID</th>
                    <th>Worker ID</th>
                    <th>Worker Name</th>
                    <th>Persentase</th>
                    <th>Total Upah</th>
                    <th>Debt</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {workers.map((worker, index) => (
                    <WorkerRefundItem
                      key={worker.id}
                      no={index + 1}
                      data={worker}
                      confirmHandler={confirmHandler}
                      setUpdated={setIsUpdated}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            {totalPage > 0 && (
              <Pagination
                pageCount={totalPage}
                changePage={changePageHandler}
              />
            )}
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default UserLists
