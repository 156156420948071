import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Toast } from "../../config/Toast";
import Modal from "../../components/Modal";
import Required from "../../components/Required";
import InputAutocomplete from "../../components/InputAutocomplete";
import InputFormNumber from "../../components/InputFormNumber";
import SkillSalaryCutService from "../../services/SkillSalaryCut";

const ModalAddEditSkill = (props) => {
  const [loading, setLoading] = useState(false)
  const [selectedItemSkill, setSelectedItemSkill] = useState(null);
  const [initDataDropdown, setInitDataDropdown] = useState([]);
  const [valid, setValid] = useState(false);
  const [messageValidation, setMessageValidation] = useState(null);

  const notifySuccess = (message) => toast.success(message, Toast);
  const notifyFailed = (message) => toast.error(message, Toast);

  const onChangeForm = (value, name) => {
    let temp = {}
    setMessageValidation(null)

    switch (name) {
      case 'skill':
        if (value.id) {
          temp = {
            skill_id: value.id,
            skill_name: value.name
          }
        } else {
          temp = {
            skill_id: 0,
            skill_name: value.name
          }
        }
        break;

      case 'type':
        temp = {
          type: +value
        }
        break;

        case 'salary_cut':
        temp = {
          salary_cut: value.replace(/,/g, "")
        }
        break;

        default:
        temp = {
          [name]: value
        }
        break;
    }

    let result = {
      ...selectedItemSkill,
      ...temp
    }
    setSelectedItemSkill(result)
    const isEmptySelectedItem = Object.entries(result).some(([key, value]) =>
      (key !== 'id') && (value === null || value === '' || value === 0)
    );

    if (isEmptySelectedItem) {
      setValid(false)
    } else {
      setValid(true)
    }
  }

  const onCloseModal = () => {
    props.closeModal()
    setSelectedItemSkill(null);
    setMessageValidation(null);
  };

  const onCheckValidation = () => {
    if (selectedItemSkill === null) {
      setMessageValidation("Harap isi semua form yang ada");
      return;
    }

    if (selectedItemSkill.skill_id === 0) {
      setMessageValidation("Harap pilih Keahlian dengan benar");
      return;
    }

    if (selectedItemSkill.type === 0) {
      setMessageValidation("Harap pilih Tipe Keahlian");
      return;
    }

    if (selectedItemSkill.salary_cut === "") {
      setMessageValidation("Harap isi Pemotongan Upah");
      return;
    }
  }

  const onSubmitModal = () => {
    const typeModal = props.typeModal
    const payload = {
      type: selectedItemSkill.type,
      salary_cut: +selectedItemSkill.salary_cut,
    };

    if (typeModal === 'add') { 
      payload.skill_id = selectedItemSkill.skill_id
    }

    const apiCall = typeModal === 'add' ? SkillSalaryCutService.createSalaryCut(payload) : SkillSalaryCutService.updateSalaryCut(selectedItemSkill.id, payload);

    apiCall
    .then(() => {
      notifySuccess(`Berhasil ${typeModal === 'add' ? 'Tambah' : 'Ubah'} Keahlian`);
      onCloseModal();
      props.refreshData();
    })
    .catch((error) => {
      console.error(error);
      const err = error.response?.data?.message || 'Terjadi kesalahan, silahkan coba lagi';
      setMessageValidation(err);
      notifyFailed(err);
    });
  };

  const getDataSkill = () => {
    setLoading(true)
    SkillSalaryCutService.listDropdown().then(response => {
      const res = response.data.data
      setInitDataDropdown(res);
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      console.log(error)
      notifyFailed(error.response.data.message || "Terjadi kesalahan, silahkan coba lagi");
    })
  };

  useEffect(() => {
    getDataSkill();
  }, []);

  useEffect(() => {
    setSelectedItemSkill(props.selectedItemSkill);
  }, [props]);

  return (
    <Modal
      activeModal={props.activeModal}
      title={`${props.typeModal === "add"
        ? "Tambah Keahlian"
        : `Ubah ${props.selectedItemSkill?.skill_name || ""}`
      }`}
      modalType="modal-lg"
      type={props.typeModal === "add" ? "Tambah" : "Simpan"}
      closeClick={onCloseModal}
      submitHandler={valid ? onSubmitModal : onCheckValidation }
    >
      {selectedItemSkill && (
        <div>
          <div className="card bg-light p-4 border" style={{height: '20em'}}>
            <div className="row mb-4">
              <div className="col text-start position-relative">
                <p className="mb-2">
                  Keahlian <Required />
                </p>
                {!loading && (
                  <InputAutocomplete
                    defaultValue={selectedItemSkill?.skill_name}
                    listData={initDataDropdown}
                    handleSelect={(e) => onChangeForm(e, 'skill')}
                    attributeName="name"
                    attributeImage="image_uri"
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <p className="mb-2">
                  Tipe Keahlian <Required />
                </p>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedItemSkill?.type}
                  onChange={(e) => onChangeForm(e.target.value, 'type')}
                >
                  <option value={0} disabled>
                    Pilih Tipe
                  </option>
                  <option value={1}>B2B</option>
                  <option value={2}>B2C</option>
                </select>
              </div>
              <div className="col text-start">
                <p className="mb-2">
                  Pemotongan Upah <Required />
                </p>
                <InputFormNumber
                  defaultValue={selectedItemSkill?.salary_cut}
                  onChange={(e) => onChangeForm(e, 'salary_cut')}
                />
              </div>
            </div>
          </div>
          {messageValidation && (
            <div className="text-red text-start" style={{ marginTop: "-10px" }}>
              {messageValidation}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ModalAddEditSkill;
