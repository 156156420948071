const Loader = () => {
  return (
    <div id="global-loader">
      <img
        src={`${window.location.origin}/assets/images/loader.svg`}
        className="loader-img"
        alt="Loader"
      />
    </div>
  )
}

export default Loader
