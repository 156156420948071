import Modal from '../../components/Modal'

const Download = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const submitHandler = () => {
    props.downloadHandler()
    props.closeModal()
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title=""
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Unduh Sekarang"
      modalHeader="false"
    >
      <img
        src={`${window.location.origin}/assets/images/logo/download.png`}
        className="header-brand-img desktop-logo pt-4"
        alt="logo"
        style={{ width: '20%' }}
      />
      <h4 className="fw-bold text-center mt-6">
        Unduh Pengajuan Kasbon {props.data.worker_name}
      </h4>
      <h5 className="mt-2">
        Pengajuan kasbon worker sudah disetujui FO Manager dan Finance
      </h5>
    </Modal>
  )
}

export default Download
