import { useState, useEffect } from "react";

const InputFormNumber = (props) => {
  const [value, setValue] = useState(props.defaultValue.toString() || '0');
  const [cursorPos, setCursorPos] = useState(null);

  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChange = (event) => {
    let inputValue = event.target.value;
    let cursorPosition = event.target.selectionStart;

    inputValue = inputValue.replace(/\D/g, "");
    const formattedValue = formatNumber(inputValue);

    setValue(formattedValue);
    props.onChange(formattedValue)

    // Adjust cursor position
    const numCommas = (formattedValue.match(/,/g) || []).length;
    const prevNumCommas = (value.match(/,/g) || []).length;
    const newCursorPosition = cursorPosition + (numCommas - prevNumCommas);

    setCursorPos(newCursorPosition);
  };

  const handleBlur = () => {
    setCursorPos(null);
  };

  useEffect(() => {
    if (props) {
      setValue(formatNumber(props.defaultValue))
    }
  }, [props])

  return (
    <div>
      <input
        className="form-control"
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Enter a number"
        ref={(input) => {
          if (input && cursorPos !== null) {
            input.selectionStart = cursorPos;
            input.selectionEnd = cursorPos;
          }
        }}
      />
    </div>
  );
};

export default InputFormNumber;
