import { ToastContainer, Flip } from 'react-toastify'

import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'

const Main = (props) => {
  return (
    <div className="page-main">
      <Header />
      <Sidebar />

      <ToastContainer
        transition={Flip}
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />

      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header">
              <h1 className="page-title">{props.title}</h1>
            </div>
            {props.children}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Main
