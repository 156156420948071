import { useState, useEffect } from 'react'
import { JsonEditor as Editor } from 'jsoneditor-react'
import { toast } from 'react-toastify'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import Button from '../../components/Button'
import ParameterService from '../../services/Parameter'
import { Toast } from '../../config/Toast'
import 'jsoneditor-react/es/editor.min.css'

const Parameter = () => {
  const [data, setData] = useState([])
  const [dataUpdate, setDataUpdate] = useState(null)
  const [btnLoadingSetup, setBtnLoadingSetup] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  const notifySucces = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  useEffect(() => {
    ParameterService.getSetup()
      .then((res) => {
        setData(res.data.data)
        setIsUpdated(false)
      })
      .catch((err) => {
        console.log(err)
        setIsUpdated(false)
      })
  }, [isUpdated])

  // Setup json editor
  const upahParams = data.length > 0 ? JSON.parse(data[0].upah_params) : {}
  const biayaTransParams =
    data.length > 0 ? JSON.parse(data[0].biaya_trans_params) : {}
  const schema = {
    upah_params: upahParams,
    biaya_trans_params: biayaTransParams,
  }

  const updateSetupHandler = () => {
    let newData = dataUpdate

    if (dataUpdate === null) {
      newData = {
        upah_params: JSON.parse(data[0].upah_params),
        biaya_trans_params: JSON.parse(data[0].biaya_trans_params),
      }
    }

    setBtnLoadingSetup(true)

    ParameterService.updateSetup(newData)
      .then(() => {
        setBtnLoadingSetup(false)
        notifySucces(`Success update parameter`)
      })
      .catch((err) => {
        console.log(err)
        setBtnLoadingSetup(false)
        notifyFailed(err.response.data.message)
      })
  }

  return (
    <Main title="Parameter">
      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row">
          <div className="col">
            <Editor value={schema} onChange={(e) => setDataUpdate(e)} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-2">
            <Button
              button="btn-primary"
              title="Update"
              icon="fa fa-check me-1"
              onClick={updateSetupHandler}
              loading={btnLoadingSetup}
            />
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default Parameter
