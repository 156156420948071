import { useState } from 'react'

const InputGroup = (props) => {
  const [search, setSearch] = useState('')
  const icon = props.icon ? props.icon : false

  const submitHandler = (e) => {
    e.preventDefault()
    props.searchHandler(search)
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="form-group">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Type keyword here.."
            aria-label="Example text with button addon"
            aria-describedby="button-addon1"
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
            className={`btn ${props.button}`}
            type="submit"
            id="button-addon2"
          >
            {icon && <i className={props.icon}></i>}
            {props.title}
          </button>
        </div>
      </div>
    </form>
  )
}

export default InputGroup
