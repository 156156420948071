import { toast } from 'react-toastify'
import { Toast } from '../../config/Toast'
import Modal from '../../components/Modal'
import MasterBpjsService from '../../services/MasterBpjs'
import { convertToRupiah } from '../../helper/Rupiah'
import 'react-toastify/dist/ReactToastify.css'

const MasterBpjsConfirm = (props) => {
  const data = props.data

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    MasterBpjsService.update(data.id, { amount: data.amount })
      .then(() => {
        props.setUpdate(true)
        notifySuccess(`Nominal BPJS berhasil diperbaharui`)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
      })

    props.closeClick()
  }

  return (
    <Modal
      activeModal={props.activeModal}
      title="Ubah Biaya Bulanan BPJS"
      closeClick={props.closeClick}
      submitHandler={submitHandler}
      type="Ya, Ubah"
    >
      <h5>
        Nominal Biaya yang dimasukkan: <br />
        {/* <br /> */}
        <h5 className="fw-bold mt-3">Rp{convertToRupiah(data.amount)}</h5>
        Pastikan Nominal Biaya sudah sesuai.
        <br />
      </h5>
    </Modal>
  )
}

export default MasterBpjsConfirm
