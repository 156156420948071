import { useState, useEffect } from "react";
import InputFormPlain from "./InputFormPlain";

const InputAutocomplete = (props) => {
  const { attributeName, attributeImage, sizeImg, title } = props
  
  const [initData, setInitData] = useState([]);
  const [data, setData] = useState(props.listData || []);
  const [openListDropdown, setOpenDropdown] = useState(false);
  const [keyword, setKeyword] = useState("");

  const onChangeKeyword = (value) => {
    setKeyword(value);
    setData(
      initData.filter((item) =>
        item[attributeName || "name"].toLowerCase().includes(value.toLowerCase())
      )
    );
    props.handleSelect({ name: value })
  };

  const onFocusInput = () => {
    setOpenDropdown(!openListDropdown);
  };

  const onSelectData = (data) => {
    setKeyword(data[attributeName || "name"]);
    setOpenDropdown(false);
    
    props.handleSelect(data)
  };
  
  useEffect(() => {
    setOpenDropdown(false);
    if (props) {
      setInitData(props.listData)
      setData(props.listData)
      setKeyword(props.defaultValue)
    }
  }, [])

  return (
    <div>
      <InputFormPlain
        placeholder="Masukkan keahlian"
        value={keyword}
        changeHandler={onChangeKeyword}
        focusHandler={onFocusInput}
      />
      {openListDropdown && (
        <div className="position-absolute z-2" style={{zIndex: 2}}>
          <div className="card" style={{ width: "18em" }}>
            <div className="card-body p-4 position-relative">
              {title && (
                <h5 className="card-title mb-3">{title}</h5>
              )}
              <p
                className="p-3 position-absolute top-0 end-0 cursor-pointer"
                onClick={onFocusInput}
              >
                X
              </p>

              <div className="overflow-auto" style={{ maxHeight: "10em" }}>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center px-0 dropdown-item cursor-pointer"
                      onClick={() => onSelectData(item)}
                    >
                      {attributeImage && (
                        <img
                          src={item[attributeImage]}
                          alt=""
                          className="me-2"
                          style={{ height: sizeImg || "20px" }}
                        />
                      )}
                      <p>{item[attributeName]}</p>
                    </div>
                  ))
                ) : (
                  <div>Empty</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputAutocomplete;
