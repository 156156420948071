export const convertToRupiah = (number) => {
  const number_string = number.toString()
  const sisa = number_string.length % 3
  const ribuan = number_string.substr(sisa).match(/\d{3}/g)
  let result = number_string.substr(0, sisa)

  if (ribuan) {
    const separator = sisa ? '.' : ''
    result += separator + ribuan.join('.')
  }

  if (number < 0 && result[1] === '.') {
    result = '-' + result.split('').splice(2, result.length).join('')
    return result
  }

  return result
}
