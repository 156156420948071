import React, { useState, useEffect } from "react";
import Main from "../../layouts/Main";
import Card from "../../components/Card";
import Pagination from "../../components/Pagination";
import RefundCustomerService from "../../services/RefundCustomer";
import Confirm from "./Confirm";
import Edit from "./Edit";
import EditRekening from "./EditRekening";
import styles from "./RefundCustomer.module.css";
import { convertToRupiah } from "../../helper/RupiahDashboard";
import Button from "../../components/Button";
import EmptyData from "../../components/icons/EmptyData";
import EditIcon from "../../components/icons/EditIcon";

const RefundCustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isRefund, setIsRefund] = useState(null);
  const [dataRekening, setDataRekening] = useState(null);
  const [modalRekening, setModalRekening] = useState(false);
  const [typeEdit, setTypeEdit] = useState(null);

  useEffect(() => {
    getDataRefund();
  }, [isUpdated, pageNumber, limit]);

  const getDataRefund = async () => {
    await RefundCustomerService.listRefundCustomer(
      pageNumber,
      limit,
      search,
      isRefund
    )
      .then((res) => {
        setCustomers(res.data.data);
        setTotalPage(res.data.total_page);
        setIsUpdated(false);
      })
      .catch((err) => {
        console.log(err);
        setIsUpdated(false);
      });
  };

  const searchHandler = (event) => {
    const valueSearch = event.target.value;
    setSearch(valueSearch === "" ? null : valueSearch);
    setPageNumber(1);
    if (event.key === "Enter") {
      getDataRefund();
    }
  };

  const confirmHandler = (data, type) => {
    setTypeEdit(type);
    setData(data);
    setModal(true);
  };

  const editHandler = (data) => {
    setData(data);
    setModalEdit(true);
  };

  const closeEditHandler = () => {
    setModal(false);
    setModalEdit(false);
  };

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1);
    setIsUpdated(true);
  };

  const changeStatusRefund = (event) => {
    const value = event.target.value;
    setIsRefund(value === "" ? null : value);
    setPageNumber(1);
  };

  const clearSearch = () => {
    setSearch("");
    setPageNumber(1);
    setIsUpdated(true);
  };

  const changeRowPerPage = (event) => {
    setLimit(event.target.value);
    setPageNumber(1);
  };

  const showTooltip = (index) => {
    const tooltip = document.getElementById(`tooltip-custom-${index}`);
    tooltip.style.visibility = "visible";
  };

  const hideTooltip = (index) => {
    const tooltip = document.getElementById(`tooltip-custom-${index}`);
    tooltip.style.visibility = "hidden";
  };

  const editRekening = (data, type) => {
    setDataRekening(data);
    setTypeEdit(type);
    setModalRekening(true);
  };

  const closeEditRekening = () => {
    setModalRekening(false);
    setTypeEdit(null);
    setDataRekening(null);
  };

  return (
    <Main title="Customer Refund">
      {modal && (
        <Confirm
          data={data}
          type={typeEdit}
          setType={setTypeEdit}
          activeModal={modal}
          setIsUpdated={setIsUpdated}
          closeModal={closeEditHandler}
        />
      )}

      {modalEdit && (
        <Edit
          data={data}
          activeModal={modalEdit}
          setIsUpdated={setIsUpdated}
          closeModal={closeEditHandler}
        />
      )}

      {modalRekening && (
        <EditRekening
          data={dataRekening}
          type={typeEdit}
          activeModal={modalRekening}
          setIsUpdated={setIsUpdated}
          closeModal={closeEditRekening}
        />
      )}

      <Card col={"col-xl-12 col-lg-12"}>
        {/* Filter and Search */}
        <div className="row justify-content-between align-items-end mb-5">
          <div className="col-7">
            <p className="fw-bold">Status Bayar</p>
            <div>
              <select
                className={`${styles.selectStatus} form-select me-3`}
                onChange={changeStatusRefund}
                value={isRefund}
              >
                <option value="">Semua</option>
                <option value={0}>Belum Terbayar</option>
                <option value={1}>Terbayar</option>
              </select>
              <button className="btn btn-primary" onClick={getDataRefund}>
                Filter
              </button>
            </div>
          </div>
          <div className="col-5">
            <div className="form-group">
              <div className="input-group position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type keyword here.."
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  onChange={searchHandler}
                  onKeyDown={searchHandler}
                  value={search}
                />
                {search !== "" && search !== null && (
                  <button
                    type="button"
                    className={`btn btn-link text-decoration-none ${styles.clearSearch}`}
                    aria-label="Clear search"
                    onClick={clearSearch}
                  >
                    &#x2715;
                  </button>
                )}
                <button
                  className="btn btn-primary fa fa-search"
                  type="submit"
                  id="button-addon2"
                  onClick={getDataRefund}
                ></button>
              </div>
            </div>
          </div>
        </div>
        {/* Filter and Search */}

        {/* Table */}
        <div className="d-flex">
          <div className={styles.divTable}>
            <div className={styles.tableWrap}>
              <div className={styles.thead}>
                <div className={styles.trow}>
                  <div className={`text-center ${styles.tdata} ${styles.no}`}>
                    NO
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.projectId}`}
                  >
                    PROJECT ID
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.clientName}`}
                  >
                    CLIENT NAME
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.amount}`}
                  >
                    AMOUNT
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.feeAdmin}`}
                  >
                    FEE ADMIN
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.feeRefund}`}
                  >
                    FEE REFUND
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.percentage}`}
                  >
                    PERCENTAGE
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.totalRefund}`}
                  >
                    TOTAL REFUND
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.rekeningRefund}`}
                  >
                    REKENING REFUND
                  </div>
                  <div
                    className={`text-center ${styles.tdata} ${styles.action}`}
                  >
                    ACTION
                  </div>
                </div>
              </div>
              <div className={styles.tbody}>
                {customers.length > 0 ? (
                  customers.map((item, index) => (
                    <div
                      className={`${styles.trow} ${
                        item.is_refund === 1 && styles.bgRefund
                      }`}
                      key={index}
                    >
                      <div
                        className={`text-center ${styles.tdata} ${styles.no}`}
                      >
                        {limit * (pageNumber - 1) + index + 1}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.projectId}`}
                      >
                        {item.project_id}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.clientName}`}
                      >
                        {item.client_name}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.amount}`}
                      >
                        Rp. {convertToRupiah(item.amount)}
                      </div>
                      <div
                        className={`text-center text-danger ${styles.tdata} ${styles.feeAdmin}`}
                      >
                        Rp.{" "}
                        {convertToRupiah(
                          item.fee_admin == null ? 0 : item.fee_admin
                        )}
                      </div>
                      <div
                        className={`text-center text-danger ${styles.tdata} ${styles.feeRefund}`}
                      >
                        Rp. {convertToRupiah(item.fee)}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.percentage}`}
                      >
                        {item.percent}%
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${styles.totalRefund}`}
                      >
                        Rp. {convertToRupiah(item.total_refund)}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${
                          styles.rekeningRefund
                        } ${
                          item.is_refund === 1 && styles.bgRefund
                        } position-relative`}
                      >
                        {item.bank_name ? (
                          <div>
                            <div
                              className={`${styles.dataRekening} d-flex justify-content-start align-items-center`}
                            >
                              <div
                                onMouseEnter={() => showTooltip(index)}
                                onMouseLeave={() => hideTooltip(index)}
                              >
                                <p
                                  className={`${styles.textBank} m-0 text-nowrap`}
                                >
                                  {item.bank_name} {item.bank_name && "|"}{" "}
                                  {item.bank_account_name}
                                </p>
                                <p className="m-0 pb-1">
                                  <i>{item.bank_account_number}</i>
                                </p>
                              </div>
                              <EditIcon className="cursor-pointer" onClick={() => editRekening(item, 'edit')} />
                            </div>
                            <div
                              className={`${styles.tooltipCustom}`}
                              id={`tooltip-custom-${index}`}
                            >
                              <div className={`text-start ${styles.tooltipInner}`}>
                                <p className="m-0 text-nowrap">
                                  {item.bank_name} {item.bank_name && "|"}{" "}
                                  {item.bank_account_name}{" "}
                                </p>
                                <p className="m-0 pb-1">
                                  <i>{item.bank_account_number}</i>
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : item.is_refund !== 1 && (
                          <div className={`${styles.editButton}`} onClick={() => editRekening(item, 'add')}>
                            <p className="m-0 me-3 fw-bold">Tambah Rekening</p>
                            <EditIcon />
                          </div>
                        )}
                      </div>
                      <div
                        className={`text-center ${styles.tdata} ${
                          styles.action
                        } ${item.is_refund === 1 && styles.bgRefund}`}
                      >
                        {item.is_refund === 1 ? (
                          <p className={`m-0 ${styles.paidText}`}>Terbayar</p>
                        ) : (
                          <React.Fragment>
                            <Button
                              button="btn btn-sm me-3 btn-warning"
                              icon="fa fa-edit"
                              onClick={() => editHandler(item)}
                            />
                            <Button
                              button="btn-success btn-sm"
                              icon="fa fa-check"
                              disabled={item.bank_name === null}
                              onClick={() => confirmHandler(item, 'confirm')}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={`${styles.emptyData}`}>
                    <EmptyData className="ms-3" />
                    <p className="m-0 fw-bold">Tidak ada data ditemukan</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Table */}

        {/* Pagination */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <p className="m-0">Row Per Page</p>
            <select
              className={`${styles.selectRowPerPage} form-select me-3`}
              onChange={changeRowPerPage}
              value={limit}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
          </div>
          <Pagination
            pageCount={totalPage}
            changePage={changePageHandler}
            forcePage={pageNumber - 1}
          />
        </div>
        {/* Pagination */}
      </Card>
    </Main>
  );
};

export default RefundCustomerList;
