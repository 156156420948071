import moment from 'moment'
import Button from '../../../components/Button'
import { convertToRupiah } from '../../../helper/Rupiah'

const TableItem = (props) => {
  const master = props.master
  const data = props.data
  const paydate = moment(data.next_paydate).format('DD/MM/YYYY')
  let paymentDate = moment(data.paydate).format('DD/MM/YYYY')
  let amount, paymentStatus, paymentStatusClass, canEdit
  let duration = data.duration

  if (!data.paydate) {
    paymentDate = '-'
  }

  if (master.is_installment == 0) {
    amount = master.amount
    duration = `${master.days} Hari`
  } else {
    amount = master.amount_installment
  }

  if (data.paydate) {
    paymentStatus = 'Lunas'
    paymentStatusClass = 'fw-bold text-success'
    canEdit = false
  } else {
    paymentStatus = 'Belum Lunas'
    paymentStatusClass = 'fw-bold text-danger'
    canEdit = true
  }

  const editHandler = () => {
    props.editHandler(data.id)
  }

  return (
    <tr>
      <td>{props.no + 1}</td>
      <td>{paydate}</td>
      <td>{paymentDate}</td>
      <td>Rp. {convertToRupiah(amount)}</td>
      <td>{duration}</td>
      <td className={paymentStatusClass}>{paymentStatus}</td>
      <td>
        {!canEdit && data.note}
        {canEdit && (
          <Button
            icon="fa fa-pencil"
            button="btn-primary btn-sm"
            onClick={editHandler}
          />
        )}
      </td>
    </tr>
  )
}

export default TableItem
