import { convertToRupiah } from '../../../helper/Rupiah'

const VendorTableItem = (props) => {
  const data = props.data

  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.project_id}</td>
      <td>Vendor</td>
      <td>{data.project_name}</td>
      <td>{data.client_name}</td>
      <td>{data.tgl_deposit}</td>
      <td>{data.start_date}</td>
      <td>Rp. {convertToRupiah(data.deposit)}</td>
    </tr>
  )
}

export default VendorTableItem
