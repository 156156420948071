import { useState } from 'react'
import TabItem from './TabItem'

const Tab = (props) => {
  const data = props.data
  const [content, setContent] = useState(props?.defaultContent || null)

  const tabHandler = (e) => {
    const dataContent = data.find((d) => d.id === e.target.id)
    setContent(dataContent.content)
  }

  return (
    <div className="panel panel-danger">
      <div className="tab-menu-heading">
        <div className="tabs-menu">
          <ul className="nav panel-tabs panel-danger">
            {data.map((d) => (
              <TabItem
                key={d.id}
                id={d.id}
                name={d.name}
                defaultId={props.defaultId}
                tabHandler={tabHandler}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className="panel-body tabs-menu-body">
        <div className="tab-content">
          {content && <div className="tab-pane active">{content}</div>}
        </div>
      </div>
    </div>
  )
}

export default Tab
