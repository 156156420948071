const Toggle = (props) => {
  return (
    <div className="form-group">
      <label className="custom-switch form-switch me-5">
        <input
          type="checkbox"
          name="custom-switch-radio1"
          className="custom-switch-input"
          defaultChecked={props.checked}
          onClick={props.onClick}
        />
        <span className="custom-switch-indicator custom-switch-indicator-md" />
        <span className="custom-switch-description">{props.title}</span>
      </label>
    </div>
  )
}

export default Toggle
