import { useEffect, useState } from 'react'
import Main from '../../layouts/Main'
import DashboardService from '../../services/Dashboard'
import CardDashboard from '../../components/CardDashboard'
import { convertToRupiah } from '../../helper/RupiahDashboard'

const Dashboard = () => {
  const [balance, setBalance] = useState(0)
  const [mitra10, setMitra10] = useState(0)
  const [project, setProject] = useState(0)

  useEffect(() => {
    DashboardService.balance()
      .then((res) => {
        setBalance(res.data.data.total)
      })
      .catch((err) => {
        console.log(err)
      })

    DashboardService.totalProjects()
      .then((res) => {
        setMitra10(res.data.data[0].total)
      })
      .catch((err) => {
        console.log(err)
      })

    DashboardService.totalProjectGeneral()
      .then((res) => {
        setProject(res.data.data.total)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const card = [
    {
      label: 'Saldo Mitra10',
      value: `Rp. ${convertToRupiah(balance)}`,
    },
    {
      label: 'Transaksi Mitra10 Hari Ini',
      value: mitra10,
    },
    {
      label: 'Transaksi Project Hari Ini',
      value: project,
    },
  ]

  return (
    <Main title="Dashboard">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
          <div className="row">
            {card.map((d, i) => (
              <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-xl-4">
                <CardDashboard data={d} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Main>
  )
}

export default Dashboard
