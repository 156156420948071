import { useState } from 'react'
import Modal from '../../components/Modal'
import InputForm from '../../components/InputForm'

const MasterBpjsEdit = (props) => {
  const data = props.data

  const [amount, setAmount] = useState(data.price)
  const submitHandler = () => {
    props.onConfirm({ id: data.id, amount })
  }

  const forms = [
    {
      margin: 'mb-4',
      label: 'Nominal',
      type: 'number',
      id: 'price',
      placeholder: data.price,
      changeHandler: setAmount,
    },
  ]

  return (
    <Modal
      activeModal={props.activeModal}
      title="Biaya Bulanan BPJS"
      closeClick={props.closeClick}
      submitHandler={submitHandler}
      type="Masukkan"
    >
      {forms.map((form) => (
        <InputForm key={form.id} {...form} />
      ))}
    </Modal>
  )
}

export default MasterBpjsEdit
