import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import InputGroup from '../../components/InputGroup'
import Pagination from '../../components/Pagination'
import PriceItem from './PriceItem'
import PriceSkillService from '../../services/PriceSkill'
import EditForm from './EditForm'
import { Toast } from '../../config/Toast'
import 'react-toastify/dist/ReactToastify.css'

const PriceSkill = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [modal, setModal] = useState(false)
  const [dataEdit, setDataEdit] = useState(null)
  const [search, setSearch] = useState(null)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  // Paginate
  const usersPerPage = 5
  const pagesVisited = pageNumber * usersPerPage
  const pageCount = Math.ceil(data.length / usersPerPage)

  useEffect(() => {
    setLoading(true)

    PriceSkillService.getSkills(search)
      .then((res) => {
        setData(res.data.data)
        setLoading(false)
        setIsUpdated(false)
      })
      .catch((err) => {
        console.log(err.response.data.message)
        notifyFailed(err.response.data.message)
        setIsUpdated(false)
      })
  }, [isUpdated])

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected)
  }

  const closeEditHandler = () => {
    setDataEdit(null)
    setModal(false)
  }

  const updateHandler = (id, data) => {
    PriceSkillService.update(id, data)
      .then(() => {
        setModal(false)
        setIsUpdated(true)
        notifySuccess(`Success updated price skill`)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
      })
  }

  const searchHandler = (value) => {
    setSearch(value)
    setIsUpdated(true)
  }

  return (
    <Main title="Harga Keahlian">
      {modal && (
        <EditForm
          data={dataEdit}
          activeModal={modal}
          closeClick={closeEditHandler}
          onUpdate={updateHandler}
        />
      )}

      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row justify-content-end">
          <div className="col-5">
            <InputGroup
              button="btn-dark"
              icon="fa fa-search"
              searchHandler={searchHandler}
            />
          </div>
        </div>
        <div className="table-responsive mb-5">
          <table className="table text-nowrap text-md-nowrap mb-0 text-center">
            <thead>
              <tr>
                <th>No</th>
                <th>Icon</th>
                <th>Skill Name</th>
                <th>Fullday</th>
                <th>Morning</th>
                <th>Evening</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice(pagesVisited, pagesVisited + usersPerPage)
                .map((d, index) => (
                  <PriceItem
                    key={d.id}
                    no={
                      index +
                      1 +
                      (pageNumber == 0 ? 0 : usersPerPage + pagesVisited)
                    }
                    data={d}
                    setModal={setModal}
                    setDataEdit={setDataEdit}
                  />
                ))}
            </tbody>
          </table>

          <Pagination pageCount={pageCount} changePage={changePageHandler} />
        </div>
      </Card>
    </Main>
  )
}

export default PriceSkill
