import { convertToRupiah } from '../../../../helper/Rupiah'

const DisbursmentWorkerItem = (props) => {
  const data = props.data

  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.worker_name}</td>
      <td>Rp{convertToRupiah(data.gross_amount)}</td>
      <td>Rp{convertToRupiah(data.point)}</td>
      <td>Rp{convertToRupiah(data.bonus)}</td>
      <td>Rp{convertToRupiah(data?.cut_bpjs || 0)}</td>
      <td>Rp{convertToRupiah(data?.topup_deposit || 0)}</td>
      <td>Rp{convertToRupiah(data?.cut_kasbon || 0)}</td>
      <td>Rp{convertToRupiah(data.net_amount)}</td>
      <td>{data.bank_code}</td>
      <td>{data.bank_account_name}</td>
      <td>{data.bank_account}</td>
      <td>{data.email}</td>
    </tr>
  )
}

export default DisbursmentWorkerItem
