import CompleteItem from './CompleteItem'
import { Fragment } from 'react'

const Complete = (props) => {
  const data = props.data

  return (
    <Fragment>
      {data.map((d, index) => (
        <CompleteItem key={index} data={d} imageHandler={props.imageHandler} />
      ))}
    </Fragment>
  )
}

export default Complete
