import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import { Toast } from "../../config/Toast";
import "react-toastify/dist/ReactToastify.css";
import styles from "./RefundCustomer.module.css";
import RefundCustomerService from "../../services/RefundCustomer";

const EditRekening = (props) => {
  const data = props.data;
  const activeModal = props.activeModal ? "effect-scale show" : "";
  const activeStyle = activeModal
    ? { display: "block", paddingright: "17px" }
    : "";

  const [namaBank, setNamaBank] = useState("");
  const [pemilikRekening, setPemilikRekening] = useState("");
  const [noRekening, setNoRekening] = useState("");

  const notifySuccess = (message) => toast.success(message, Toast);
  const notifyFailed = (message) => toast.error(message, Toast);

  useEffect(() => {
    if (data) {
      setNamaBank(data.bank_name);
      setPemilikRekening(data.bank_account_name);
      setNoRekening(data.bank_account_number);
    }
  }, []);

  const submitHandler = () => {
    const payload = {
      "bank_name": namaBank,
      "bank_account_name": pemilikRekening,
      "bank_account_number": noRekening
    }

    RefundCustomerService.editRekening(data.id, payload)
      .then(() => {
        notifySuccess("Berhasil ubah detail rekening");
        props.setIsUpdated(true);
        props.closeModal();
      })
      .catch((err) => {
        console.log(err);
        notifyFailed("Terjadi kesalahan, silahkan coba lagi");
      });
  };

  const convertTitle = () => {
    if (props.type === "add") {
      return "Tambah No. Rekening";
    } else if (props.type === "edit") {
      return "Update No. Rekening";
    }
  };

  const disableSubmit = () => {
    if (
      namaBank === "" ||
      namaBank === null ||
      pemilikRekening === "" ||
      pemilikRekening === null ||
      noRekening === "" ||
      noRekening === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      modalType="modal-md"
      title={convertTitle()}
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Simpan"
      buttonDisable={disableSubmit()}
    >
      <div className="text-start px-5">
        <p className="text-gray">
          Kamu dapat merubah atau menambah data jika terdapat <br /> kesalahan
          input
        </p>
        <div className="input-group">
          <span
            className={`${styles.inputGroupText} input-group-text bg-light text-black`}
            style={{ width: "160px" }}
          >
            NAMA BANK <span className="text-danger">*</span>
          </span>
          <input
            type="text"
            className={`${styles.disableInput} form-control fw-bold text-black`}
            value={namaBank}
            onChange={(e) => setNamaBank(e.target.value)}
          />
        </div>
        <div className="input-group">
          <span
            className={`${styles.inputGroupText} input-group-text bg-light text-black`}
            style={{ width: "160px" }}
          >
            PEMILIK REKENING <span className="text-danger">*</span>
          </span>
          <input
            type="text"
            className={`${styles.disableInput} form-control fw-bold text-black`}
            value={pemilikRekening}
            onChange={(e) => setPemilikRekening(e.target.value)}
          />
        </div>
        <div className="input-group">
          <span
            className={`${styles.inputGroupText} input-group-text bg-light text-black`}
            style={{ width: "160px" }}
          >
            NO. REKENING <span className="text-danger">*</span>
          </span>
          <input
            type="text"
            className={`${styles.disableInput} form-control fw-bold text-black`}
            value={noRekening}
            onChange={(e) => setNoRekening(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditRekening;
