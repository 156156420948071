import { useState } from 'react'
import { toast } from 'react-toastify'
import FileSaver from 'file-saver'
import Button from '../../../components/Button'
import { Toast } from '../../../config/Toast'
import Report from '../../../services/Report'
import { convertToRupiah } from '../../../helper/Rupiah'

const DepositConsumerItem = (props) => {
  const [btnLoading, setBtnLoading] = useState(false)

  const data = props.data
  const voucherClass = data.is_voucher === 1 ? 'text-danger' : ''

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const invoiceHandler = () => {
    setBtnLoading(true)

    Report.consumerInvoice(data.project_id)
      .then((res) => {
        const url = `${process.env.REACT_APP_API_DOMAIN}/${res.data.path}`
        FileSaver.saveAs(url, res.data.path)
        setBtnLoading(false)
        notifySuccess(`Success generate invoice`)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Failed generate invoice')
        setBtnLoading(false)
      })
  }

  return (
    <tr className={voucherClass}>
      <td>
        <Button
          button="btn-danger btn-sm"
          icon="fa fa-file-pdf-o"
          loading={btnLoading}
          onClick={invoiceHandler}
        />
      </td>
      <td>{props.no}</td>
      <td>{data.project_id}</td>
      <td>Consumer</td>
      <td>{data.client_name}</td>
      <td>{data.tgl_deposit}</td>
      <td>{data.start_date}</td>
      <td>Rp. {convertToRupiah(data.deposit)}</td>
      <td>{data.payment_method}</td>
      <td>{data.payment_channel}</td>
      <td>Rp. {convertToRupiah(data.fee_transfer)}</td>
    </tr>
  )
}

export default DepositConsumerItem
