const ButtonGroup = (props) => {
  const iconOne = props.iconOne ? props.iconOne : false
  const iconTwo = props.iconTwo ? props.iconOne : false
  const loadingOne = props.loadingOne ? props.loadingOne : false
  const loadingTwo = props.loadingTwo ? props.loadingTwo : false

  return (
    <div className="wd-200 mg-b-30">
      <div className="btn-group" role="group" aria-label="Basic example">
        {!loadingOne && (
          <button
            type="button"
            className={`btn ${props.buttonOne}`}
            onClick={props.onClickOne}
          >
            {iconOne && <i className={props.iconOne}></i>}
            {props.titleOne}
          </button>
        )}
        {loadingOne && (
          <button type="button" className={`btn ${props.buttonOne}`} disabled>
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            {props.titleOne}
          </button>
        )}

        {!loadingTwo && (
          <button
            type="button"
            className={`btn ${props.buttonTwo}`}
            onClick={props.onClickTwo}
          >
            {iconTwo && <i className={props.iconTwo}></i>}
            {props.titleTwo}
          </button>
        )}
        {loadingTwo && (
          <button type="button" className={`btn ${props.buttonTwo}`} disabled>
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            {props.titleTwo}
          </button>
        )}
      </div>
    </div>
  )
}

export default ButtonGroup
