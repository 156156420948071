import { useState, Fragment } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import Button from '../../../components/Button'
import Badge from '../../../components/Badge'
import BpjsService from '../../../services/Bpjs'
import { Toast } from '../../../config/Toast'
import { convertToRupiah } from '../../../helper/Rupiah'

const RekapItem = (props) => {
  const data = props.data
  const oldAmount = +data.gross_amount + +data.point + +data.bonus
  const note = data.bpjs?.note || '-'
  const isPosting = data.bpjs_check == 1 ? true : false
  const canSwap = data.bpjs.can_swap_deposit && isPosting == false
  let deductionAmount = data.bpjs.deduction

  const [bpjsUnpaid, setBpjsUnpaid] = useState(
    data.bpjs.total_bpjs_unpaid > 0
      ? `${data.bpjs.total_bpjs_unpaid} month(s)`
      : '-'
  )
  const [masterBpjs, setMasterBpjs] = useState(props.masterBpjs)

  // if (data.is_topup == 1) {
  //   deductionAmount += masterBpjs
  // }

  const [deposit, setDeposit] = useState(data.deposit_balance)
  const [bookmark, setBookmark] = useState(data.bpjs_bookmark)
  const [topup, setTopup] = useState(data.is_topup)
  const [initialAmount, setInitalAmount] = useState(oldAmount)
  const [deduction, setDeduction] = useState(deductionAmount)
  const [finalAmount, setFinalAmount] = useState(data.net_amount)
  const notifyFailed = (message) => toast.error(message, Toast)

  const topupHandler = () => {
    BpjsService.topup(data.id)
      .then(() => {
        if (topup == 0) {
          setTopup(1)
          setDeposit((data?.deposit_balance || 0) + masterBpjs * 3)
          setDeduction(+data.bpjs.deduction + +(masterBpjs * 3))
          setFinalAmount(+data.net_amount - masterBpjs * 3)
        } else {
          setTopup(0)
          setDeposit(data?.deposit_balance || 0)
          setDeduction(data.bpjs.deduction)
          setFinalAmount(+data.net_amount)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const bookmarkHandler = () => {
    BpjsService.bookmark(data.id)
      .then(() => {
        bookmark == 0 ? setBookmark(1) : setBookmark(0)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const detailHandler = () => {
    const curYear = moment().format('YYYY')

    BpjsService.history(data.id, curYear)
      .then((res) => {
        props.detailHandler(data.id, data.worker_name, res.data.data)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Something went wrong!')
      })
  }

  return (
    <tr className={isPosting ? 'text-primary' : ''}>
      <td>{props.no}</td>
      <td>{data.worker_name}</td>
      <td>
        <div className="row">
          <div className="col-2 text-start">
            Rp. {convertToRupiah(parseInt(deposit))}
          </div>
          <div className="col text-end">
            <Button
              button={`${
                topup === 0 ? 'btn-outline-warning' : 'btn-warning'
              } btn-sm`}
              icon={`fa fa-exchange ${
                topup === 0
                  ? canSwap
                    ? 'text-warning'
                    : 'text-light'
                  : 'text-light'
              }`}
              disabled={canSwap ? false : true}
              onClick={topupHandler}
            />
          </div>
        </div>
      </td>
      <td>Rp. {convertToRupiah(parseInt(initialAmount))}</td>
      <td>
        {!isPosting && (
          <Fragment>
            <Button
              button={`${
                bookmark === 0 ? 'btn-outline-success' : 'btn-success'
              } btn-sm me-1`}
              icon={`fa fa-bookmark ${
                bookmark === 0 ? 'text-success' : 'text-light'
              }`}
              onClick={bookmarkHandler}
            />
            <Button
              button="btn-outline-warning btn-sm"
              icon="fa fa-wrench text-warning"
              onClick={detailHandler}
            />
          </Fragment>
        )}

        {isPosting && (
          <Badge badge="bg-primary pt-1" name="posted" type="rounded-pill" />
        )}
      </td>
      <td>{bpjsUnpaid}</td>
      <td className="text-danger">-Rp. {convertToRupiah(deduction)}</td>
      <td>{note}</td>
      <td className="text-success">Rp. {convertToRupiah(finalAmount)}</td>
    </tr>
  )
}

export default RekapItem
