import Modal from '../../../components/Modal'
import 'react-toastify/dist/ReactToastify.css'

const Posting = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const submitHandler = () => {
    props.onConfirm()
    props.closeModal()
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Posting Kasbon"
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Ya, Posting"
    >
      <h5 className="mt">Apakah anda yakin untuk memposting Kasbon ?</h5>
      <h5>Proses ini merupakan tahap terakhir dari pemotongan upah</h5>
      <h5 className="mt-4 fw-bold">
        Data yang telah di posting tidak dapat dikembalikan
      </h5>
    </Modal>
  )
}

export default Posting
