import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import FileSaver from 'file-saver'
import Main from '../../../layouts/Main'
import Card from '../../../components/Card'
import InputGroup from '../../../components/InputGroup'
import ButtonGroup from '../../../components/ButtonGroup'
import InputGroupDate from '../../../components/InputGroupDate'
import Pagination from '../../../components/Pagination'
import BonusPointItem from './BonusPointItem'
import Report from '../../../services/Report'
import { Toast } from '../../../config/Toast'

const BonusPoint = () => {
  const [data, setData] = useState([])
  const [update, setUpdate] = useState(false)
  const [btnFilterLoading, setBtnFilterLoading] = useState(false)
  const [btnExcelLoading, setBtnExcelLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [totalPage, setTotalPage] = useState(0)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)
  const limit = 5

  useEffect(() => {
    if (startDate && endDate) {
      Report.bonusPoint(pageNumber, limit, search, startDate, endDate)
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setBtnFilterLoading(false)
          setUpdate(false)
        })
        .catch((err) => {
          setBtnFilterLoading(false)
          console.log(err)
          setUpdate(false)
        })
    }
  }, [update])

  const filterHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnFilterLoading(true)
    setUpdate(true)
  }

  const excelHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnExcelLoading(true)

    Report.bonusPointExcel(startDate, endDate)
      .then((res) => {
        const url = `${process.env.REACT_APP_API_DOMAIN}/${res.data.path}`
        FileSaver.saveAs(url, res.data.path)

        notifySuccess('Success download report bonus point')
        setBtnExcelLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setBtnExcelLoading(false)
        notifyFailed('Something went wrong!')
      })
  }

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setUpdate(true)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setUpdate(true)
  }

  return (
    <Main title="Report Bonus Point">
      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row">
          <div className="col-3">
            <InputGroupDate
              type="startDate"
              label="Start Date"
              setStartDate={setStartDate}
            />
          </div>
          <div className="col-3">
            <InputGroupDate
              type="endDate"
              label="End Date"
              setEndDate={setEndDate}
            />
          </div>
          <div className="col">
            <div className="row mt-5">
              <div className="col mt-4">
                <ButtonGroup
                  buttonOne="btn-primary"
                  titleOne="Filter"
                  iconOne="fa fa-filter me-1"
                  onClickOne={filterHandler}
                  loadingOne={btnFilterLoading}
                  buttonTwo="btn-success"
                  titleTwo="Excel"
                  iconTwo="fa fa-file-excel-o me-1"
                  onClickTwo={excelHandler}
                  loadingTwo={btnExcelLoading}
                />
              </div>
              <div className="col-lg-7 mt-4">
                <InputGroup
                  button="btn-dark"
                  icon="fa fa-search"
                  searchHandler={searchHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered table-hover text-nowrap text-md-nowrap mb-0 text-center">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Worker ID</th>
                    <th>Worker Name</th>
                    <th>Point</th>
                    <th>Bonus</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, index) => (
                    <BonusPointItem
                      key={d.worker_id}
                      no={
                        index +
                        (pageNumber == 1 ? 1 : limit * pageNumber - limit + 1)
                      }
                      data={d}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            {totalPage > 0 && (
              <Pagination
                pageCount={totalPage}
                changePage={changePageHandler}
              />
            )}
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default BonusPoint
