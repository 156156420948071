import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class Worker {
  list(page, limit, search) {
    const endPoint = `/workers?page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  topup(id, payload) {
    const endPoint = `/workers/${id}/topup`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, payload, headersTokenConfig)
  }
}

export default new Worker()
