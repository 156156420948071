import { convertToRupiah } from '../../../../helper/Rupiah'

const SalaryCalculationItem = (props) => {
  const data = props.data
  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.attendance_date ? data.attendance_date : '-'}</td>
      <td>{data.source_project}</td>
      <td>{data.project_id}</td>
      <td>{data.client_name}</td>
      <td>{data.worker_name ? data.worker_name : '-'}</td>
      <td>{data.user_id === 0 ? '-' : data.user_id}</td>
      <td>{data.skill_name ? data.skill_name : '-'}</td>
      <td>Rp. {convertToRupiah(data.order)}</td>
      <td>Rp. {convertToRupiah(data.order_sukses)}</td>
      <td>
        {data.worker_order}/{data.worker_onsite}
      </td>
      <td>{data.sesi}</td>
      <td>Rp. {convertToRupiah(data.upah_harian)}</td>
      <td>Rp. {convertToRupiah(data.upah_add)}</td>
      <td>Rp. {convertToRupiah(data.upah_transport)}</td>
      <td>Rp. {convertToRupiah(data.upah_add_keramik)}</td>
      <td>Rp. {convertToRupiah(data.upah_add_las ? data.upah_add_las : 0)}</td>
      <td>
        Rp.{' '}
        {convertToRupiah(data.upah_fee_building ? data.upah_fee_building : 0)}
      </td>
      <td>
        Rp. {convertToRupiah(data.potongan_keluhan ? data.potongan_keluhan : 0)}
      </td>
      <td>Rp. {convertToRupiah(data.total_upah)}</td>
    </tr>
  )
}

export default SalaryCalculationItem
