import { useEffect, useState } from 'react'
import FileSaver from 'file-saver'
import { toast } from 'react-toastify'
import Main from '../../../layouts/Main'
import Card from '../../../components/Card'
import ButtonGroup from '../../../components/ButtonGroup'
import InputGroup from '../../../components/InputGroup'
import InputGroupDate from '../../../components/InputGroupDate'
import Pagination from '../../../components/Pagination'
import VendorCommissionItem from './VendorCommissionItem'
import Report from '../../../services/Report'
import { Toast } from '../../../config/Toast'

const VendorComission = () => {
  const [data, setData] = useState([])
  const [update, setUpdate] = useState(false)
  const [btnFilterLoading, setBtnFilterLoading] = useState(false)
  const [btnExcelLoading, setBtnExcelLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [search, setSearch] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  // Paginate
  const usersPerPage = 5
  const pagesVisited = pageNumber * usersPerPage
  const pageCount = Math.ceil(data.length / usersPerPage)

  useEffect(() => {
    if (startDate && endDate) {
      Report.komisiVendors(startDate, endDate, search)
        .then((res) => {
          setData(res.data.data)
        })
        .catch((err) => {
          console.log(err)
        })

      setUpdate(false)
      setBtnFilterLoading(false)
    }
  }, [update])

  const filterHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnFilterLoading(true)
    setUpdate(true)
  }

  const excelHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnExcelLoading(true)

    Report.komisiVendorsExcel(startDate, endDate)
      .then((res) => {
        const url = `${process.env.REACT_APP_API_DOMAIN}/${res.data.path}`
        FileSaver.saveAs(url, res.data.path)

        notifySuccess('Success download komisi vendor')
        setBtnExcelLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setBtnExcelLoading(false)
        notifyFailed('Something went wrong!')
      })
  }

  const searchHandler = (value) => {
    setSearch(value)
    setUpdate(true)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <Main title="Report Komisi Vendor">
      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row">
          <div className="col-3">
            <InputGroupDate
              type="startDate"
              label="Start Date"
              setStartDate={setStartDate}
            />
          </div>
          <div className="col-3">
            <InputGroupDate
              type="endDate"
              label="End Date"
              setEndDate={setEndDate}
            />
          </div>
          <div className="col">
            <div className="row mt-5">
              <div className="col mt-4">
                <ButtonGroup
                  buttonOne="btn-primary"
                  titleOne="Filter"
                  iconOne="fa fa-filter me-1"
                  onClickOne={filterHandler}
                  loadingOne={btnFilterLoading}
                  buttonTwo="btn-success"
                  titleTwo="Excel"
                  iconTwo="fa fa-file-excel-o me-1"
                  onClickTwo={excelHandler}
                  loadingTwo={btnExcelLoading}
                />
              </div>
              <div className="col-lg-7 mt-4">
                <InputGroup
                  button="btn-dark"
                  icon="fa fa-search"
                  searchHandler={searchHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered table-hover text-nowrap text-md-nowrap mb-0 text-center">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Vendor Name</th>
                    <th>PIC</th>
                    <th>Phone</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .slice(pagesVisited, pagesVisited + usersPerPage)
                    .map((d, index) => (
                      <VendorCommissionItem
                        key={d.id}
                        no={
                          index +
                          1 +
                          (pageNumber == 0 ? 0 : usersPerPage + pagesVisited)
                        }
                        data={d}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            {data.length > 0 && (
              <Pagination
                pageCount={pageCount}
                changePage={changePageHandler}
              />
            )}
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default VendorComission
