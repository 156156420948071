import axios from 'axios'
import { headersConfig } from '../config/Header'

class AuthService {
  login(email, password) {
    const url = process.env.REACT_APP_API_URL_V1 + '/auth/login'
    return axios.post(url, { email, password }, headersConfig)
  }
}

export default new AuthService()
