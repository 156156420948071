import Button from '../../components/Button'
import { convertToRupiah } from '../../helper/RupiahDashboard'

const WorkerItem = (props) => {
  const data = props.data
  let deposit = '-'

  if (data.amount) {
    deposit = `Rp. ${convertToRupiah(data.amount)}`
  }

  const editHandler = () => {
    props.setModal(true)
    props.setData(props)
  }

  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.name}</td>
      <td>{data.bank_code}</td>
      <td>{data.bank_account_name}</td>
      <td>{data.bank_account}</td>
      <td>{data.email}</td>
      <td>{deposit}</td>
      <td>
        <Button
          icon="fa fa-wrench"
          button="btn-outline-warning btn-sm"
          onClick={editHandler}
          // disabled={data.is_inject_first_time == 1 ? true : false}
        />
      </td>
    </tr>
  )
}

export default WorkerItem
