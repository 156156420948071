import Button from '../../components/Button'
import BonusPointService from '../../services/BonusPoint'

const BonusPointItem = (props) => {
  const detailHandler = () => {
    BonusPointService.detail(props.worker_id)
      .then((res) => {
        const data = {
          ...res.data.data,
          worker_name: props.name,
        }
        props.setDataDetail(data)
        props.setModal(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <tr>
      <td className="text-center">{props.no}</td>
      <td className="text-center">{props.worker_id}</td>
      <td className="text-center">{props.name}</td>
      <td className="text-center">
        <Button
          icon="fa fa-eye me-1"
          title="Detail"
          button="btn-primary btn-sm"
          onClick={detailHandler}
        />
      </td>
    </tr>
  )
}

export default BonusPointItem
