import { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import ButtonGroup from '../../../components/ButtonGroup'
import InputGroup from '../../../components/InputGroup'
import InputGroupDate from '../../../components/InputGroupDate'
import Pagination from '../../../components/Pagination'
import BpjsService from '../../../services/Bpjs'
import MasterBpjsService from '../../../services/MasterBpjs'
import { Toast } from '../../../config/Toast'
import BpjsItem from './BpjsItem'
import Posting from './Posting'
import Detail from './Detail'
import Edit from './Edit'
import Submit from './Submit'
import 'react-loading-skeleton/dist/skeleton.css'

const BpjsList = () => {
  const [data, setData] = useState([])
  const [dataId, setDataId] = useState(null)
  const [dataDetail, setDataDetail] = useState(null)
  const [dataEdit, setDataEdit] = useState(null)
  const [dataFinal, setDataFinal] = useState([])
  const [masterBpjs, setMasterBpjs] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loadingFilter, setLoadingFilter] = useState(false)
  const [loadingPost, setLoadingPost] = useState(false)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalSubmit, setModalSubmit] = useState(false)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)
  const limit = 5

  useEffect(() => {
    MasterBpjsService.list()
      .then((res) => {
        setMasterBpjs(res.data.data.amount)
      })
      .catch((err) => {
        console.log(err)
        setUpdate(false)
      })

    if (startDate && endDate) {
      setLoading(true)

      BpjsService.list(pageNumber, limit, search, startDate, endDate)
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setUpdate(false)
        })
        .catch((err) => {
          console.log(err)
          setUpdate(false)
        })

      setTimeout(() => {
        setLoading(false)
      }, 1200)
    }
  }, [update])

  const filterHandler = () => {
    if (startDate && endDate) {
      setUpdate(true)
      setLoadingFilter(true)

      BpjsService.list(pageNumber, limit, search, startDate, endDate)
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setLoadingFilter(false)
          setUpdate(false)
        })
        .catch((err) => {
          console.log(err)
          setLoadingFilter(false)
          setUpdate(false)
        })
    } else {
      notifyFailed('Periode rentang waktu tidak boleh kosong')
    }
  }

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setUpdate(true)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setUpdate(true)
  }

  const postHandler = () => {
    if (!startDate || !endDate) {
      notifyFailed('Periode rentang waktu tidak boleh kosong')
      return
    }

    setModal(true)
  }

  const detailHandler = (id, workerName, data) => {
    data.information.id = id
    data.information.worker_name = workerName
    setDataId(id)
    setDataDetail(data)
    setModalDetail(true)
  }

  const editHandler = (value) => {
    value.all_data = dataDetail.list
    setDataEdit(value)
    setModalEdit(true)
  }

  const confirmHandler = (value) => {
    setDataFinal(value)
    setModalEdit(false)
    setModalSubmit(false)
  }

  const submitBpjsHandler = () => {
    setModalSubmit(true)
  }

  const saveBpjsHandler = () => {
    for (let i = 0; i < dataFinal.length; i++) {
      delete dataFinal[i].all_data
      if (
        dataFinal[i].month == moment().format('M') &&
        dataFinal[i].payment_status == 'paid'
      ) {
        dataFinal[i].worker_salary_report_id = dataId
      }
    }
    BpjsService.update(dataId, dataFinal)
      .then(() => {
        setModalSubmit(false)
        setModalDetail(false)
        setUpdate(true)
        notifySuccess('Berhasil melakukan pembayaran BPJS')
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Gagal melakukan pembayaran BPJS')
      })
  }

  const postingHandler = () => {
    setLoadingPost(true)

    const payload = {
      start: startDate,
      end: endDate,
    }

    BpjsService.post(payload)
      .then(() => {
        notifySuccess('Barhasil posting data BPJS tukang')
        setUpdate(true)
        setLoadingPost(false)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Gagal posting data BPJS tukang')
      })
  }

  const closeModalHandler = () => {
    if (modalEdit) {
      setModalSubmit(false)
      setModalEdit(false)
    } else if (modalSubmit) {
      setModalSubmit(false)
    } else {
      setModal(false)
      setModalDetail(false)
      setModalSubmit(false)
    }
  }

  const tHead = [
    'No',
    'Worker Name',
    'Deposit Balance',
    'Initial Amount',
    'Action',
    'Total BPJS Unpaid',
    'Deduction',
    'Note',
    'Final Amount',
  ]

  return (
    <Fragment>
      {modal && (
        <Posting
          activeModal={modal}
          closeModal={closeModalHandler}
          onConfirm={postingHandler}
        />
      )}

      {modalDetail && (
        <Detail
          activeModal={modalDetail}
          closeModal={closeModalHandler}
          data={dataDetail}
          editHandler={editHandler}
          setModalSubmit={setModalSubmit}
          submitBpjsHandler={submitBpjsHandler}
        />
      )}

      {modalEdit && (
        <Edit
          activeModal={modalEdit}
          closeModal={closeModalHandler}
          data={dataEdit}
          dataDetail={dataDetail}
          masterBpjs={masterBpjs}
          confirmHandler={confirmHandler}
        />
      )}

      {modalSubmit && !modalEdit && (
        <Submit
          activeModal={modalSubmit}
          closeModal={closeModalHandler}
          saveBpjsHandler={saveBpjsHandler}
        />
      )}

      <div className="row">
        <div className="col-3">
          <InputGroupDate
            type="startDate"
            label="Start Date"
            setStartDate={setStartDate}
          />
        </div>
        <div className="col-3">
          <InputGroupDate
            type="endDate"
            label="End Date"
            setEndDate={setEndDate}
          />
        </div>
        <div className="col">
          <div className="row mt-5">
            <div className="col mt-4">
              <ButtonGroup
                buttonOne="btn-primary"
                titleOne="Filter"
                iconOne="fa fa-filter me-1"
                onClickOne={filterHandler}
                loadingOne={loadingFilter}
                buttonTwo="btn-orange"
                titleTwo="Post to Kasbon"
                iconTwo="ti-angle-double-right me-1"
                onClickTwo={postHandler}
                loadingTwo={loadingPost}
              />
            </div>
            <div className="col mt-4">
              <InputGroup
                button="btn-primary"
                icon="fa fa-search"
                searchHandler={searchHandler}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover text-nowrap text-md-nowrap mb-0 text-center">
              <thead>
                <tr>
                  {tHead.map((d) => (
                    <th>{d}</th>
                  ))}
                </tr>
              </thead>
              {loading &&
                tHead.map(() => (
                  <td>
                    <Skeleton
                      count={5}
                      variant="rectangular"
                      height={33}
                      style={{
                        marginBottom: '0.5rem',
                      }}
                    />
                  </td>
                ))}
              {!loading && (
                <tbody>
                  {data.map((d, index) => (
                    <BpjsItem
                      key={d.id}
                      no={
                        index +
                        (pageNumber == 1 ? 1 : limit * pageNumber - limit + 1)
                      }
                      data={d}
                      masterBpjs={masterBpjs}
                      setUpdate={setUpdate}
                      detailHandler={detailHandler}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          {totalPage > 0 && (
            <Pagination pageCount={totalPage} changePage={changePageHandler} />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default BpjsList
