import { useState } from 'react'
import Modal from '../../components/Modal'
import ImageUploading from 'react-images-uploading'

const Upload = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const [images, setImages] = useState([])
  const [padding, setPadding] = useState('py-9')
  const maxNumber = 69

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
    setPadding('')
  }

  const submitHandler = () => {
    props.uploadHandler(images)
    // props.closeModal()
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title=""
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Simpan Gambar"
      modalHeader="false"
    >
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className={`upload__image-wrapper ${padding}`}>
            {padding && (
              <button
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
                className="btn btn-dark"
              >
                Click or Drop here
              </button>
            )}
            {imageList.map((image, index) => (
              <div key={index} className="image-item py-2">
                <img src={image['data_url']} alt="" width="100%" />
                <div className="image-item__btn-wrapper mt-3">
                  <button
                    onClick={() => onImageUpdate(index)}
                    className="btn btn-warning btn-sm"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => {
                      onImageRemove(index)
                      setPadding('py-9')
                    }}
                    className="btn btn-danger btn-sm ms-2"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </Modal>
  )
}

export default Upload
