import Badge from '../../../components/Badge'
import Button from '../../../components/Button'
import CompleteTableItem from './CompleteTableItem'

const CompleteItem = (props) => {
  const data = props.data
  const imageExists = data.image_evidance ? true : false

  const tHead = [
    'No',
    'Due Date',
    'Payment Date',
    'Amount',
    'Duration',
    'Payment Status',
    'Note',
  ]

  const imageHandler = () => {
    props.imageHandler(data.image_evidance)
  }

  return (
    <div className="card p-4 border">
      <div className="card bg-light p-3">
        <div className="row">
          <div className="col text-start">
            <span>PIC</span>
            <p className="fw-bold">{data.pic_name}</p>
          </div>
          <div className="col text-start">
            <span>Note</span>
            <p className="fw-bold">{data.note}</p>
          </div>
          <div className="col text-end">
            {imageExists && (
              <Button
                button="btn-outline-dark"
                title="Lihat Tanda Bukti"
                onClick={imageHandler}
              />
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col text-start">
          <h5 className="ms-1">Status Pembayaran</h5>
        </div>
        <div className="col text-end">
          <Badge
            badge="bg-success px-6"
            name="Lunas"
            type="rounded-pill py-2"
          />
        </div>
      </div>

      <table className="table table-bordered mt-2">
        {tHead.map((d) => (
          <th className="fw-bold">{d}</th>
        ))}
        <tbody>
          {data.list.map((d, index) => (
            <CompleteTableItem key={index} no={index} data={d} master={data} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CompleteItem
