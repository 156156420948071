import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Toast } from '../../../config/Toast'
import Modal from '../../../components/Modal'
import InputForm from '../../../components/InputForm'
import SalaryService from '../../../services/SalaryCalculationMitra10'
import 'react-toastify/dist/ReactToastify.css'

const EditSalaryModal = (props) => {
  const data = props.dataModal
  const [dailySalary, setDailySalary] = useState(
    data.upah_harian ? data.upah_harian : 0
  )
  const [sesiSalary, setSesiSalary] = useState(
    data.upah_per_sesi ? data.upah_per_sesi : 0
  )
  const [insentifSalary, setInsentifSalary] = useState(
    data.upah_insentif ? data.upah_insentif : 0
  )
  const [transportSalary, setTransportSalary] = useState(
    data.upah_transport ? data.upah_transport : 0
  )
  const [serviceSalary, setServiceSalary] = useState(
    data.upah_tambahan_layanan ? data.upah_tambahan_layanan : 0
  )
  const [buildingSalary, setBuildingSalary] = useState(
    data.upah_fee_building ? data.upah_fee_building : 0
  )
  const [total, setTotal] = useState(data.total ? data.upah_harian : 0)
  const [note, setNote] = useState(data.note)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  useEffect(() => {
    const newSesiSalary = (+dailySalary / 8) * 3
    setSesiSalary(newSesiSalary)
  }, [dailySalary])

  useEffect(() => {
    const newTotal =
      +sesiSalary +
      +insentifSalary +
      +transportSalary +
      +serviceSalary +
      +buildingSalary

    setTotal(newTotal)
  }, [
    dailySalary,
    sesiSalary,
    insentifSalary,
    transportSalary,
    serviceSalary,
    buildingSalary,
  ])

  const submitHandler = () => {
    const id = data.id
    const payload = {
      worker_id: data.worker_id,
      worker_name: data.worker_name,
      upah_harian: dailySalary,
      upah_per_sesi: sesiSalary,
      upah_insentif: insentifSalary,
      upah_transport: transportSalary,
      upah_tambahan_layanan: serviceSalary,
      upah_fee_building: buildingSalary,
      total: total,
      note: note,
    }

    SalaryService.edit(id, payload)
      .then(() => {
        notifySuccess(`Upah berhasil diperbarui`)
        props.setUpdate(true)
        props.closeModal()
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(`Upah gagal diperbarui`)
      })
  }

  const forms = [
    {
      margin: 'mb-4',
      label: ' Upah Harian',
      type: 'number',
      id: 'dailySalary',
      value: dailySalary,
      changeHandler: setDailySalary,
    },
    {
      margin: 'mb-4',
      label: 'Upah Per Sesi',
      type: 'number',
      id: 'sesiSalary',
      value: sesiSalary,
      changeHandler: setSesiSalary,
    },
    {
      margin: 'mb-4',
      label: 'Insentif',
      type: 'number',
      id: 'insentifSalary',
      value: insentifSalary,
      changeHandler: setInsentifSalary,
    },
    {
      margin: 'mb-4',
      label: 'Transport',
      type: 'number',
      id: 'transportSalary',
      value: transportSalary,
      changeHandler: setTransportSalary,
    },
    {
      margin: 'mb-4',
      label: 'Bangunan',
      type: 'number',
      id: 'buildingSalary',
      value: buildingSalary,
      changeHandler: setBuildingSalary,
    },
    {
      margin: 'mb-4',
      label: 'Tambahan Layanan',
      type: 'number',
      id: 'serviceSalary',
      value: serviceSalary,
      changeHandler: setServiceSalary,
    },
    {
      margin: 'mb-4',
      label: 'Total',
      type: 'number',
      id: 'total',
      value: total,
      changeHandler: setTotal,
      disabled: true,
    },
    {
      margin: 'mb-4',
      label: 'Keterangan',
      type: 'text',
      id: 'note',
      value: note,
      changeHandler: setNote,
    },
  ]

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title={`ID Project: #${data.project_id}`}
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Update"
    >
      {forms.map((form) => (
        <InputForm key={form.id} {...form} />
      ))}
    </Modal>
  )
}

export default EditSalaryModal
