import moment from 'moment'
import Badge from '../../../components/Badge'
import Button from '../../../components/Button'
import { convertToRupiah } from '../../../helper/Rupiah'

const DetailItem = (props) => {
  const data = props.data
  let paymentType = '-'

  if (data.payment_type) {
    paymentType =
      data.payment_type.charAt(0).toUpperCase() + data.payment_type.slice(1)
  }

  const editHandler = () => {
    props.editHandler(data)
  }

  return (
    <tr>
      <td>{moment(data.month.toString()).format('MMM')}</td>
      <td>
        <Badge
          type={`${
            data.payment_status == 'default'
              ? 'bg-gray text-light'
              : data.payment_status == 'paid'
              ? 'bg-success'
              : 'bg-danger'
          } rounded-pill py-1 px-3`}
          name={data.payment_status}
        />
      </td>
      <td>
        {data.payment_date != null
          ? moment(data.payment_date).format('DD/MM/YYYY')
          : '-'}
      </td>
      <td>{paymentType}</td>
      <td>
        {data.amount != null
          ? `Rp${convertToRupiah(data.amount)} x ${data.total_month}`
          : 'Rp0'}
      </td>
      <td>
        <Badge
          type={`${
            data.bpjs_status == 'default'
              ? 'bg-gray text-light'
              : data.bpjs_status == 'active'
              ? 'bg-success'
              : data.bpjs_status == 'postponed'
              ? 'bg-warning'
              : 'bg-danger'
          } rounded-pill py-1 px-3`}
          name={data.bpjs_status}
        />
      </td>
      <td className="text-success">
        {data.remaining_amount != null
          ? `Rp${convertToRupiah(data.remaining_amount)}`
          : '-'}
      </td>
      <td>{data?.note || '-'}</td>
      <td>
        <Button
          icon="fa fa-pencil"
          button="btn-primary btn-sm"
          onClick={editHandler}
        />
      </td>
    </tr>
  )
}

export default DetailItem
