import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class Kasbon {
  list(page, limit, search, startDate, endDate) {
    const endPoint = `/bill/kasbon?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  bookmark(id) {
    const url = process.env.REACT_APP_API_URL_V1 + `/bill/kasbon/${id}/bookmark`
    return axios.put(url, null, headersTokenConfig)
  }

  detail(id) {
    const endPoint = `/bill/kasbon/${id}/detail`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenConfig)
  }

  update(id, historyId, payload) {
    const url =
      process.env.REACT_APP_API_URL_V1 +
      `/bill/kasbon/${id}/detail/${historyId}/update`
    return axios.put(url, payload, headersTokenConfig)
  }

  post(payload) {
    const url = process.env.REACT_APP_API_URL_V1 + `/bill/kasbon/posting`
    return axios.post(url, payload, headersTokenConfig)
  }
}

export default new Kasbon()
