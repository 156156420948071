import Main from '../../layouts/Main'
import Card from '../../components/Card'
import Tab from '../../components/Tab'
import General from './General/SalaryCalculation'
import Mitra10 from './Mitra10/SalaryCalculation'

const SalaryCalculation = () => {
  const data = [
    {
      name: 'General',
      id: '#tab1',
      content: <General />,
    },
    {
      name: 'Mitra 10',
      id: '#tab2',
      content: <Mitra10 />,
    },
  ]

  return (
    <Main title="Perhitungan Upah">
      <Card col={'col-xl-12 col-lg-12'}>
        <Tab
          data={data}
          defaultId={data[0].id}
          defaultContent={data[0].content}
        />
      </Card>
    </Main>
  )
}

export default SalaryCalculation
