import Main from '../../layouts/Main'
import Card from '../../components/Card'
import Tab from '../../components/Tab'
import Rekap from './Rekap/RekapList'
import BPJS from './BPJS/BpjsList'
import Kasbon from './Kasbon/KasbonList'

const SalaryCut = () => {
  const data = [
    {
      name: 'Rekap',
      id: '#tab1',
      content: <Rekap />,
    },
    {
      name: 'BPJS',
      id: '#tab2',
      content: <BPJS />,
    },
    {
      name: 'Kasbon',
      id: '#tab3',
      content: <Kasbon />,
    },
  ]

  return (
    <Main title="Pemotongan Upah">
      <Card col={'col-xl-12 col-lg-12'}>
        <Tab
          data={data}
          defaultId={data[0].id}
          defaultContent={data[0].content}
        />
      </Card>
    </Main>
  )
}

export default SalaryCut
