import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import Toggle from '../../components/Toggle'
import Button from '../../components/Button'
import Badge from '../../components/Badge'
import UserService from '../../services/User'
import { Toast } from '../../config/Toast'
import { AuthContext } from '../../context/AuthContext'
import 'react-toastify/dist/ReactToastify.css'

const UserItems = (props) => {
  const { currentUser } = useContext(AuthContext)
  const isVerified = props.email_verified_at == null ? false : true
  const [verified, setVerified] = useState(isVerified)
  const [sameUser, setSameUser] = useState(currentUser.email === props.email)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const verifiedHandler = () => {
    const toogle = verified ? 0 : 1

    UserService.setVerified(props.id, toogle)
      .then(() => {
        setVerified(!verified)
        const message = verified
          ? `${props.name} success unverified`
          : `${props.name} success verified`
        notifySuccess(message)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
      })
  }

  const deleteHandler = () => {
    UserService.delete(props.id)
      .then(() => {
        props.setUpdated(true)
        notifySuccess(`Success delete user`)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
      })
  }

  return (
    <tr>
      <td>{props.no}</td>
      <td>{props.name}</td>
      <td>{props.email}</td>
      <td>
        {!sameUser && <Toggle checked={verified} onClick={verifiedHandler} />}
        {sameUser && <Badge badge="bg-primary" name="It's You" />}
      </td>
      <td>
        {!sameUser && (
          <Button
            title="Delete"
            button="btn-danger btn-sm"
            onClick={deleteHandler}
          />
        )}
        {sameUser && <Badge badge="bg-primary" name="It's You" />}
      </td>
    </tr>
  )
}

export default UserItems
