const Card = (props) => {
  return (
    <div className={`row ${props.row}`}>
      <div className={props.col}>
        <div className="card">
          <div className="card-body">{props.children}</div>
        </div>
      </div>
    </div>
  )
}

export default Card
