import { toast } from 'react-toastify'
import { Toast } from '../../config/Toast'
import Modal from '../../components/Modal'
import WorkerService from '../../services/Worker'
import { convertToRupiah } from '../../helper/Rupiah'
import 'react-toastify/dist/ReactToastify.css'

const Confirm = (props) => {
  const data = props.data

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    WorkerService.topup(data.workerId, { amount: data.amount })
      .then(() => {
        props.setUpdate(true)
        notifySuccess(`Deposit saldo BPJS berhasil`)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
      })

    props.closeClick()
  }

  return (
    <Modal
      activeModal={props.activeModal}
      title="Ubah Biaya Bulanan BPJS"
      closeClick={props.closeClick}
      submitHandler={submitHandler}
      type="Ya, Masukkan"
    >
      <h4 className="fw-bold">Masukkan Saldo Deposit Awal ?</h4>
      <p>
        Nominal Saldo yang dimasukkan:
        <h5 className="fw-bold mt-2 mb-4">Rp{convertToRupiah(data.amount)}</h5>
        Pastikan Nominal Saldo sudah sesuai.
        <br />
      </p>
    </Modal>
  )
}

export default Confirm
