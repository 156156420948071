import { useState, useEffect } from 'react'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import MasterBpjsService from '../../services/MasterBpjs'
import Loader from '../../components/Loader'
import MasterBpjsItem from './MasterBpjsItem'
import MasterBpjsEdit from './MasterBpjsEdit'
import MasterBpjsConfirm from './MasterBpjsConfirm'

const MasterBpjs = () => {
  const [bpjs, setBpjs] = useState()
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    setLoading(true)

    MasterBpjsService.list()
      .then((res) => {
        setBpjs(res.data.data)
        setIsUpdated(false)
      })
      .catch((err) => {
        console.log(err)
        setIsUpdated(false)
      })

    setLoading(false)
  }, [isUpdated])

  const confirmHandler = (data) => {
    setModal(false)
    setData(data)
    setModalConfirm(true)
  }

  const closeModalHandler = () => {
    setModalConfirm(false)
    setModal(false)
  }

  if (loading == true) {
    return <Loader />
  }

  return (
    <Main title="Configuration BPJS">
      {modal && (
        <MasterBpjsEdit
          data={data}
          activeModal={modal}
          closeClick={closeModalHandler}
          onConfirm={confirmHandler}
          setModalConfirm={setModalConfirm}
        />
      )}

      {modalConfirm && (
        <MasterBpjsConfirm
          data={data}
          activeModal={modalConfirm}
          setUpdate={setIsUpdated}
          closeClick={closeModalHandler}
        />
      )}

      <Card col={'col-xl-8 col-lg-8'}>
        <div className="row mt-5">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered text-nowrap text-md-nowrap text-center mb-0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Type</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {bpjs && (
                    <MasterBpjsItem
                      key={bpjs.id}
                      no="1"
                      id={bpjs.id}
                      type="BPJS Bulanan"
                      price={bpjs.amount}
                      setModal={setModal}
                      setData={setData}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default MasterBpjs
