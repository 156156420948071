import { useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import _ from 'lodash'
import Modal from '../../../components/Modal'
import InputForm from '../../../components/InputForm'
import { Toast } from '../../../config/Toast'

const Edit = (props) => {
  const dataMaster = props.dataDetail.information
  const masterBpjs = props.masterBpjs
  const data = props.data
  const allData = data.all_data
  const month = moment(`'${data.month}'`).format('MMM')
  const year = moment().format('YYYY')
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const [paymentStatus, setPaymentStatus] = useState(data.payment_status || '')
  const [paymentDate, setPaymentDate] = useState(data.payment_date || '')
  const [disablePaymentDate, setDisablePaymentDate] = useState(true)
  const [paymentType, setPaymentType] = useState(data.payment_type || '')
  const [disablePaymentType, setDisablePaymentType] = useState(true)
  const [totalMonth, setTotalMonth] = useState(data.total_month || '')
  const [disableTotalMonth, setDisableTotalMonth] = useState(true)
  const [disableButton, setDisableButton] = useState(true)
  const [note, setNote] = useState(data.note || '')
  const [alert, setAlert] = useState(false)

  const notifyFailed = (message) => toast.error(message, Toast)

  const paymentStatusHandler = (e) => {
    setAlert(false)
    setPaymentStatus(e)

    if (e == 'unpaid') {
      setDisablePaymentDate(true)
      setDisablePaymentType(true)
      setDisableTotalMonth(true)
      setDisableButton(false)
      setDisableButton(false)
    }

    if (e == 'paid') {
      setDisablePaymentDate(false)
      setDisablePaymentType(false)
      setDisableTotalMonth(false)
      setDisableButton(false)
      setDisableButton(false)

      if (moment().format('M') != data.month) {
        setDisablePaymentType(true)
        setDisableTotalMonth(true)
      }
    }
  }

  const submitHandler = () => {
    const id = data.id
    let index

    for (let i = 0; i < allData.length; i++) {
      if (allData[i].id == id) {
        if (paymentType == 'deposit') {
          const totalBpjs = totalMonth * masterBpjs
          const afterAmount = dataMaster.deposit - totalBpjs

          if (afterAmount < 0) {
            setAlert(true)
            return
          }
        }

        index = i
        break
      }
    }

    allData[index].payment_status = paymentStatus
    allData[index].note = note

    if (paymentStatus == 'paid') {
      allData[index].payment_date = paymentDate
      allData[index].payment_type = paymentType
      allData[index].total_month = totalMonth

      if (allData[index].month == moment().format('M')) {
        allData[index].amount = masterBpjs
      }
    }

    props.confirmHandler(allData)
  }

  const forms = [
    {
      margin: 'mb-4',
      label: 'Status Pembayaran',
      type: 'select',
      option: ['paid', 'unpaid'],
      id: 'payment_status',
      disabled: false,
      defaultOption: 'default',
      placeholder: 'Pilih status pembayaran',
      value: paymentStatus,
      changeHandler: paymentStatusHandler,
    },
    {
      margin: 'mb-4',
      label: 'Tanggal Pembayaran',
      type: 'date',
      id: 'payment_date',
      disabled: disablePaymentDate,
      value: paymentDate,
      changeHandler: setPaymentDate,
    },
    {
      margin: 'mb-4',
      label: 'Jenis Pembayaran',
      type: 'select',
      option: ['upah', 'deposit'],
      id: 'payment_type',
      defaultOption: 'default',
      placeholder: 'Pilih jenis pembayaran',
      disabled: disablePaymentType,
      value: paymentType,
      changeHandler: setPaymentType,
    },
    {
      margin: 'mb-4',
      label: 'Jumlah Pembayaran',
      type: 'number',
      id: 'total_month',
      placeholder: 'Masukkan total bulan yang mau dibayar',
      disabled: disableTotalMonth,
      value: totalMonth,
      changeHandler: setTotalMonth,
    },
    {
      margin: 'mb-4',
      label: 'Note Finance',
      type: 'text',
      id: 'note',
      disabled: false,
      value: note,
      changeHandler: setNote,
    },
  ]

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title={`Pembayaran BPJS - ${month} ${year}`}
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Masukkan"
      buttonDisable={disableButton}
      modalType="modal-lg"
    >
      {alert && (
        <div className="alert alert-danger" role="alert">
          Saldo deposit BPJS tidak cukup
        </div>
      )}
      {forms.map((form) => (
        <InputForm key={form.id} {...form} />
      ))}
    </Modal>
  )
}

export default Edit
