import { useState, Fragment } from 'react'
import Button from '../../../components/Button'
import Badge from '../../../components/Badge'
import SalaryCalculationService from '../../../services/SalaryCalculation'
import { convertToRupiah } from '../../../helper/Rupiah'

const SalaryCalculationItem = (props) => {
  const data = props.data
  const isBookmark =
    data.project_name.substring(data.project_name.length - 1) === '.'
      ? 'btn-success'
      : 'btn-outline-success'
  const hasId = props.id ? true : false
  const isPosting = data.is_posting === 1 ? true : false
  let modifClass
  // let complaintClass =
  //   props.data.is_complaint == 1 ? 'bg-warning text-dark' : null

  switch (data.is_modif) {
    case 'EDIT':
      modifClass = 'text-warning'
      break
    case 'DELETE':
      modifClass = 'text-danger'
      break
    case 'INSERT':
      modifClass = 'text-success'
      break
    default:
      modifClass = 'text-dark'
      break
  }

  const [hasBookmarkBtn, setHasBookmarkBtn] = useState(hasId)
  const [bookmark, setBookmark] = useState(isBookmark)

  const bookmarkHandler = () => {
    bookmark === 'btn-outline-success'
      ? setBookmark('btn-success')
      : setBookmark('btn-outline-success')

    SalaryCalculationService.confirm(props.id)
      .then(() => {})
      .catch((err) => {
        console.log(err)
      })
  }

  const editHandler = () => {
    props.setDataModal(props)
    props.setModal(true)
  }

  return (
    <tr className={isPosting ? 'text-primary' : modifClass}>
      <td>{props.no}</td>
      <td>
        {!isPosting && hasBookmarkBtn && (
          <Fragment>
            <Button
              button={`${bookmark} btn-sm me-1`}
              icon={`fa fa-bookmark ${
                bookmark === 'btn-outline-success' ? 'text-success' : ''
              }`}
              onClick={bookmarkHandler}
            />
            <Button
              button="btn-outline-warning btn-sm"
              icon="fa fa-wrench text-warning"
              onClick={editHandler}
            />
          </Fragment>
        )}
        {isPosting && (
          <Badge badge="bg-primary pt-1" name="posted" type="rounded-pill" />
        )}
      </td>
      <td>{data.attendance_date}</td>
      <td>{data.source_project}</td>
      <td>{data.project_id}</td>
      <td>{data.client_name}</td>
      {/* <td className={complaintClass}>{data.worker_name}</td> */}
      <td>{data.worker_name}</td>
      <td>{data.skill_name}</td>
      <td>Rp. {convertToRupiah(data.order)}</td>
      <td>Rp. {convertToRupiah(data.order_sukses)}</td>
      <td>
        {data.worker_order} / {data.worker_onsite}
      </td>
      <td>{data.sesi}</td>
      <td>Rp. {convertToRupiah(data.upah_harian)}</td>
      <td>Rp. {convertToRupiah(data.upah_add)}</td>
      <td>Rp. {convertToRupiah(data.upah_transport)}</td>
      <td>Rp. {convertToRupiah(data.upah_add_keramik)}</td>
      <td>Rp. {convertToRupiah(data.upah_add_las ? data.upah_add_las : 0)}</td>
      <td>
        Rp.{' '}
        {convertToRupiah(data.upah_fee_building ? data.upah_fee_building : 0)}
      </td>
      <td>
        Rp. {convertToRupiah(data.potongan_keluhan ? data.potongan_keluhan : 0)}
      </td>
      <td>Rp. {convertToRupiah(data.total_upah)}</td>
      <td>Rp. {convertToRupiah(data.insurance ? data.insurance : 0)}</td>
    </tr>
  )
}

export default SalaryCalculationItem
