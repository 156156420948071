import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class Bpjs {
  list(page, limit, search, startDate, endDate) {
    const endPoint = `/bill/bpjs?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  bookmark(id) {
    const url = process.env.REACT_APP_API_URL_V1 + `/bill/bpjs/${id}/bookmark`
    return axios.put(url, null, headersTokenConfig)
  }

  history(id, year) {
    const endPoint = `/bill/bpjs/${id}/history`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (year) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `?year=${year}`
    }

    return axios.get(url, headersTokenConfig)
  }

  update(id, payload) {
    const url = process.env.REACT_APP_API_URL_V1 + `/bill/bpjs/${id}/update`
    return axios.put(url, payload, headersTokenConfig)
  }

  topup(id) {
    const url = process.env.REACT_APP_API_URL_V1 + `/bill/bpjs/${id}/topup`
    return axios.put(url, null, headersTokenConfig)
  }

  post(payload) {
    const url = process.env.REACT_APP_API_URL_V1 + `/bill/bpjs/posting`
    return axios.post(url, payload, headersTokenConfig)
  }
}

export default new Bpjs()
