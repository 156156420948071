import Modal from '../../../components/Modal'
import { convertToRupiah } from '../../../helper/Rupiah'
import 'react-toastify/dist/ReactToastify.css'

const Detail = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''
  const data = props.data
  console.log(data)
  let originalAmount = 0
  let total = 0

  for (const d of data) {
    total += d.amount
    originalAmount += d.upah_harian + d.insentif_transport
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="List Proyek Refund Customer"
      closeClick={props.closeModal}
      type="Detail"
      modalFooter="false"
      modalType="modal-lg"
    >
      <h5 className="text-start">
        Total Upah Awal:
        <b> Rp. {convertToRupiah(parseInt(data.gross_amount))}</b>
      </h5>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Project ID</th>
            <th>Upah Harian</th>
            <th>Insentif & Transport</th>
            <th>Persentase yang diterima Worker</th>
            <th>Total Upah</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, index) => (
            <tr key={index}>
              <td>{d.project_id}</td>
              <td>Rp. {convertToRupiah(parseInt(d.upah_harian))}</td>
              <td>Rp. {convertToRupiah(parseInt(d.insentif_transport))}</td>
              <td>{d.percent}%</td>
              <td>Rp. {convertToRupiah(parseInt(d.amount))}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total Upah Awal</td>
            <td colSpan={2}>Rp. {convertToRupiah(parseInt(originalAmount))}</td>
            <td>Total Upah</td>
            <td className="text-success">
              Rp. {convertToRupiah(parseInt(total))}
            </td>
          </tr>
        </tfoot>
      </table>
    </Modal>
  )
}

export default Detail
