import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class BonusPoint {
  workers(page, limit, search) {
    const endPoint = `/mutasi-point/worker?page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  detail(id) {
    const url =
      process.env.REACT_APP_API_URL_V1 + `/mutasi-point/worker/${id}/detail`
    return axios.get(url, headersTokenConfig)
  }

  allWorkers() {
    const url = process.env.REACT_APP_API_URL_V1 + `/mutasi-point/all-worker`
    return axios.get(url, headersTokenConfig)
  }
}

export default new BonusPoint()
