import { convertToRupiah } from '../../../helper/Rupiah'

const BonusPointItem = (props) => {
  const data = props.data
  const total = +data.bonus + +data.point

  return (
    <tr>
      <th>{props.no}</th>
      <td>{data.worker_id}</td>
      <td>{data.name}</td>
      <td>{data.point}</td>
      <td>{data.bonus}</td>
      <td>Rp. {convertToRupiah(total)}</td>
    </tr>
  )
}

export default BonusPointItem
