import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class SalaryCalculation {
  confirm(id) {
    const endPoint = `/upah-tukang/${id}/confirm`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, null, headersTokenConfig)
  }

  edit(id, payload) {
    const endPoint = `/upah-tukang/${id}/edit-upah`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, payload, headersTokenConfig)
  }

  undo(id) {
    const endPoint = `/upah-tukang/${id}/undo-upah`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, null, headersTokenConfig)
  }

  delete(id) {
    const endPoint = `/upah-tukang/${id}/delete-worker`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, null, headersTokenConfig)
  }

  addWorker(payload) {
    const endPoint = `/upah-tukang/add-worker`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.post(url, payload, headersTokenConfig)
  }

  posting(start, end) {
    const endPoint = `/upah-tukang/posting?start=${start}&end=${end}`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.post(url, null, headersTokenConfig)
  }

  refund(payload) {
    const endPoint = `/upah-tukang/refund-contractor`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.post(url, payload, headersTokenConfig)
  }
}

export default new SalaryCalculation()
