import Button from '../../components/Button'
import { convertToRupiah } from '../../helper/RupiahDashboard'
import 'react-toastify/dist/ReactToastify.css'

const WorkerRefundItem = (props) => {
  const data = props.data
  const percent = 100 - +data.percent

  const buttonHandler = () => {
    props.confirmHandler(data)
  }

  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.project_id}</td>
      <td>{data.worker_id}</td>
      <td>{data.worker_name}</td>
      <td>{percent}%</td>
      <td>Rp.{convertToRupiah(data.total_upah)}</td>
      <td>Rp.{convertToRupiah(data.debt)}</td>
      <td className="text-center align-middle">
        <Button
          button="btn-success btn-sm"
          icon="fa fa-check me-1"
          onClick={buttonHandler}
        />
      </td>
    </tr>
  )
}

export default WorkerRefundItem
