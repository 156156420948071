import { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../components/Modal'
import { Toast } from '../../config/Toast'
import RefundCustomerService from '../../services/RefundCustomer'
import InputForm from '../../components/InputForm'
import 'react-toastify/dist/ReactToastify.css'
import { convertToRupiah } from '../../helper/RupiahDashboard'

const Edit = (props) => {
  const data = props.data
  const workers = data.data_worker_refund
  const [totalRefund, setTotalRefund] = useState(data.total_refund)
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    if (totalRefund <= 0 || totalRefund == null || totalRefund == '') {
      notifyFailed('Total refund tidak boleh kurang dari sama dengan 0')
      return
    }

    props.closeModal()

    RefundCustomerService.edit(data.project_id, totalRefund)
      .then(() => {
        notifySuccess('Berhasil memperbarui data total refund')
        props.setIsUpdated(true)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Terjadi kesalahan')
      })
  }

  const refundHandler = (value) => {
    setTotalRefund(value)
  }

  const forms = [
    {
      type: 'number',
      margin: 'mb-4',
      label: 'Total Refund',
      id: 'total_refund',
      disabled: false,
      defaultOption: 'default',
      placeholder: 'Masukan nominal refund yang dikembalikan ke customer',
      value: totalRefund,
      changeHandler: refundHandler,
    },
  ]

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      modalType="modal-lg"
      title="Update Total Refund"
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Simpan"
    >
      {workers.length > 0 && (
        <table className="table table-bordered mb-3">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Tukang</th>
              <th>Harga</th>
            </tr>
          </thead>
          <tbody>
            {workers.map((worker, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{worker.worker_name}</td>
                <td>{worker.skill_name}</td>
                <td>Rp. {convertToRupiah(worker.price)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {forms.map((form) => (
        <InputForm key={form.id} {...form} />
      ))}
    </Modal>
  )
}

export default Edit
