import Modal from '../../../components/Modal'
import Button from '../../../components/Button'

const MenuModal = (props) => {
  const data = props.dataModal.data
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const addHandler = () => {
    props.setModalType('add')
    props.closeModal(props.modalType)
    props.setModalAdd(true)
  }

  const editHandler = () => {
    props.setModalType('edit')
    props.closeModal(props.modalType)
    props.setModalEdit(true)
  }

  const undoHandler = () => {
    props.setModalType('undo')
    props.closeModal(props.modalType)
    props.setModalUndo(true)
  }

  const deleteHandler = () => {
    props.setModalType('delete')
    props.closeModal(props.modalType)
    props.setModalDelete(true)
  }

  const buttonList = [
    {
      id: 1,
      icon: 'fa fa-user me-1',
      title: 'Tambah Tukang',
      button: 'btn-success me-2',
      onClick: addHandler,
    },
    {
      id: 2,
      icon: 'fa fa-pencil me-1',
      title: 'Koreksi Upah',
      button: 'btn-warning me-2',
      onClick: editHandler,
    },
    {
      id: 3,
      icon: 'fa fa-mail-reply me-1',
      title: 'Undo Koreksi',
      button: 'btn-info me-2',
      onClick: undoHandler,
    },
    {
      id: 4,
      icon: 'fa fa-trash me-1',
      title: 'Delete Worker',
      button: 'btn-danger me-2',
      onClick: deleteHandler,
    },
  ]

  return (
    <Modal
      activeModal={activeModal}
      modalType="modal-lg"
      activeStyle={activeStyle}
      title="Menu Detail"
      closeClick={props.closeModal}
      type="Detail"
    >
      <div className="table-responsive">
        <table className="table text-nowrap text-md-nowrap text-center">
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Client Name</th>
              <th>Worker Name</th>
              <th>Skill Worker</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.attendance_date}</td>
              <td>{data.client_name}</td>
              <td>{data.worker_name}</td>
              <td>{data.skill_name}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {buttonList.map((button) => (
        <Button
          key={button.id}
          icon={button.icon}
          title={button.title}
          button={button.button}
          onClick={button.onClick}
        />
      ))}
    </Modal>
  )
}

export default MenuModal
