import Modal from '../../components/Modal'
import 'react-toastify/dist/ReactToastify.css'

const ImageEvidance = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Tanda Bukti Kasbon"
      closeClick={props.closeModal}
      type="Tanda Bukti Kasbon"
      modalFooter="false"
      modalType="modal-lg"
    >
      <img src={props.data} alt="" />
    </Modal>
  )
}

export default ImageEvidance
