import React from 'react'

const CardDashboard = (props) => {
  const data = props.data

  return (
    <div>
      <div className="card overflow-hidden">
        <div className="card-body">
          <div className="d-flex">
            <div className="mt-2">
              <h6 className>{data.label}</h6>
              <h2 className="mb-2 number-font">{data.value}</h2>
            </div>
            <div className="ms-auto">
              <div className="chart-wrapper mt-1">
                <canvas id="leadschart" className="h-8 w-9 chart-dropshadow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDashboard
