import { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import FileSaver from 'file-saver'
import ButtonGroup from '../../../components/ButtonGroup'
import InputGroup from '../../../components/InputGroup'
import InputGroupDate from '../../../components/InputGroupDate'
import Pagination from '../../../components/Pagination'
import Button from '../../../components/Button'
import ButtonDropdown from '../../../components/ButtonDropdown'
import RekapService from '../../../services/Rekap'
import { Toast } from '../../../config/Toast'
import RekapItem from './RekapItem'
import Posting from './Posting'
import Detail from './Detail'
import 'react-loading-skeleton/dist/skeleton.css'

const RekapList = () => {
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loadingFilter, setLoadingFilter] = useState(false)
  const [loadingPost, setLoadingPost] = useState(false)
  const [btnExcelLoading, setBtnExcelLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [dataDetail, setDataDetail] = useState(null)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)
  const limit = 10

  useEffect(() => {
    if (startDate && endDate) {
      setLoading(true)

      RekapService.list(pageNumber, limit, search, startDate, endDate)
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
        })
        .catch((err) => {
          console.log(err)
        })

      setUpdate(false)

      setTimeout(() => {
        setLoading(false)
      }, 1200)
    }
  }, [update])

  const filterHandler = () => {
    if (startDate && endDate) {
      setLoadingFilter(true)

      RekapService.list(pageNumber, limit, search, startDate, endDate)
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setLoadingFilter(false)
        })
        .catch((err) => {
          console.log(err)
          setLoadingFilter(false)
        })
    } else {
      notifyFailed('Periode rentang waktu tidak boleh kosong')
    }
  }

  const postHandler = () => {
    if (!startDate || !endDate) {
      notifyFailed('Periode rentang waktu tidak boleh kosong')
      return
    }

    setModal(true)
  }

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setUpdate(true)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setUpdate(true)
  }

  const confirmHandler = () => {
    setLoadingPost(true)

    const payload = {
      start: startDate,
      end: endDate,
    }

    RekapService.post(payload)
      .then(() => {
        notifySuccess('Upah tukang berhasil di rekap')
        setLoadingPost(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingPost(false)
      })
  }

  const excelHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnExcelLoading(true)

    RekapService.excel(startDate, endDate)
      .then((res) => {
        const url = `${process.env.REACT_APP_API_DOMAIN}/${res.data.path}`
        FileSaver.saveAs(url, res.data.path)

        notifySuccess('Success download report disbursment worker')
        setBtnExcelLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setBtnExcelLoading(false)
        notifyFailed('Something went wrong!')
      })
  }

  const excelRefundHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnExcelLoading(true)

    RekapService.excelRefund(startDate, endDate)
      .then((res) => {
        const url = `${process.env.REACT_APP_API_DOMAIN}/${res.data.path}`
        FileSaver.saveAs(url, res.data.path)

        notifySuccess('Success download report refund disbursment worker')
        setBtnExcelLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setBtnExcelLoading(false)
        notifyFailed('Something went wrong!')
      })
  }

  const detailHandler = (data, grossAmount) => {
    data.gross_amount = grossAmount
    setDataDetail(data)
    setModalDetail(true)
  }

  const closeModalHandler = () => {
    setModal(false)
    setModalDetail(false)
  }

  const tHead = [
    'No',
    'Worker ID',
    'Worker Name',
    'Amount',
    'Bank',
    'Bank Account',
    'Bank Number',
  ]

  const dropdown = [
    {
      name: 'Default',
      clickFunction: excelHandler,
    },
    {
      name: 'With Cut',
      clickFunction: excelRefundHandler,
    },
  ]

  return (
    <Fragment>
      {modal && (
        <Posting
          activeModal={modal}
          closeModal={closeModalHandler}
          onConfirm={confirmHandler}
        />
      )}

      {modalDetail && (
        <Detail
          activeModal={modalDetail}
          closeModal={closeModalHandler}
          data={dataDetail}
        />
      )}

      <div className="row">
        <div className="col-3">
          <InputGroupDate
            type="startDate"
            label="Start Date"
            setStartDate={setStartDate}
          />
        </div>
        <div className="col-3">
          <InputGroupDate
            type="endDate"
            label="End Date"
            setEndDate={setEndDate}
          />
        </div>
        <div className="col">
          <div className="row mt-5">
            <div className="col mt-4">
              <ButtonGroup
                buttonOne="btn-primary"
                titleOne="Filter"
                iconOne="fa fa-filter me-1"
                onClickOne={filterHandler}
                loadingOne={loadingFilter}
                buttonTwo="btn-orange"
                titleTwo="Post to BPJS"
                iconTwo="ti-angle-double-right me-1"
                onClickTwo={postHandler}
                loadingTwo={loadingPost}
              />
            </div>
            <div className="col-2 mt-4 me-2">
              {/* <Button
                button="btn-success"
                title="Excel"
                icon="fa fa-file-excel-o me-1"
                onClick={excelHandler}
                loading={btnExcelLoading}
              /> */}
              <ButtonDropdown
                button="btn-success"
                title="Excel"
                icon="fa fa-file-excel-o me-1"
                dropdown={dropdown}
                loading={btnExcelLoading}
              />
            </div>
            <div className="col mt-4">
              <InputGroup
                button="btn-primary"
                icon="fa fa-search"
                searchHandler={searchHandler}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover text-nowrap text-md-nowrap mb-0 text-center">
              <thead>
                <tr>
                  {tHead.map((d, index) => (
                    <th key={index}>{d}</th>
                  ))}
                </tr>
              </thead>
              {loading &&
                tHead.map((_, index) => (
                  <td>
                    <Skeleton
                      key={index}
                      count={5}
                      variant="rectangular"
                      height={33}
                      style={{
                        marginBottom: '0.5rem',
                      }}
                    />
                  </td>
                ))}
              {!loading && (
                <tbody>
                  {data.map((d, index) => (
                    <RekapItem
                      key={d.id}
                      no={
                        index +
                        (pageNumber == 1 ? 1 : limit * pageNumber - limit + 1)
                      }
                      data={d}
                      detailHandler={detailHandler}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          {totalPage > 0 && (
            <Pagination pageCount={totalPage} changePage={changePageHandler} />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default RekapList
