import { useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../../components/Button'
import { Toast } from '../../config/Toast'
import RecalculateService from '../../services/Recalculate'

const RecalculateItem = (props) => {
  const [btnLoading, setBtnLoading] = useState(false)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const recalculateHandler = () => {
    if (props.startDate === null || props.endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnLoading(true)

    const payload = {
      start: props.startDate,
      end: props.endDate,
      module: props.type,
    }

    RecalculateService.recalculate(payload)
      .then(() => {
        setBtnLoading(false)
        notifySuccess(`${props.name} success recalculated`)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Something went wrong!')
        setBtnLoading(false)
      })
  }

  return (
    <tr>
      <td>{props.no}</td>
      <td>{props.name}</td>
      <td>
        <Button
          title="Recalculate"
          button="btn-primary btn-sm"
          icon="fa fa-gear me-1"
          loading={btnLoading}
          onClick={recalculateHandler}
        />
      </td>
    </tr>
  )
}

export default RecalculateItem
