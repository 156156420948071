import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class WorkerRefundService {
  listWorkers(page, limit) {
    const url =
      process.env.REACT_APP_API_URL_V1 +
      `/ticketing?page=${page}&limit=${limit}`

    return axios.get(url, headersTokenConfig)
  }

  syncWorker() {
    const url = process.env.REACT_APP_API_URL_V1 + '/ticketing/sync'
    return axios.get(url, headersTokenConfig)
  }

  detail(workerId) {
    const url =
      process.env.REACT_APP_API_URL_V1 + `/ticketing/${workerId}/detail`
    return axios.get(url, headersTokenConfig)
  }

  confirm(id) {
    const endPoint = `/ticketing/${id}/confirm`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, null, headersTokenConfig)
  }
}

export default new WorkerRefundService()
