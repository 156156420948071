import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import Loader from '../../components/Loader'
import Button from '../../components/Button'
import InputGroup from '../../components/InputGroup'
import Pagination from '../../components/Pagination'
import WorkerService from '../../services/Worker'
import ParameterService from '../../services/Parameter'
import WorkerItem from './WorkerItem'
import Topup from './Topup'
import Confirm from './Confirm'
import { Toast } from '../../config/Toast'
import 'jsoneditor-react/es/editor.min.css'

const WorkerList = () => {
  const [workers, setWorkers] = useState()
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [data, setData] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState(null)
  const [totalPage, setTotalPage] = useState(0)
  const limit = 10
  const notifySucces = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  useEffect(() => {
    setLoading(true)

    WorkerService.list(pageNumber, limit, search)
      .then((res) => {
        setWorkers(res.data.data)
        setTotalPage(res.data.total_page)
      })
      .catch((err) => {
        console.log(err)
      })

    setIsUpdated(false)
    setLoading(false)
  }, [isUpdated])

  const syncWorkerHandler = () => {
    setBtnLoading(true)

    ParameterService.syncWorkers()
      .then(() => {
        setIsUpdated(true)
        setBtnLoading(false)
        notifySucces(`Sync data tukang berhasil`)
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
        notifyFailed('Gagal sync data tukang')
      })
  }

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setIsUpdated(true)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setIsUpdated(true)
  }

  const confirmHandler = (data) => {
    if (data.amount < 0) {
      notifyFailed('Nominal tidak boleh kurang dari 0')
      setModal(false)
      return
    }

    setModal(false)
    setData(data)
    setModalConfirm(true)
  }

  const closeModalHandler = () => {
    setModalConfirm(false)
    setModal(false)
  }

  if (loading == true) {
    return <Loader />
  }

  return (
    <Main title="List Tukang">
      {modal && (
        <Topup
          data={data}
          activeModal={modal}
          closeClick={closeModalHandler}
          onConfirm={confirmHandler}
        />
      )}

      {modalConfirm && (
        <Confirm
          data={data}
          activeModal={modalConfirm}
          setUpdate={setIsUpdated}
          closeClick={closeModalHandler}
        />
      )}

      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row">
          <div className="col">
            <Button
              button="btn-orange"
              title="Sync Data Worker"
              icon="fa fa-exchange me-2"
              onClick={syncWorkerHandler}
              loading={btnLoading}
            />
          </div>
          <div className="col">
            <InputGroup
              button="btn-primary"
              icon="fa fa-search"
              searchHandler={searchHandler}
            />
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered text-nowrap text-md-nowrap text-center mb-0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Worker Name</th>
                    <th>Bank</th>
                    <th>Bank Account Name</th>
                    <th>Bank Number</th>
                    <th>Email</th>
                    <th>Deposit</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {workers &&
                    workers.map((worker, index) => (
                      <WorkerItem
                        key={workers.id}
                        no={
                          index +
                          (pageNumber == 1 ? 1 : limit * pageNumber - limit + 1)
                        }
                        data={worker}
                        type="Deposit Awal"
                        setModal={setModal}
                        setData={setData}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            {totalPage > 0 && (
              <Pagination
                pageCount={totalPage}
                changePage={changePageHandler}
              />
            )}
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default WorkerList
