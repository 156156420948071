import { useState } from 'react'
import Modal from '../../../components/Modal'

const Edit = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''
  const [reason, setReason] = useState(null)

  const submitHandler = () => {
    props.updateHandler(reason)
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title=""
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Ya, Bayar"
      modalHeader="false"
    >
      <h4 className="fw-bold text-center mt-4">
        Yakin untuk membayar kasbon ini ?
      </h4>
      <h5 className="mt-2">
        Jika sudah membayar, data tidak dapat dikembalikan
      </h5>
      <textarea
        className="form-control mt-5"
        rows="7"
        onChange={(e) => setReason(e.target.value)}
        placeholder="Kosongkan jika tidak ingin memberi catatan"
      ></textarea>
    </Modal>
  )
}

export default Edit
