import { Fragment } from 'react'

const InputGroupDate = (props) => {
  const type = props.type
  const isLabel = props.label ? true : false

  const changeHandler = (e) => {
    switch (type) {
      case 'startDate':
        props.setStartDate(e.target.value)
        break
      case 'endDate':
        props.setEndDate(e.target.value)
        break
    }
  }

  return (
    <Fragment>
      {isLabel && <h5 className="card-title">{props.label}</h5>}
      <div className="wd-200 mg-b-30">
        <div className="input-group">
          <div className="input-group-text">
            <i className="fa fa-calendar text-light tx-16 lh-0 op-6" />
          </div>
          <input
            className="form-control"
            placeholder="MM/DD/YYYY"
            type="date"
            onChange={changeHandler}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default InputGroupDate
