import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class SalaryCalculationMitra10 {
  confirm(id) {
    const endPoint = `/upah-tukang/mitra10/${id}/confirm`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, null, headersTokenConfig)
  }

  edit(id, payload) {
    const endPoint = `/upah-tukang/mitra10/${id}/edit-upah`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, payload, headersTokenConfig)
  }

  undo(id) {
    const endPoint = `/upah-tukang/mitra10/${id}/undo-upah`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenConfig)
  }

  addWorker(id, payload) {
    const endPoint = `/upah-tukang/mitra10/${id}/add-worker`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, payload, headersTokenConfig)
  }

  delete(id) {
    const endPoint = `/upah-tukang/mitra10/${id}/delete-worker`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.delete(url, headersTokenConfig)
  }

  posting(start, end) {
    const endPoint = `/upah-tukang/mitra10/posting?start_date=${start}&end_date=${end}`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.post(url, null, headersTokenConfig)
  }
}

export default new SalaryCalculationMitra10()
