import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import { Toast } from "../../config/Toast";
import RefundCustomerService from "../../services/RefundCustomer";
import "react-toastify/dist/ReactToastify.css";
import styles from "./RefundCustomer.module.css";

const Confirm = (props) => {
  const data = props.data;
  const activeModal = props.activeModal ? "effect-scale show" : "";
  const activeStyle = activeModal
    ? { display: "block", paddingright: "17px" }
    : "";

  const [namaBank, setNamaBank] = useState("");
  const [pemilikRekening, setPemilikRekening] = useState("");
  const [noRekening, setNoRekening] = useState("");

  const notifySuccess = (message) => toast.success(message, Toast);
  const notifyFailed = (message) => toast.error(message, Toast);

  useEffect(() => {
    if (data) {
      setNamaBank(data.bank_name);
      setPemilikRekening(data.bank_account_name);
      setNoRekening(data.bank_account_number);
    }
  }, []);

  const submitHandler = () => {
    if (
      data.total_refund <= 0 ||
      data.total_refund == null ||
      data.total_refund === ""
    ) {
      notifyFailed("Total refund tidak boleh kurang dari sama dengan 0");
      props.closeModal();
      return;
    }

    props.closeModal();

    RefundCustomerService.confirm(data.project_id)
      .then(() => {
        notifySuccess("Berhasil konfirmasi refund customer");
        props.setIsUpdated(true);
      })
      .catch((err) => {
        console.log(err);
        notifyFailed("Terjadi kesalahan");
      });
  };

  const changeType = (type) => {
    props.setType(type);
    if (type === "confirm") {
      setNamaBank(data.bank_name);
      setPemilikRekening(data.bank_account_name);
      setNoRekening(data.bank_account_number);
    }
  };

  const disableButtonEdit = () => {
    if (
      namaBank === "" ||
      namaBank === null ||
      pemilikRekening === "" ||
      pemilikRekening === null ||
      noRekening === "" ||
      noRekening === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const editRekeningHandler = () => {
    const payload = {
      bank_name: namaBank,
      bank_account_name: pemilikRekening,
      bank_account_number: noRekening,
    };

    RefundCustomerService.editRekening(data.id, payload)
      .then(() => {
        notifySuccess("Berhasil ubah detail rekening");
        props.setType("confirm");
        props.setIsUpdated(true);
      })
      .catch((err) => {
        console.log(err);
        props.setType("confirm");
        setNamaBank(data.bank_name);
        setPemilikRekening(data.bank_account_name);
        setNoRekening(data.bank_account_number);
        notifyFailed("Terjadi kesalahan, silahkan coba lagi");
      });
  };

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title={props.type === "confirm" ? "Refund Project" : "Ubah Data"}
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Proses"
      buttonDisable={props.type === "confirm" ? false : true}
    >
      <div className="px-5">
        <p>Apakah Anda yakin telah membayar refund project?</p>
        <p className="fw-bold">
          Pastikan semua data sudah benar. Data tidak dapat diubah <br />{" "}
          setelah di proses
        </p>
        <div className="input-group">
          <span
            className={`${styles.inputGroupText} input-group-text bg-light text-black`}
            style={{ width: "160px" }}
          >
            NAMA BANK <span className="text-danger">*</span>
          </span>
          <input
            type="text"
            className={`${styles.disableInput} form-control fw-bold`}
            disabled={props.type === "confirm"}
            value={namaBank}
            onChange={(e) => setNamaBank(e.target.value)}
          />
        </div>
        <div className="input-group">
          <span
            className={`${styles.inputGroupText} input-group-text bg-light text-black`}
            style={{ width: "160px" }}
          >
            PEMILIK REKENING <span className="text-danger">*</span>
          </span>
          <input
            type="text"
            className={`${styles.disableInput} form-control fw-bold`}
            disabled={props.type === "confirm"}
            value={pemilikRekening}
            onChange={(e) => setPemilikRekening(e.target.value)}
          />
        </div>
        <div className="input-group">
          <span
            className={`${styles.inputGroupText} input-group-text bg-light text-black`}
            style={{ width: "160px" }}
          >
            NO. REKENING <span className="text-danger">*</span>
          </span>
          <input
            type="text"
            className={`${styles.disableInput} form-control fw-bold`}
            disabled={props.type === "confirm"}
            value={noRekening}
            onChange={(e) => setNoRekening(e.target.value)}
          />
        </div>

        {props.type === "confirm" ? (
          <div className="d-flex justify-content-between my-5">
            <div className="text-start">
              <p className="m-0 fw-bold">No. Rekening tidak sesuai ?</p>
              <p className="m-0">Klik ubah untuk mengganti data</p>
            </div>
            <div>
              <button
                className="btn btn-outline-primary fw-semibold d-flex align-items-center"
                style={{ height: "27px" }}
                onClick={() => changeType("edit")}
                type="button"
              >
                <p>Ubah</p>
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between my-5">
            <button
              className={`${styles.btnEdit} btn fw-bold`}
              type="button"
              onClick={() => changeType("confirm")}
            >
              Batal Edit
            </button>
            <button
              className={`${styles.btnConfirm} btn btn-primary fw-bold`}
              type="button"
              disabled={disableButtonEdit()}
              onClick={editRekeningHandler}
            >
              Simpan
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Confirm;
