import Button from '../../../components/Button'
import Tab from '../../../components/Tab'
import Ongoing from './Ongoing'
import Complete from './Complete'

const Detail = (props) => {
  const data = props.data
  const ongoing = data.ongoing
  const complete = data.complete
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const closeHandler = () => {
    props.closeModal()
  }

  const tab = [
    {
      name: 'Sedang Berjalan',
      id: '#tab1',
      content: (
        <Ongoing
          data={ongoing}
          editHandler={props.editHandler}
          imageHandler={props.imageHandler}
        />
      ),
    },
    {
      name: 'Lunas',
      id: '#tab2',
      content: <Complete data={complete} imageHandler={props.imageHandler} />,
    },
  ]

  return (
    <div
      className={`modal fade ${activeModal}`}
      id="modalDetailKasbon"
      style={activeStyle}
      role="dialog"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-xl text-center "
        role="document"
      >
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h5 className="modal-title fw-bold">
              {`Detail Kasbon | ${props.data.worker_name}`}
            </h5>
            <button
              aria-label="Close"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={props.closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body pb-7">
            <Tab
              data={tab}
              defaultId={tab[0].id}
              defaultContent={tab[0].content}
            />
            <div className="text-end">
              <Button
                button="btn-gray ms-4 px-7"
                title="Tutup"
                onClick={closeHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail
