import { toast } from 'react-toastify'
import { Toast } from '../../../config/Toast'
import Modal from '../../../components/Modal'
import SalaryCalculationService from '../../../services/SalaryCalculationMitra10'
import 'react-toastify/dist/ReactToastify.css'

const DeleteModal = (props) => {
  const data = props.dataModal
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    const id = data.id

    SalaryCalculationService.delete(id)
      .then(() => {
        props.setUpdate(true)
        props.closeModal()
        notifySuccess(`Tukang berhasil dihapus`)
      })
      .catch((err) => {
        console.log(err.response.data.message)
        notifyFailed(err.response.data.message)
        props.closeModal()
      })
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Hapus Tukang"
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Delete"
    >
      <h5>
        Apakah anda yakin untuk menghapus tukang
        <b> '{data.worker_name ? data.worker_name : '-'}'</b> <br /> pada
        <b> Project #{data.project_id}</b> ?
      </h5>
    </Modal>
  )
}

export default DeleteModal
