import { convertToRupiah } from '../../../../helper/Rupiah'

const DisbursmentWorkerItem = (props) => {
  const data = props.data

  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.project_id}</td>
      <td>{data.date_project}</td>
      <td>{data.client_name}</td>
      <td>{data.worker_name}</td>
      <td>{data.product_name}</td>
      <td>{data.service_name}</td>
      <td>{`${data.sesi} (${data.sesi_start}-${data.sesi_end})`}</td>
      <td>Rp. {convertToRupiah(data.order)}</td>
      <td>Rp. {convertToRupiah(data.upah_harian)}</td>
      <td>Rp. {convertToRupiah(data.upah_per_sesi)}</td>
      <td>Rp. {convertToRupiah(data.upah_insentif)}</td>
      <td>Rp. {convertToRupiah(data.upah_transport)}</td>
      <td>Rp. {convertToRupiah(data.upah_tambahan_layanan)}</td>
      <td>Rp. {convertToRupiah(+data.upah_fee_building)}</td>
      <td>Rp. {convertToRupiah(data.total)}</td>
      <td>{data.note}</td>
    </tr>
  )
}

export default DisbursmentWorkerItem
