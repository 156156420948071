import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'
import { headersTokenExcel } from '../config/HeaderTokenExcel'

class Report {
  consumer(page, limit, search, startDate, endDate) {
    const endPoint = `/report/deposit/consumer?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  consumerExcel(startDate, endDate) {
    const endPoint = `/report/deposit/consumer/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  consumerInvoice(projectId) {
    const endPoint = `/report/deposit/invoice?project_id=${projectId}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenConfig)
  }

  consumerMultipleInvoice(payload) {
    const url =
      process.env.REACT_APP_API_URL_V1 + `/report/deposit/invoice-multiple`
    return axios.post(url, payload, headersTokenConfig)
  }

  vendor(page, limit, search, startDate, endDate) {
    const endPoint = `/report/deposit/vendor?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  vendorExcel(startDate, endDate) {
    const endPoint = `/report/deposit/vendor/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  contractor(page, limit, search, startDate, endDate) {
    const endPoint = `/report/deposit/contractor?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  contractorExcel(startDate, endDate) {
    const endPoint = `/report/deposit/contractor/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  pelaksana(page, limit, search, startDate, endDate) {
    const endPoint = `/report/deposit/pelaksana?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  pelaksanaExcel(startDate, endDate) {
    const endPoint = `/report/deposit/pelaksana/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  voucher(page, limit, search, startDate, endDate) {
    const endPoint = `/report/deposit/consumer/voucher?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  voucherExcel(startDate, endDate) {
    const endPoint = `/report/deposit/consumer/voucher/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  komisiVendors(startDate, endDate, search) {
    const endPoint = `/report/deposit/consumer/voucher/rekap?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenExcel)
  }

  komisiVendorsExcel(startDate, endDate) {
    const endPoint = `/report/deposit/consumer/voucher/rekap/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  salaryCalculation(page, limit, search, startDate, endDate) {
    const endPoint = `/report/upah-tukang?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  salaryCalculationExcel(startDate, endDate) {
    const endPoint = `/report/upah-tukang/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  salaryCalculationExcelByPm(startDate, endDate) {
    const endPoint = `/report/upah-tukang/excel/pm?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  salaryCalculationMitra10(page, limit, search, startDate, endDate) {
    const endPoint = `/report/upah-tukang/mitra10?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  salaryCalculationMitra10Excel(startDate, endDate) {
    const endPoint = `/report/upah-tukang/mitra10/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  disbursmentWorker(page, limit, search, startDate, endDate) {
    const endPoint = `/report/disbursment-worker?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  disbursmentWorkerExcel(startDate, endDate) {
    const endPoint = `/report/disbursment-worker/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  disbursmentWorkerNew(page, limit, search, startDate, endDate) {
    const endPoint = `/report/disbursment-worker-new?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  disbursmentWorkerExcelNew(startDate, endDate) {
    const endPoint = `/report/disbursment-worker-new/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  disbursmentWorkerExcelDetailNew(startDate, endDate) {
    const endPoint = `/report/disbursment-worker-new/excel-detail?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  disbursmentWorkerMitra10(page, limit, search, startDate, endDate) {
    const endPoint = `/report/disbursment-worker/mitra10?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  disbursmentWorkerMitra10Excel(startDate, endDate) {
    const endPoint = `/report/disbursment-worker/mitra10/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  refundKontraktor(page, limit, search, startDate, endDate) {
    const endPoint = `/report/refund-kontraktor?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  refundKontraktorExcel(startDate, endDate) {
    const endPoint = `/report/refund-kontraktor/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  bonusPoint(page, limit, search, startDate, endDate) {
    const endPoint = `/report/bonus-point?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  bonusPointExcel(startDate, endDate) {
    const endPoint = `/report/bonus-point/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }
}

export default new Report()
