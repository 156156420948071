export const convertToRupiah = (numb) => {
  const format = numb.toString().split('').reverse().join('')
  const convert = format.match(/\d{1,3}/g)
  let result = convert.join('.').split('').reverse().join('')

  if (numb < 0) {
    result = '-' + result
  }

  return result
}
