import { toast } from 'react-toastify'
import Modal from '../../components/Modal'
import { Toast } from '../../config/Toast'
import WorkerRefundService from '../../services/WorkerRefundService'
import 'react-toastify/dist/ReactToastify.css'

const Confirm = (props) => {
  const data = props.data
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    props.closeModal()

    WorkerRefundService.confirm(data.id)
      .then(() => {
        notifySuccess('Berhasil konfirmasi hutang tukang')
        props.setIsUpdated(true)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
      })
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Posting Perhitungan Upah"
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Ya, Konfirmasi"
    >
      <h5>
        Apakah anda yakin telah memotong upah tukang berdasarkan nominal
        hutangnya? <br />
        <br />
        <div className="fw-bold">
          Setelah proses ini, <br /> hutang tukang sudah tidak bisa lagi di
          koreksi.
        </div>
      </h5>
    </Modal>
  )
}

export default Confirm
