import Modal from '../../components/Modal'

const Detail = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title={`Detail Mutasi - ${props.data.worker_name}`}
      closeClick={props.closeClick}
      type="Detail"
    >
      <div className="table-responsive">
        <table className="table text-nowrap text-md-nowrap text-center mb-0">
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Debet</th>
              <th>Kredit</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(props.data).length > 0 && (
              <tr>
                <td>{props.data.mutasi_date}</td>
                <td>{props.data.debet}</td>
                <td>{props.data.kredit}</td>
                <td>{props.data.saldo}</td>
              </tr>
            )}
            {Object.keys(props.data).length <= 1 && (
              <tr>
                <td colSpan="4">Point & Bonus is empty</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

export default Detail
