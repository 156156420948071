import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'
import { headersTokenFormData } from '../config/HeaderTokenFormData'

class KasbonApproval {
  list(page, limit, search, startDate, endDate) {
    const endPoint = `/bill/kasbon/approval?page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    if (startDate && endDate) {
      url =
        process.env.REACT_APP_API_URL_V1 +
        endPoint +
        `&start=${startDate}&end=${endDate}`
    }

    return axios.get(url, headersTokenConfig)
  }

  detail(id) {
    const endPoint = `/bill/kasbon/approval/${id}/detail`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenConfig)
  }

  confirm(id) {
    const endPoint = `/bill/kasbon/approval/${id}/confirm`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, null, headersTokenConfig)
  }

  reject(id, payload) {
    const endPoint = `/bill/kasbon/approval/${id}/reject`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, payload, headersTokenConfig)
  }

  download(id) {
    const endPoint = `/bill/kasbon/approval/${id}/download`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenConfig)
  }

  upload(id, bodyFormData) {
    const endPoint = `/cms/kasbon/${id}/upload`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, bodyFormData, headersTokenFormData)
  }
}

export default new KasbonApproval()
