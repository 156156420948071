const InputForm = (props) => {
  const changeHandler = (e) => {
    props.changeHandler(e.target.value)
  }

  const focusHandler = () => {
    if (props.focusHandler) {
      props.focusHandler()
    }
  }

  return (
    <div className={`row ${props.margin}`}>
      <div className="">
        <input
          type={props.type}
          className="form-control"
          id={props.id}
          value={props.value}
          onChange={changeHandler}
          onFocus={focusHandler}
          disabled={props.disabled ? props.disabled : false}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  )
}

export default InputForm
