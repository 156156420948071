import { convertToRupiah } from '../../../helper/Rupiah'

const VendorCommissionItem = (props) => {
  const data = props.data

  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.name}</td>
      <td>{data.pic}</td>
      <td>{data.phone}</td>
      <td>Rp. {convertToRupiah(data.total)}</td>
    </tr>
  )
}

export default VendorCommissionItem
