import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { Toast } from '../../../config/Toast'
import InputForm from '../../../components/InputForm'
import SalaryService from '../../../services/SalaryCalculation'
import 'react-toastify/dist/ReactToastify.css'

const AddModal = (props) => {
  const data = props.dataModal.data

  const [workers, setWorkers] = useState(props.workers)
  const [skills, setSkills] = useState(props.skills)
  const [workerSelected, setWorkerSelected] = useState(null)
  const [skillSelected, setSkillSelected] = useState(null)
  const [dailySalary, setDailySalary] = useState(data.upah_harian)
  const [insentifSalary, setInsentifSalary] = useState(data.upah_add)
  const [transportSalary, setTransportSalary] = useState(data.upah_transport)
  const [keramikSalary, setKeramikSalary] = useState(data.upah_add_keramik)
  const [lasSalary, setLasSalary] = useState(data.upah_add_las)
  const [buildingSalary, setBuildingSalary] = useState(data.upah_fee_building)
  // const [complaintAmount, setComplaintAmount] = useState(data.potongan_keluhan)
  const [total, setTotal] = useState(data.total_upah)
  const [note, setNote] = useState(data.keterangan)

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  useEffect(() => {
    const newTotal =
      +dailySalary +
      +insentifSalary +
      +transportSalary +
      +keramikSalary +
      +lasSalary +
      +buildingSalary
    // +complaintAmount

    setTotal(newTotal)
  }, [
    dailySalary,
    insentifSalary,
    transportSalary,
    keramikSalary,
    lasSalary,
    buildingSalary,
    // complaintAmount,
  ])

  const workerSelectHandler = (options) => {
    setWorkerSelected(options)
  }

  const skillSelectHandler = (options) => {
    setSkillSelected(options)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const workerId = workerSelected.value
    const workerName = workerSelected.label
    const skillId = skillSelected.value
    const skillName = skillSelected.label

    const payload = {
      user_id: workerId,
      project_id: data.project_id,
      start_date: data.attendance_date,
      worker_name: workerName,
      upah_harian: dailySalary,
      upah_add: insentifSalary,
      upah_transport: transportSalary,
      upah_add_keramik: keramikSalary,
      upah_add_las: lasSalary,
      upah_fee_building: buildingSalary,
      // potongan_keluhan: complaintAmount,
      total_upah: total,
      keterangan: note,
      skill_id: skillId,
      skill_name: skillName,
    }

    SalaryService.addWorker(payload)
      .then(() => {
        notifySuccess(`Tukang berhasil ditambahkan`)
        props.setUpdate(true)
        props.closeModal()
      })
      .catch((err) => {
        console.log(err)
        notifyFailed(err.response.data.message)
      })
  }

  const forms = [
    {
      margin: 'mb-2',
      label: ' Upah Harian',
      type: 'number',
      id: 'dailySalary',
      value: dailySalary,
      changeHandler: setDailySalary,
    },
    {
      margin: 'mb-2',
      label: 'Insentif',
      type: 'number',
      id: 'insentifSalary',
      value: insentifSalary,
      changeHandler: setInsentifSalary,
    },
    {
      margin: 'mb-2',
      label: 'Transport',
      type: 'number',
      id: 'transportSalary',
      value: transportSalary,
      changeHandler: setTransportSalary,
    },
    {
      margin: 'mb-2',
      label: 'Keramik',
      type: 'number',
      id: 'keramikSalary',
      value: keramikSalary,
      changeHandler: setKeramikSalary,
    },
    {
      margin: 'mb-2',
      label: 'Las',
      type: 'number',
      id: 'lasSalary',
      value: lasSalary,
      changeHandler: setLasSalary,
    },
    {
      margin: 'mb-2',
      label: 'Bangunan',
      type: 'number',
      id: 'buildingSalary',
      value: buildingSalary,
      changeHandler: setBuildingSalary,
    },
    // {
    //   margin: 'mb-2',
    //   label: 'Potongan Keluhan',
    //   type: 'number',
    //   id: 'complaintAmount',
    //   value: complaintAmount,
    //   changeHandler: setComplaintAmount,
    // },
    {
      margin: 'mb-2',
      label: 'Total',
      type: 'number',
      id: 'total',
      value: total,
      changeHandler: setTotal,
      disabled: true,
    },
    {
      margin: 'mb-2',
      label: 'Keterangan',
      type: 'text',
      id: 'note',
      value: note,
      changeHandler: setNote,
    },
  ]

  return (
    <div
      className={`modal fade ${activeModal}`}
      id="modalAddGeneral"
      style={activeStyle}
      role="dialog"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered text-center "
        role="document"
      >
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h5 className="modal-title fw-bold">
              ID Project: #{data.project_id}
            </h5>
            <button
              aria-label="Close"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={props.closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap text-center table-bordered">
                <thead className="table-info">
                  <tr>
                    <th>Tanggal</th>
                    <th>Client Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.attendance_date}</td>
                    <td>{data.client_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <form className="form-horizontal" onSubmit={submitHandler}>
              <div className="modal-body">
                <div className="row mb-2">
                  <label htmlFor="worker" className="col-md-3 form-label">
                    Nama Tukang
                  </label>
                  <div className="col-md-9">
                    <Select
                      options={workers}
                      value={workerSelected}
                      onChange={workerSelectHandler}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <label htmlFor="skill" className="col-md-3 form-label">
                    Skill Tukang
                  </label>
                  <div className="col-md-9">
                    <Select
                      options={skills}
                      value={skillSelected}
                      onChange={skillSelectHandler}
                    />
                  </div>
                </div>
                {forms.map((form) => (
                  <InputForm key={form.id} {...form} />
                ))}
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary">Add</button>
                <button
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={props.closeModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddModal
