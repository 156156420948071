const rmsStorage = localStorage.getItem('rms')
let token = null

if (rmsStorage != null) {
  const rmsStorageParse = JSON.parse(rmsStorage)
  if (rmsStorageParse) {
    token = rmsStorageParse.token
  }
}

export const headersTokenExcel = {
  headers: {
    responseType: 'arrayBuffer',
    'API-KEY': process.env.REACT_APP_API_KEY,
    Authorization: token,
  },
}
