import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'
import { headersTokenExcel } from '../config/HeaderTokenExcel'

class Rekap {
  list(page, limit, search, startDate, endDate) {
    const endPoint = `/rekap?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  post(payload) {
    const url = process.env.REACT_APP_API_URL_V1 + `/rekap/collect`
    return axios.post(url, payload, headersTokenConfig)
  }

  excel(startDate, endDate) {
    const endPoint = `/rekap/excel?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }

  excelRefund(startDate, endDate) {
    const endPoint = `/rekap/excel-refund?start=${startDate}&end=${endDate}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.get(url, headersTokenExcel)
  }
}

export default new Rekap()
