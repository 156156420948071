import { useState } from 'react'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import InputGroupDate from '../../components/InputGroupDate'
import RecalculateItem from './RecalculateItem'

const Recalculate = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const recalculate = [
    {
      name: 'Deposit Consumer',
      type: 'deposit',
    },
    {
      name: 'Deposit Voucher',
      type: 'deposit-voucher',
    },
    {
      name: 'Upah Tukang',
      type: 'upah-tukang',
    },
    {
      name: 'Upah Tukang (Khusus Mitra 10)',
      type: 'upah-tukang-mitra10',
    },
    {
      name: 'Bonus Tukang',
      type: 'bonus',
    },
    {
      name: 'Point Rate Tukang',
      type: 'point',
    },
  ]

  return (
    <Main title="Recalculate">
      <Card title="Recalculate" col={'col-xl-12 col-lg-12'}>
        <div className="row">
          <div className="col-3">
            <InputGroupDate
              label="Start Date"
              type="startDate"
              setStartDate={setStartDate}
            />
          </div>
          <div className="col-3">
            <InputGroupDate
              label="End Date"
              type="endDate"
              setEndDate={setEndDate}
            />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap mb-0 text-center">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Data Report</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {recalculate.map((r, index) => (
                    <RecalculateItem
                      key={r.type}
                      no={index + 1}
                      name={r.name}
                      type={r.type}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default Recalculate
