import { useState, useEffect } from 'react'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import UserService from '../../services/User'
import Loader from '../../components/Loader'
import UserItems from './UsersItem'

const UserLists = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  useEffect(() => {
    // setLoading(true)

    UserService.getAll()
      .then((res) => {
        setUsers(res.data.data)
        setLoading(false)
        setIsUpdated(false)
      })
      .catch((err) => {
        console.log(err)
        setIsUpdated(false)
      })
  }, [isUpdated])

  if (loading === true) {
    return <Loader />
  }

  return (
    <Main title="User Lists">
      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row mt-5">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap text-center mb-0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Verified</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <UserItems
                      key={user.id}
                      no={index + 1}
                      id={user.id}
                      name={user.name}
                      email={user.email}
                      email_verified_at={user.email_verified_at}
                      setUpdated={setIsUpdated}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default UserLists
