import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class MasterBpjs {
  list() {
    const url = process.env.REACT_APP_API_URL_V1 + '/parameter/bpjs'
    return axios.get(url, headersTokenConfig)
  }

  update(id, payload) {
    const endPoint = `/parameter/bpjs/${id}/update`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, payload, headersTokenConfig)
  }
}

export default new MasterBpjs()
