import { Fragment } from 'react'
import Modal from '../../components/Modal'
import Badge from '../../components/Badge'
import Button from '../../components/Button'
import { convertToRupiah } from '../../helper/Rupiah'

const Detail = (props) => {
  const data = props.data
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''
  let message, messageClass, badgeClass, status
  let canDownload = false
  let canUpload = false
  const messageExist = props.data.status == 3 || props.data.status == 7
  const imageExists = data.image_evidance ? true : false

  switch (data.status) {
    case 2:
      status = 'Menunggu'
      badgeClass = 'gray'
      break
    case 3:
      status = 'Posted'
      badgeClass = 'primary px-4'
      message = 'Pengajuan sudah di posted'
      messageClass = 'text-primary'
      canDownload = true
      canUpload = true
      break
    case 4:
      status = 'Upload'
      badgeClass = 'warning'
      canDownload = true
      canUpload = true
      break
    case 5:
      status = 'Lunas'
      badgeClass = 'success'
      canDownload = true
      break
    case 7:
      status = 'Ditolak'
      badgeClass = 'danger'
      message = `Ditolak Finance: ${data.reject_reason}`
      messageClass = 'text-danger'
      break
  }

  if (canUpload) {
    if (data.image_evidance != null) {
      canUpload = false
    }
  }

  const confirmHandler = () => {
    props.setModalConfirm(true)
    props.setDataConfirm(data)
  }

  const rejectHandler = () => {
    props.setModalReject(true)
    props.setDataReject(data)
  }

  const downloadHandler = () => {
    props.setModalDownload(true)
  }

  const uploadHandler = () => {
    props.setModalUpload(true)
  }

  const imageHandler = () => {
    props.imageHandler(data.image_evidance)
  }

  const closeHandler = () => {
    props.closeModal()
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      modalType="modal-xl"
      title={`Detail Kasbon | ${props.data.worker_name}`}
      closeClick={props.closeModal}
      modalFooter="false"
      type="Tutup"
    >
      <div className="row">
        <div className="col text-start mb-3">
          <Badge
            name={status}
            badge={`bg-${badgeClass} py-2 px-5`}
            type="rounded-pill"
          />
        </div>
      </div>

      {messageExist && (
        <div className="card mt-3 bg-light p-4 border">
          <div className="row">
            <div className="col text-start">
              <p className={`${messageClass}`}>{message}</p>
            </div>
            <div className="col text-end">
              {imageExists && (
                <Button
                  button="btn-outline-dark"
                  title="Lihat Tanda Bukti"
                  onClick={imageHandler}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="card bg-light p-4 border">
        <div className="row">
          <div className="col text-start">
            <span>PIC</span>
            <p className="fw-bold">{data.pic_name}</p>
            <br />
            <h5 className="fw-bold">Detail Worker</h5>
            <div className="row">
              <div className="col text-start">
                <span>ID: {data.worker_id}</span>
                <p className="fw-bold">{data.worker_name}</p>
              </div>
              <div className="col text-start">
                <span>Total Kasbon</span>
                <p className="fw-bold">Rp{convertToRupiah(data.amount)}</p>
              </div>
              <div className="col text-start">
                <span>Note</span>
                <p className="fw-bold">{data.note}</p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col text-start">
                <span>Category</span>
                <p className="fw-bold">
                  {data.is_installment == 0
                    ? 'Tanpa Cicilan'
                    : 'Dengan Cicilan'}
                </p>
              </div>
              <div className="col text-start">
                <span>Duration</span>
                <p className="fw-bold">
                  {data.is_installment == 0
                    ? data.days + ' Hari'
                    : data.tenor + ' x Cicilan'}
                </p>
              </div>
              <div className="col text-start">
                <span>Payment Type</span>
                <p className="fw-bold">
                  {data.payment_type.charAt(0).toUpperCase() +
                    data.payment_type.slice(1)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-end">
        {canUpload && (
          <Button
            button="btn-outline-success ms-4 px-3"
            icon="fa fa-print me-1"
            title="Upload  Bukti Transfer"
            onClick={uploadHandler}
          />
        )}
        {canDownload && (
          <Button
            button="btn-outline-info ms-4 px-7"
            icon="fa fa-print me-1"
            title="Cetak"
            onClick={downloadHandler}
          />
        )}
        {data.status == 2 && (
          <Fragment>
            <Button
              button="btn-outline-danger ms-4 px-5"
              icon="fa fa-times me-1"
              title="Tolak Kasbon"
              onClick={rejectHandler}
            />
            <Button
              button="btn-outline-success ms-4"
              icon="fa fa-check me-1"
              title="Setujui Kasbon"
              onClick={confirmHandler}
            />
          </Fragment>
        )}
        <Button
          button="btn-gray ms-4 px-7"
          title="Tutup"
          onClick={closeHandler}
        />
      </div>
    </Modal>
  )
}

export default Detail
