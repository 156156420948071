import * as React from "react";
const EditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 15"
    {...props}
  >
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="m5.814 14.19 7.322-9.469c.398-.51.54-1.1.406-1.702-.114-.546-.45-1.066-.955-1.46L11.358.583c-1.07-.851-2.396-.762-3.157.215L7.38 1.864a.315.315 0 0 0 .053.439l2.122 1.702a.875.875 0 0 1 .274.528.787.787 0 0 1-.68.869.705.705 0 0 1-.54-.152L6.424 3.512a.26.26 0 0 0-.354.045L.88 10.275c-.337.421-.452.968-.337 1.496l.663 2.876c.036.152.168.26.328.26l2.918-.036a1.753 1.753 0 0 0 1.362-.681Zm4.086-.896h4.758c.464 0 .842.383.842.853a.848.848 0 0 1-.842.853H9.9a.848.848 0 0 1-.842-.853c0-.47.377-.853.842-.853Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0.5}
        x2={12.592}
        y1={7.364}
        y2={13.294}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E10009" />
        <stop offset={1} stopColor="#E10009" />
      </linearGradient>
    </defs>
  </svg>
);
export default EditIcon;
