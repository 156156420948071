import axios from "axios";
import { headersTokenConfig } from "../config/HeaderToken";

class SkillSalaryCutService {
  listDropdown() {
    const url = process.env.REACT_APP_API_URL_V1 + `/skill/list-dropdown`
    return axios.get(url, headersTokenConfig)
  }

  getListSalaryCut() {
    const url = process.env.REACT_APP_API_URL_V1 + `/skill-salary-cut`;
    return axios.get(url, headersTokenConfig);
  }

  createSalaryCut(data) {
    const url = process.env.REACT_APP_API_URL_V1 + `/skill-salary-cut`;
    return axios.post(url, data, headersTokenConfig);
  }

  updateSalaryCut(id, data) {
    const url = process.env.REACT_APP_API_URL_V1 + `/skill-salary-cut/${id}`;
    return axios.put(url, data, headersTokenConfig);
  }

  deleteSalaryCut(id) {
    const url = process.env.REACT_APP_API_URL_V1 + `/skill-salary-cut/${id}`;
    return axios.delete(url, headersTokenConfig);
  }
}

export default new SkillSalaryCutService();
