import { useEffect } from "react";

const Modal = (props) => {
  const closeHandler = () => {
    props.closeClick();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (props.submitHandler) {
      props.submitHandler();
    }
  };
  
  useEffect(() => {
    const modalElement = document.getElementById("modaldemo8");
    if (props.activeModal) {
      window.jQuery(modalElement).modal("show");
    } else {
      window.jQuery(modalElement).modal("hide");
    }

    const handleHidden = () => {
      props.closeClick();
    };

    // Listens for the hidden.bs.modal event to detect when the modal is fully hidden and then calls onClose to update the parent state
    modalElement.addEventListener('hidden.bs.modal', handleHidden); 

    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleHidden);
      window.jQuery(modalElement).modal("hide");
    };
  }, []);

  return (
    <div
      className={`modal fade ${props.activeModal}`}
      id="modaldemo8"
      style={props.activeStyle}
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`modal-dialog ${props.modalType} modal-dialog-centered text-center`}
        role="document"
      >
        <div className="modal-content modal-content-demo">
          {!props.modalHeader && (
            <div className="modal-header">
              <h5 className="modal-title fw-bold">{props.title}</h5>
              <div className="ms-5">{props.action}</div>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={closeHandler}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          )}

          <form className="form-horizontal" onSubmit={submitHandler}>
            <div className="modal-body">{props.children}</div>
            {!props.modalFooter && (
              <div className="modal-footer">
                <p
                  className="btn text-danger me-4"
                  data-bs-dismiss="modal"
                  onClick={closeHandler}
                >
                  Batalkan
                </p>
                {props.type !== "Detail" && (
                  <button
                    className="btn btn-primary fw-bold"
                    disabled={props.buttonDisable}
                  >
                    {props.type}
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
