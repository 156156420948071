import { Fragment, useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Header = () => {
  const { currentUser, dispatch } = useContext(AuthContext)
  const navigate = useNavigate()

  const logoutHandler = (e) => {
    e.preventDefault()
    dispatch({ type: 'LOGOUT' })
    localStorage.setItem('rms', null)
    navigate('/')
  }

  return (
    <Fragment>
      <div className="app-header header sticky">
        <div className="container-fluid main-container">
          <div className="d-flex">
            <Link
              to="#"
              aria-label="Hide Sidebar"
              className="app-sidebar__toggle"
              data-bs-toggle="sidebar"
            />
            <Link className="logo-horizontal" to="/">
              <img
                src={`${window.location.origin}/assets/images/logo/logo-new2.png`}
                className="header-brand-img desktop-logo"
                alt="logo"
              />
              <img
                src={`${window.location.origin}/assets/images/logo/logo-new2.png`}
                className="header-brand-img light-logo1"
                alt="logo"
              />
            </Link>
            <div className="d-flex order-lg-2 ms-auto header-right-icons">
              <div className="navbar navbar-collapse responsive-navbar p-0">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent-4"
                >
                  <div className="d-flex order-lg-2">
                    <div className="dropdown d-flex">
                      <a className="nav-link icon full-screen-link nav-link-bg">
                        <i className="fe fe-minimize fullscreen-button" />
                      </a>
                    </div>

                    <div className="dropdown d-flex profile-1">
                      <a
                        data-bs-toggle="dropdown"
                        className="nav-link leading-none d-flex"
                      >
                        <img
                          src={`${window.location.origin}/assets/images/logo/kanggo.png`}
                          alt="profile-user"
                          className="avatar  profile-user brround cover-image"
                        />
                      </a>
                      <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <div className="drop-heading">
                          <div className="text-center">
                            <small className="text-muted">Hello!</small>
                            <h5 className="text-dark mb-0 fs-14 fw-semibold">
                              {currentUser.email}
                            </h5>
                          </div>
                        </div>
                        <div className="dropdown-divider m-0" />
                        <a className="dropdown-item" onClick={logoutHandler}>
                          <i className="dropdown-icon fe fe-alert-circle" />
                          Sign out
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="jumps-prevent" style={{ paddingTop: '71.375px' }} />
    </Fragment>
  )
}

export default Header
