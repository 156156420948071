import { toast } from 'react-toastify'
import { Toast } from '../../../config/Toast'
import Modal from '../../../components/Modal'
import SalaryCalculationService from '../../../services/SalaryCalculationMitra10'
import 'react-toastify/dist/ReactToastify.css'

const UndoSalaryModal = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    const id = props.dataModal.id

    SalaryCalculationService.undo(id)
      .then(() => {
        props.setUpdate(true)
        props.closeModal()
        notifySuccess(`Upah berhasil dikembalikan ke data awal`)
      })
      .catch((err) => {
        console.log(err.response.data.message)
        notifyFailed(err.response.data.message)
        props.closeModal()
      })
  }

  let isModif = true
  if (props.dataModal.is_modif !== 'EDIT') {
    isModif = false
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Undo Upah Tukang"
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Undo"
    >
      <h4>
        {isModif &&
          'Apakah anda yakin untuk mengembalikan upah tukang ke nilai awal'}
        {!isModif && `Data upah tukang belum pernah di edit!`}
      </h4>
    </Modal>
  )
}

export default UndoSalaryModal
