import { convertToRupiah } from '../../../helper/Rupiah'
import Button from '../../../components/Button'

const RekapItem = (props) => {
  const data = props.data
  const textClass = data.ticketing.length > 0 ? 'text-danger' : ''

  const detailHandler = () => {
    props.detailHandler(data.ticketing, data.gross_amount_with_bonus_point)
  }

  return (
    <tr className={textClass}>
      <td>{props.no}</td>
      <td>{data.user_id}</td>
      <td>{data.name}</td>
      <td>
        Rp. {convertToRupiah(parseInt(data.amount))}
        {textClass && (
          <Button
            onClick={detailHandler}
            icon="fa fa-eye"
            button="btn-danger btn-sm ms-3"
          />
        )}
      </td>
      <td>{data.bank_code}</td>
      <td>{data.bank_account}</td>
      <td>{data.bank_account_name}</td>
    </tr>
  )
}

export default RekapItem
