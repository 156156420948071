import { useState, Fragment } from 'react'
import Button from '../../../components/Button'
import Badge from '../../../components/Badge'
import SalaryCalculationService from '../../../services/SalaryCalculationMitra10'
import { convertToRupiah } from '../../../helper/Rupiah'

const SalaryCalculationItem = (props) => {
  const isBookmark = props.is_valid == 1 ? 'btn-success' : 'btn-outline-success'
  const [bookmark, setBookmark] = useState(isBookmark)

  const isPosting = props.is_posting === 1 ? true : false
  let modifClass

  switch (props.is_modif) {
    case 'EDIT':
      modifClass = 'text-warning'
      break
    case 'DELETE':
      modifClass = 'text-danger'
      break
    case 'INSERT':
      modifClass = 'text-success'
      break
    default:
      modifClass = 'text-dark'
      break
  }

  const bookmarkHandler = () => {
    bookmark === 'btn-outline-success'
      ? setBookmark('btn-success')
      : setBookmark('btn-outline-success')

    SalaryCalculationService.confirm(props.id)
      .then(() => {})
      .catch((err) => {
        console.log(err)
      })
  }

  const editHandler = () => {
    props.setDataModal(props)
    props.setModal(true)
  }

  return (
    <tr className={isPosting ? 'text-primary' : modifClass}>
      <td>{props.no}</td>
      <td>
        {!isPosting && (
          <Fragment>
            <Button
              button={`${bookmark} btn-sm me-1`}
              icon={`fa fa-bookmark ${
                bookmark === 'btn-outline-success' ? 'text-success' : ''
              }`}
              onClick={bookmarkHandler}
            />
            <Button
              button="btn-outline-warning btn-sm"
              icon="fa fa-wrench text-warning"
              onClick={editHandler}
            />
          </Fragment>
        )}
        {isPosting && (
          <Badge badge="bg-primary pt-1" name="posted" type="rounded-pill" />
        )}
      </td>
      <td>{props.project_id}</td>
      <td>{props.date_project}</td>
      <td>{props.client_name}</td>
      <td>{props.worker_name}</td>
      <td>{props.product_name}</td>
      <td>{props.service_name}</td>
      <td>{`${props.sesi} (${props.sesi_start}-${props.sesi_end})`}</td>
      <td>Rp. {convertToRupiah(props.order)}</td>
      <td>Rp. {convertToRupiah(props.upah_harian)}</td>
      <td>Rp. {convertToRupiah(props.upah_per_sesi)}</td>
      <td>Rp. {convertToRupiah(props.upah_insentif)}</td>
      <td>Rp. {convertToRupiah(props.upah_transport)}</td>
      <td>Rp. {convertToRupiah(props.upah_tambahan_layanan)}</td>
      <td>Rp. {convertToRupiah(props.total)}</td>
      <td>{props.note}</td>
    </tr>
  )
}

export default SalaryCalculationItem
