import { useState } from 'react'
import Modal from '../../components/Modal'

const Reject = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''
  const [rejectReason, setRejectReason] = useState(null)

  const submitHandler = () => {
    props.rejectHandler(rejectReason)
    props.closeModal()
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title=""
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Ya, Tolak"
      modalHeader="false"
    >
      <img
        src={`${window.location.origin}/assets/images/logo/danger.png`}
        className="header-brand-img desktop-logo pt-4"
        alt="logo"
      />
      <h4 className="fw-bold text-center mt-4">
        Anda yakin tolak permintaan kasbon ini?
      </h4>
      <h5 className="mt-2">
        Jika sudah ditolak pengajuan tidak dapat dipulihkan atau disetujui
      </h5>
      <textarea
        className="form-control mt-5"
        rows="7"
        onChange={(e) => setRejectReason(e.target.value)}
      ></textarea>
    </Modal>
  )
}

export default Reject
