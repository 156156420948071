import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class PriceSkill {
  getSkills(search) {
    let url = process.env.REACT_APP_API_URL_V1 + `/skill`
    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + `/skill?search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  update(id, data) {
    const url = process.env.REACT_APP_API_URL_V1 + `/skill/${id}/update`
    const payload = {
      standard_price: data.fullPrice,
      session1_price: data.morningPrice,
      session2_price: data.eveningPrice,
    }

    return axios.put(url, payload, headersTokenConfig)
  }
}

export default new PriceSkill()
