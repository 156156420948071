import { Fragment } from 'react'
import moment from 'moment'
import Button from '../../components/Button'
import Badge from '../../components/Badge'
import KasbonApprovalService from '../../services/KasbonApproval'
import { convertToRupiah } from '../../helper/Rupiah'

const RekapItem = (props) => {
  const data = props.data
  const date = moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')
  const payment =
    data.payment_type.charAt(0).toUpperCase() + data.payment_type.slice(1)
  let type, duration, status, statusClass
  let canDownload = false

  if (data.is_installment == 1) {
    type = 'Dengan Cicilan'
    duration = `${data.tenor} x Cicilan`
  } else {
    type = 'Tanpa Cicilan'
    duration = `${data.days} Hari`
  }

  switch (data.status) {
    case 2:
      status = 'Menunggu'
      statusClass = 'gray'
      break
    case 3:
      status = 'Posted'
      statusClass = 'primary px-4'
      canDownload = true
      break
    case 4:
      status = 'Upload'
      statusClass = 'warning'
      canDownload = true
      break
    case 5:
      status = 'Lunas'
      statusClass = 'success'
      canDownload = true
      break
    case 7:
      status = 'Ditolak'
      statusClass = 'danger'
      break
  }

  const confirmHandler = () => {
    props.setModalConfirm(true)
    props.setDataConfirm(data)
  }

  const rejectHandler = () => {
    props.setModalReject(true)
    props.setDataReject(data)
  }

  const detailHandler = () => {
    KasbonApprovalService.detail(props.data.id)
      .then((res) => {
        props.setModalDetail(true)
        props.setDataDetail(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const downloadHandler = () => {
    KasbonApprovalService.detail(props.data.id)
      .then((res) => {
        props.setModalDownload(true)
        props.setDataDetail(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <tr>
      <td>{props.no}</td>
      <td>{date}</td>
      <td>{data.pic_name}</td>
      <td>{data.worker_name}</td>
      <td>{data.worker_id}</td>
      <td>
        <Badge
          name={status}
          badge={`bg-${statusClass} py-1`}
          type="rounded-pill"
        />
      </td>
      <td>{data.note}</td>
      <td className="fw-bold">Rp{convertToRupiah(data.amount)}</td>
      <td>{type}</td>
      <td className="fw-bold">{duration}</td>
      <td>{payment}</td>
      <td>
        <Button
          button="btn-outline-info btn-sm"
          icon="fa fa-eye"
          onClick={detailHandler}
        />
        {canDownload && (
          <Button
            button="btn-outline-default btn-sm ms-1"
            icon="fa fa-print"
            onClick={downloadHandler}
          />
        )}
        {data.can_action && (
          <Fragment>
            <Button
              button="btn-outline-success btn-sm ms-1"
              icon="fa fa-check"
              onClick={confirmHandler}
            />
            <Button
              button="btn-outline-danger btn-sm ms-1"
              icon="fa fa-times"
              onClick={rejectHandler}
            />
          </Fragment>
        )}
      </td>
    </tr>
  )
}

export default RekapItem
