import { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import Button from '../../../components/Button'
import ButtonGroup from '../../../components/ButtonGroup'
import InputGroup from '../../../components/InputGroup'
import InputGroupDate from '../../../components/InputGroupDate'
import Pagination from '../../../components/Pagination'
import ReportService from '../../../services/Report'
import BonusPointService from '../../../services/BonusPoint'
import PriceSkillService from '../../../services/PriceSkill'
import SalaryCalculationItem from './SalaryCalculationItem'
import { Toast } from '../../../config/Toast'
import MenuModal from './MenuModal'
import AddModal from './AddModal'
import EditModal from './EditModal'
import UndoModal from './UndoModal'
import DeleteModal from './DeleteModal'
import PostingModal from './PostingModal'
import RefundModal from './RefundModal'
import 'react-loading-skeleton/dist/skeleton.css'

const SalaryCalculation = () => {
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [workers, setWorkers] = useState([])
  const [skills, setSkills] = useState([])
  const [update, setUpdate] = useState(false)
  const [btnFilterLoading, setBtnFilterLoading] = useState(false)
  const [loadingPosting, setLoadingPosting] = useState(false)
  const [loadingRefund, setLoadingRefund] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState(null)
  const [totalPage, setTotalPage] = useState(0)
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [dataModal, setDataModal] = useState(null)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalUndo, setModalUndo] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [modalAdd, setModalAdd] = useState(false)
  const [modalPosting, setModalPosting] = useState(false)
  const [modalRefund, setModalRefund] = useState(false)
  const [loading, setLoading] = useState(false)

  const notifyFailed = (message) => toast.error(message, Toast)
  const limit = 5

  useEffect(() => {
    if (startDate && endDate) {
      setLoading(true)

      ReportService.salaryCalculation(
        pageNumber,
        limit,
        search,
        startDate,
        endDate
      )
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setBtnFilterLoading(false)
          setUpdate(false)
        })
        .catch((err) => {
          setBtnFilterLoading(false)
          console.log(err)
          setUpdate(false)
        })

      setTimeout(() => {
        setLoading(false)
      }, 1500)
    }
  }, [update])

  useEffect(() => {
    // Fetch all workers
    BonusPointService.allWorkers()
      .then((res) => {
        setWorkers(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })

    // Fetch all skills
    PriceSkillService.getSkills()
      .then((res) => {
        const skills = res.data.data

        // Refactor skills
        const newSkills = []
        skills.forEach((skill) => {
          skill.value = skill.id
          skill.label = skill.name
          delete skill.image_uri
          delete skill.standard_price
          delete skill.standard_salary
          delete skill.session1_price
          delete skill.session2_price
          delete skill.tools_price
          newSkills.push(skill)
        })

        setSkills(newSkills)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const filterHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnFilterLoading(true)
    setUpdate(true)
  }

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setUpdate(true)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setUpdate(true)
  }

  const postingHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }
    setModalPosting(true)
  }

  const refundHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }
    setModalRefund(true)
  }

  const closeModalHandler = () => {
    switch (modalType) {
      case 'add':
        setModalAdd(false)
        setModal(false)
        break
      case 'edit':
        setModalEdit(false)
        setModal(false)
        break
      case 'undo':
        setModalUndo(false)
        setModal(false)
        break
      case 'delete':
        setModalDelete(false)
        setModal(false)
        break
      default:
        setModal(false)
    }
  }

  const closePostingRefundModalHandler = () => {
    setModalPosting(false)
    setModalRefund(false)
    setModal(false)
  }

  const tHead = [
    'No',
    'Action',
    'Att.Date',
    'Project',
    'Project ID',
    'Client Name',
    'Worker Name',
    'Skill',
    'Order',
    'Order Sukses',
    'Total Tukang',
    'Sesi',
    'Upah Harian',
    'Upah Insentif',
    'Upah Transport',
    'Upah Keramik',
    'Upah Las',
    'Fee Building',
    'Potongan Keluhan',
    'Total Upah',
    'Tabungan / Asuransi'
  ]

  return (
    <Fragment>
      {modal && (
        <MenuModal
          dataModal={dataModal}
          activeModal={modal}
          setModalAdd={setModalAdd}
          setModalEdit={setModalEdit}
          setModalUndo={setModalUndo}
          setModalDelete={setModalDelete}
          setModalType={setModalType}
          closeModal={closeModalHandler}
        />
      )}

      {modalAdd && (
        <AddModal
          dataModal={dataModal}
          activeModal={modalAdd}
          setUpdate={setUpdate}
          workers={workers}
          skills={skills}
          setModalType="add"
          closeModal={closeModalHandler}
        />
      )}

      {modalEdit && (
        <EditModal
          dataModal={dataModal}
          activeModal={modalEdit}
          setUpdate={setUpdate}
          modalType="edit"
          closeModal={closeModalHandler}
        />
      )}

      {modalUndo && (
        <UndoModal
          dataModal={dataModal}
          activeModal={modalUndo}
          setUpdate={setUpdate}
          modalType="undo"
          closeModal={closeModalHandler}
        />
      )}

      {modalDelete && (
        <DeleteModal
          dataModal={dataModal}
          activeModal={modalDelete}
          setUpdate={setUpdate}
          modalType="delete"
          closeModal={closeModalHandler}
        />
      )}

      {modalPosting && (
        <PostingModal
          dataModal={dataModal}
          activeModal={modalPosting}
          setUpdate={setUpdate}
          setLoadingPosting={setLoadingPosting}
          startDate={startDate}
          endDate={endDate}
          closeModal={closePostingRefundModalHandler}
        />
      )}

      {modalRefund && (
        <RefundModal
          dataModal={dataModal}
          activeModal={modalRefund}
          setUpdate={setUpdate}
          setLoadingRefund={setLoadingRefund}
          startDate={startDate}
          endDate={endDate}
          closeModal={closePostingRefundModalHandler}
        />
      )}

      <div className="row">
        <div className="col-3">
          <InputGroupDate
            type="startDate"
            label="Start Date"
            setStartDate={setStartDate}
          />
        </div>
        <div className="col-3">
          <InputGroupDate
            type="endDate"
            setEndDate={setEndDate}
            label="End Date"
          />
        </div>
        <div className="col">
          <div className="row mt-4">
            <div className="col">
              <Button
                button="btn-primary mt-5"
                title="Filter"
                icon="fa fa-filter me-1"
                onClick={filterHandler}
                loading={btnFilterLoading}
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row mt-5">
            <div className="col mt-4">
              <ButtonGroup
                buttonOne="btn-teal"
                titleOne="Posting Disbursment"
                onClickOne={postingHandler}
                loadingOne={loadingPosting}
                buttonTwo="btn-orange"
                titleTwo="Refund Kontraktor"
                onClickTwo={refundHandler}
                loadingTwo={loadingRefund}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-end mt-2">
        <div className="col-6">
          <InputGroup
            button="btn-dark"
            searchHandler={searchHandler}
            icon="fa fa-search"
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover text-nowrap text-md-nowrap mb-0 text-center">
              <thead>
                <tr>
                  {tHead.map((d) => (
                    <th>{d}</th>
                  ))}
                </tr>
              </thead>
              {loading &&
                tHead.map((d) => (
                  <td>
                    <Skeleton
                      count={5}
                      variant="rectangular"
                      height={33}
                      style={{
                        marginBottom: '0.5rem',
                      }}
                    />
                  </td>
                ))}
              {!loading && (
                <tbody>
                  {data.map((d, index) => (
                    <SalaryCalculationItem
                      key={d.id}
                      id={d.id}
                      no={
                        index +
                        (pageNumber == 1 ? 1 : limit * pageNumber - limit + 1)
                      }
                      data={d}
                      setModal={setModal}
                      setDataModal={setDataModal}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          {totalPage > 0 && (
            <Pagination pageCount={totalPage} changePage={changePageHandler} />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default SalaryCalculation
