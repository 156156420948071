import Modal from '../../components/Modal'
import { convertToRupiah } from '../../helper/Rupiah'

const Confirm = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const submitHandler = () => {
    props.confirmHandler()
    props.closeModal()
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title=""
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Ya, Setujui"
      modalHeader="false"
    >
      <h4 className="fw-bold text-center pt-4">
        Setujui Kasbon Rp{convertToRupiah(props.data.amount)} ?
      </h4>
      <h5 className="mt-5">
        Pastikan Anda telah mengecek kembali, jika pengajuan telah disetujui
        tida dapat dipulihkan kembali.
      </h5>
      <h5 className="mt-4 fw-bold">
        Pengajuan kasbon yang telah disetujui akan masuk ke halaman BPJS &
        Kasbon
      </h5>
    </Modal>
  )
}

export default Confirm
