import Modal from '../../../components/Modal'
import 'react-toastify/dist/ReactToastify.css'

const Submit = (props) => {
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const submitHandler = () => {
    props.saveBpjsHandler()
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Ya, Simpan"
    >
      <h5 className="fw-bold">Simpan Data Pembayaran BPJS ?</h5>
      <h5 className="mt-4">
        Mohon diperhatikan bahwa jumlah pembayaran{' '}
        <span className="text-danger">tidak dapat diedit kembali</span> setelah
        data ini disimpan. <br /> <br /> Pastikan kembali data sudah sesuai.
      </h5>
    </Modal>
  )
}

export default Submit
