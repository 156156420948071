import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class Recalculate {
  recalculate(payload) {
    const url = process.env.REACT_APP_API_URL_V1 + `/recalculate`
    return axios.post(url, payload, headersTokenConfig)
  }
}

export default new Recalculate()
