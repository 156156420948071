import { useState } from 'react'
import moment from 'moment'
import DetailItem from './DetailItem'
import ButtonDropdown from '../../../components/ButtonDropdown'
import BpjsService from '../../../services/Bpjs'
import 'react-toastify/dist/ReactToastify.css'

const Detail = (props) => {
  const dataMaster = props.data.information
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const [list, setList] = useState(props.data.list)
  const [year, setYear] = useState(moment().format('YYYY'))
  const curYear = moment().format('YYYY')
  const dropdownObj = []

  const yearHandler = (e) => {
    setYear(e.target.innerText)

    BpjsService.history(props.data.information.id, e.target.innerText)
      .then((res) => {
        setList(res.data.data.list)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  for (let i = 0; i <= 2; i++) {
    const temp = {
      name: curYear - i,
      clickFunction: yearHandler,
    }
    dropdownObj.push(temp)
  }

  const action = (
    <ButtonDropdown
      button="btn-dark btn-sm px-4"
      title={year}
      dropdown={dropdownObj}
    />
  )

  const submitHandler = (e) => {
    e.preventDefault()
    props.submitBpjsHandler()
  }

  return (
    <div
      className={`modal fade ${activeModal}`}
      id="modalDetailBPJS"
      style={activeStyle}
      role="dialog"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-xl  text-center "
        role="document"
      >
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h5 className="modal-title fw-bold">
              {`List Pembayaran BPJS | ${props.data.information.worker_name}`}
            </h5>
            <button
              aria-label="Close"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={props.closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-end mb-2">{action}</div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>BPJS</th>
                  <th>Remaining Deposit</th>
                  <th>Note</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {list.map((data) => (
                  <DetailItem
                    key={data.id}
                    data={data}
                    editHandler={props.editHandler}
                    closeModal={props.closeModal}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <div className="modal-footer">
            <form className="form-horizontal" onSubmit={submitHandler}>
              <button
                className="btn text-danger me-5"
                data-bs-dismiss="modal"
                onClick={props.closeModal}
              >
                Batalkan
              </button>
              <button className="btn btn-primary px-7">Simpan</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail
