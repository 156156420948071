import Button from '../../components/Button'
import { convertToRupiah } from '../../helper/RupiahDashboard'
import 'react-toastify/dist/ReactToastify.css'

const MasterBpjsItem = (props) => {
  const editHandler = () => {
    props.setModal(true)
    props.setData(props)
  }

  return (
    <tr>
      <td>{props.no}</td>
      <td>{props.type}</td>
      <td>Rp{convertToRupiah(props.price)}</td>
      <td>
        <Button
          icon="fa fa-wrench"
          button="btn-outline-warning btn-sm"
          onClick={editHandler}
        />
      </td>
    </tr>
  )
}

export default MasterBpjsItem
