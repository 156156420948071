import { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Pagination from '../../../components/Pagination'
import ButtonGroup from '../../../components/ButtonGroup'
import InputGroup from '../../../components/InputGroup'
import InputGroupDate from '../../../components/InputGroupDate'
import Tag from '../../../components/Tag'
import ReportService from '../../../services/Report'
import BonusPointService from '../../../services/BonusPoint'
import SalaryCalculationItem from './SalaryCalculationItem'
import { Toast } from '../../../config/Toast'
import MenuModal from './MenuModal'
import AddModal from './AddModal'
import EditModal from './EditModal'
import UndoModal from './UndoModal'
import DeleteModal from './DeleteModal'
import PostingModal from './PostingModal'

const SalaryCalculation = () => {
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [workers, setWorkers] = useState([])
  const [update, setUpdate] = useState(false)
  const [btnFilterLoading, setBtnFilterLoading] = useState(false)
  const [loadingPosting, setLoadingPosting] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState(null)
  const [totalPage, setTotalPage] = useState(0)
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [dataModal, setDataModal] = useState(null)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalUndo, setModalUndo] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [modalAdd, setModalAdd] = useState(false)
  const [modalPosting, setModalPosting] = useState(false)

  const notifyFailed = (message) => toast.error(message, Toast)
  const limit = 5

  useEffect(() => {
    if (startDate && endDate) {
      ReportService.salaryCalculationMitra10(
        pageNumber,
        limit,
        search,
        startDate,
        endDate
      )
        .then((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setBtnFilterLoading(false)
          setUpdate(false)
        })
        .catch((err) => {
          console.log(err)
          setBtnFilterLoading(false)
          setUpdate(false)
        })
    }
  }, [update])

  // Fetch all workers
  useEffect(() => {
    BonusPointService.allWorkers()
      .then((res) => {
        setWorkers(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const filterHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setBtnFilterLoading(true)
    setUpdate(true)
  }

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setUpdate(true)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setUpdate(true)
  }

  const postingHandler = () => {
    if (startDate === null || endDate === null) {
      notifyFailed('Date is required')
      return
    }

    setModalPosting(true)
  }

  const closeModalHandler = () => {
    switch (modalType) {
      case 'add':
        setModalAdd(false)
        setModal(false)
        break
      case 'edit':
        setModalEdit(false)
        setModal(false)
        break
      case 'undo':
        setModalUndo(false)
        setModal(false)
        break
      case 'delete':
        setModalDelete(false)
        setModal(false)
        break
      default:
        setModal(false)
    }
  }

  const closePostingModalHandler = () => {
    setModalPosting(false)
  }

  return (
    <Fragment>
      {modal && (
        <MenuModal
          dataModal={dataModal}
          activeModal={modal}
          setModalAdd={setModalAdd}
          setModalEdit={setModalEdit}
          setModalUndo={setModalUndo}
          setModalDelete={setModalDelete}
          setModalType={setModalType}
          closeModal={closeModalHandler}
        />
      )}

      {modalAdd && (
        <AddModal
          dataModal={dataModal}
          activeModal={modalAdd}
          setUpdate={setUpdate}
          workers={workers}
          setModalType="add"
          closeModal={closeModalHandler}
        />
      )}

      {modalEdit && (
        <EditModal
          dataModal={dataModal}
          activeModal={modalEdit}
          setUpdate={setUpdate}
          modalType="edit"
          closeModal={closeModalHandler}
        />
      )}

      {modalUndo && (
        <UndoModal
          dataModal={dataModal}
          activeModal={modalUndo}
          setUpdate={setUpdate}
          modalType="undo"
          closeModal={closeModalHandler}
        />
      )}

      {modalDelete && (
        <DeleteModal
          dataModal={dataModal}
          activeModal={modalDelete}
          setUpdate={setUpdate}
          modalType="delete"
          closeModal={closeModalHandler}
        />
      )}

      {modalPosting && (
        <PostingModal
          dataModal={dataModal}
          activeModal={modalPosting}
          setUpdate={setUpdate}
          setLoadingPosting={setLoadingPosting}
          startDate={startDate}
          endDate={endDate}
          closeModal={closePostingModalHandler}
        />
      )}

      <div className="row">
        <div className="col-3">
          <InputGroupDate
            type="startDate"
            label="Start Date"
            setStartDate={setStartDate}
          />
        </div>
        <div className="col-3">
          <InputGroupDate
            type="endDate"
            setEndDate={setEndDate}
            label="End Date"
          />
        </div>
        <div className="col">
          <div className="row mt-4">
            <div className="col">
              <ButtonGroup
                buttonOne="btn-primary px-5 mt-5"
                titleOne="Filter"
                iconOne="fa fa-filter me-1"
                onClickOne={filterHandler}
                loadingOne={btnFilterLoading}
                buttonTwo="btn-success mt-5"
                titleTwo="Posting"
                iconTwo="fa fa-check me-1"
                onClickTwo={postingHandler}
                loadingTwo={loadingPosting}
              />
            </div>
            <div className="col-6 mt-5">
              <InputGroup
                button="btn-dark"
                searchHandler={searchHandler}
                icon="fa fa-search"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col">
          <div className="mb-2">Color Information:</div>
          <Tag color="bg-primary" name="posted" />
          <Tag color="bg-success" name="added" />
          <Tag color="bg-warning" name="updated" />
          <Tag color="bg-danger" name="deleted" />
        </div>
      </div> */}

      <div className="row mt-1">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover text-nowrap text-md-nowrap mb-0 text-center">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Action</th>
                  <th>Project ID</th>
                  <th>Start Project</th>
                  <th>Customer Name</th>
                  <th>Worker Name</th>
                  <th>Product</th>
                  <th>Service</th>
                  <th>Sesi</th>
                  <th>Order</th>
                  <th>Upah Harian</th>
                  <th>Upah Per Sesi</th>
                  <th>Upah Insentif</th>
                  <th>Biaya Bangunan</th>
                  <th>Layanan Bongkar Pasang</th>
                  <th>Total Upah</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d, index) => (
                  <SalaryCalculationItem
                    key={d.id}
                    id={d.id}
                    no={
                      index +
                      (pageNumber == 1 ? 1 : limit * pageNumber - limit + 1)
                    }
                    project_id={d.project_id}
                    date_project={d.date_project}
                    client_name={d.client_name}
                    worker_name={d.worker_name}
                    worker_id={d.worker_id}
                    service_name={d.service_name}
                    product_name={d.product_name}
                    sesi={d.sesi}
                    sesi_start={d.sesi_start}
                    sesi_end={d.sesi_end}
                    order={d.order}
                    upah_harian={d.upah_harian}
                    upah_per_sesi={d.upah_per_sesi}
                    upah_insentif={d.upah_insentif}
                    upah_transport={d.upah_transport}
                    upah_fee_building={d.upah_fee_building}
                    upah_tambahan_layanan={d.upah_tambahan_layanan}
                    total={d.total}
                    is_valid={d.is_valid}
                    is_posting={d.is_posting}
                    is_modif={d.is_modif}
                    note={d.note}
                    setModal={setModal}
                    setDataModal={setDataModal}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          {totalPage > 0 && (
            <Pagination pageCount={totalPage} changePage={changePageHandler} />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default SalaryCalculation
